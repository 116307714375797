import {observer} from "mobx-react";
import React, {useState} from "react";
import {Button} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { brandStore } from "../BrandStore";
const FilterTab = () => {
    const [openLabel, setOpenFilteropenLabel] = useState(true);
    return (
        <div className="filter-tab">
            <Button onClick={() => setOpenFilteropenLabel(!openLabel)}
                    aria-controls="example-collapse-text" aria-expanded={openLabel}>
                <span>Trạng thái</span>
                <i className="fas fa-chevron-down"></i>
            </Button>
            <Collapse in={openLabel}>
                <div className="filter-tab__body">
                    <div className="filter-tab__option">
                        <input checked={brandStore.onFilterActive} type="radio" name="active" id="active" onClick={()=>{brandStore.checkFilterActive()}}/>
                        <label className="radio-button" htmlFor="active">
                            Hoạt động
                        </label>
                    </div>
                    <div className="filter-tab__option">
                        <input checked={brandStore.onFilterInactive} type="radio" name="inactive" id="inactive" onClick={()=>{brandStore.checkFilterInactive()}}/>
                        <label className="radio-button" htmlFor="inactive">
                            Không hoạt động
                        </label>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
export default observer(FilterTab);