import {getRequest, postRequest, putRequest, deleteRequest} from '../../../common/helpers/RequestHelper';
class BrandService {
    apiSubdirectory = 'api/v1/brands' ;
    async fetchBrands(paramBrand:any) {
        const queryParameters = [
            paramBrand.search ? `search=${paramBrand.search}` : '',
            paramBrand.offset ? `offset=${paramBrand.offset}` : '',
            paramBrand.order_by ? `order_by=${paramBrand.order_by}` : '',
            paramBrand.page ? `page=${paramBrand.page}` : '',
            paramBrand.per_page ? `per_page=${paramBrand.per_page}` : '',
            paramBrand.status ? `status=${paramBrand.status}` : '',
        ].filter(Boolean).join('&');
        return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
        // return await getRequest(`${this.apiSubdirectory}?limit=${paramBrand.limit}&offset=${paramBrand.offset}&order_by=${paramBrand.order_by}&page_number=${paramBrand.page_number}&page_size=${paramBrand.page_size}&search=${paramBrand.search}`);
    }
    async fetchBrandsAll() {
        return await getRequest(`${this.apiSubdirectory}?per_page=10`);
    }
    async fetchCreateNewBrand(paramsCreate:any): Promise<any> {
        // console.log(paramsCreate)
        return await postRequest(`${this.apiSubdirectory}`,paramsCreate);
    }
    async fetchDelBrand(paramsDelete:any){
        return await deleteRequest(`${this.apiSubdirectory}/${paramsDelete.id}`)
    }
    async fetchSpecificBrand(paramBrand: any): Promise<any>{
        return await getRequest(`${this.apiSubdirectory}/${paramBrand.id}`)
    }
    async fetchModifyBrand(paramsModify: any): Promise<any>{
        return await putRequest(`${this.apiSubdirectory}/${paramsModify.id}`, paramsModify)
    }
}
export const brandService = new BrandService();