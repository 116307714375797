import { Button, Input, Select } from "antd"
import { observer } from "mobx-react"
import { groupProductStore } from "../../GroupProductStore"
import SelectCompare from "./SelectCompare"
import SelectProductRequirement from "./SelectProductRequirement"
import NoContent from "../../../../../shared/components/NoContent"
import Loading from "../../../../../shared/components/loading/Loading"
const ConditionalOption = ()=>{
    return(<>
        <div className="item-form item-form--custom">
            <label>Kiểu lọc</label>
            <Select
                defaultValue="Sản phẩm thoả mãn một trong các điều kiện"
                onChange={(e)=>{String(e) == String(true) ? groupProductStore.isApplyConditionAll = true : groupProductStore.isApplyConditionAll = false;}}
                options={[
                    { value: true, label: 'Sản phẩm phải thoả mãn tất cả các điều kiện.', disabled: true },
                    { value: false, label: 'Sản phẩm chỉ thoả mãn một điều kiện bất kỳ.' },
                ]}
            />
        </div>
        <div className="item-form item-form--custom">
            <label>Điều kiện lọc</label>
            <div className="condition__container">
                {groupProductStore.conditionList.length === 0 ?
                (<></>):
                (<>
                {groupProductStore.conditionList
                .map((item:any, index:number)=>(
                    <div className="condition__item" key={index}>
                        <div className="input-group">
                            <div>
                                <SelectProductRequirement value={item.sortTitle} onChange={(event)=>{groupProductStore.conditionList[index].sortTitle = event}}/>
                            </div>
                            {groupProductStore.conditionList[index].sortTitle === 'empty' ? (<></>):(
                                <>
                                    <div><SelectCompare value={item.sortType} onChange={(event)=>{groupProductStore.conditionList[index].sortType = event}}/></div>
                                    <div><Input placeholder="Vui lòng nhập" value={item.sortValue} onChange={(event)=>{groupProductStore.conditionList[index].sortValue = event.target.value}}/></div>
                                </>
                            )}
                        </div>
                        <div className="delete-btn">
                            <Button title="Xoá nhóm sản phẩm" type='primary' danger onClick={()=>{groupProductStore.checkIfHavingCondition();groupProductStore.conditionPosition = index;groupProductStore.handleRemoveItemInConditionList()}}><i className="fas fa-trash" ></i></Button>
                        </div>
                    </div>
                ))}
                </>)
                }
            </div>
            <div className="action-btn__container">
                {groupProductStore.conditionList.length === 0 ? <></>:(<Button type="primary" onClick={()=>{groupProductStore.handleApplyCondition()}}>Áp dụng điều kiện</Button>)}
                <Button onClick={()=>{groupProductStore.handleAddItemInConditionList()}}>Thêm điều kiện</Button>
            </div>
        </div>
        <div className="item-form item-form--custom">
            <label>Danh sách sản phẩm thoả mãn điều kiện:</label>
            <div className="product-table condition-filtering-product">
                <table>
                    <tbody>
                        {groupProductStore.isApplyingConditionStatus === true ? //is there conditions ?
                        (<>
                            {groupProductStore.isLoadingProductList === true ? (<tr><td><Loading/></td></tr>):
                            (<>
                                {groupProductStore.idProductList.length === 0 ? <tr><td><NoContent/></td></tr>:(groupProductStore.dataListProduct.map((item:any, index:number)=>
                                    (<tr>
                                        <td>
                                            <input type="checkbox" id={item.id} value={item.id} checked={groupProductStore.idProductList.includes(item.id)} onChange={(e) => groupProductStore.handleSelectProductInTable(e.target.checked, Number(e.target.value))}/>
                                        </td>
                                        <td key={index} style={{width: '95%'}}>{item.title}</td>
                                    </tr>)
                                ))}
                            </>)}
                        </>)
                        :
                        (<>
                        {groupProductStore.isLoadingProductList === true ? (<tr><td><Loading/></td></tr>):
                            (<>{groupProductStore.dataListProduct.map((item:any, index:number)=>(
                                <tr key={index}>
                                    <td style={{width: '5%', textAlign: 'center'}}>
                                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                            <input type="checkbox" style={{width: '15px', height:'15px'}} id={item.id} value={item.id} checked={groupProductStore.idProductList.includes(item.id)} onChange={(e) => groupProductStore.handleSelectProductInTable(e.target.checked, Number(e.target.value))}/>
                                        </div>
                                    </td>
                                    <td><label htmlFor={item.id}>{item.title}</label></td>
                                </tr>
                            ))}</>)}
                        </>)}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="item-form item-form--custom product-table">
            <label>Danh sách sản phẩm được chọn:</label>
            <div style={{maxHeight: "300px", overflowY: 'auto'}}>
                <table>
                    <tbody>
                        <>
                            {groupProductStore.idProductList.length === 0 ?
                            (<><tr><td><NoContent/></td></tr></>):
                            (<>
                                {groupProductStore.dataListProductAll.filter((item:any)=> groupProductStore.idProductList.includes(item.id)).map((element:any, index:number)=>(
                                    <tr key={index}>
                                        <td style={{width: '5%'}}><Button type="text" danger onClick={()=>{groupProductStore.handleRemoveItemInIDProductList(Number(index))}}><i className="fas fa-trash"/></Button></td>
                                        <td style={{width: '90%', marginLeft: '5%'}}>{element.title}</td>
                                    </tr>
                                ))}
                            </>)}
                        </>
                    </tbody>
                </table>
            </div>
        </div>
    </>)
}
export default observer(ConditionalOption)