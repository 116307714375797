import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { productStore } from "../ProductStore";
import { Link, useParams } from "react-router-dom";
import "../createProduct/styles/create_product.css";
import { Button, Form, Input, InputNumber, Space } from "antd";
import EditProductMenu from "./EditProductMenu";
import TextareaEditor from "../../../../shared/components/form/TextareaEditor";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import SelectMultipleAnt from "../../../../shared/components/form/SelectMultipleAnt";
import { dashboardStore } from "../../../dashboard/DashboardStore";
import { categoryProductStore } from "../../categoryProduct/CategoryProductStore";

const { TextArea } = Input;
const EditProductGeneral = () => {
    const { id } = useParams<{ id: string }>();
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${id}`;
    productStore.staticDataDetailProduct.request_type = "general";
    useEffect(() => {
        productStore.fetchGetProduct().then();
        productStore.fetchListFeature().then();
        productStore.fetchListCategory().then();
        productStore.fetchListBrand().then();
        productStore.fetchListTags().then();
        dashboardStore.fetchDataStore().then();
    }, []);

    useEffect(() => {
        productStore.dataProduct.categories =
            productStore.dataProduct.categories?.map(
                (itemCategory: any) => itemCategory.id
            );
        productStore.dataProduct.tags = productStore.dataProduct.tags?.map(
            (itemTag: any) => itemTag.id
        );
        form.setFieldsValue({
            title: productStore.dataProduct?.title,
            slug: productStore.dataProduct?.slug,
            brand_id: productStore.dataProduct?.brand_id,
            feature_id: productStore.dataProduct?.feature_id,
            is_variation: productStore.dataProduct?.is_variation,
            status: productStore.dataProduct?.status,
            rating: productStore.dataProduct?.rating,
            price_origin: productStore.dataProduct?.price_origin,
            price_compare_origin:
                productStore.dataProduct?.price_compare_origin,
            sku_origin: productStore.dataProduct?.sku_origin,
            weight_origin: productStore.dataProduct?.weight_origin,
            unit: productStore.dataProduct?.unit,
            show_price: productStore.dataProduct?.show_price,
            seo_title: productStore.dataProduct?.seo_title,
            seo_description: productStore.dataProduct?.seo_description,
            seo_canonical: productStore.dataProduct?.seo_canonical,
            style_view_template: productStore.dataProduct?.style_view_template,
            group_product_ids: productStore.dataProduct?.group_product_ids,
            discount_applies: productStore.dataProduct?.discount_applies,
            excerpt: productStore.dataProduct?.excerpt?.value,
            description: productStore.dataProduct?.description?.value,
            tags: productStore.dataProduct?.tags,
            categories: productStore.dataProduct.categories?.map(
                (itemCategory: any) => itemCategory.id
            ),
            feature: productStore.dataProduct,
            brand: productStore.dataProduct?.brand,
        });
    }, [productStore.dataProduct, form]);
    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            // productStore.staticDataProduct.title = values.name
                        }}
                        style={{ maxWidth: 600 }}
                    >
                        <div className="session-create">
                            <div className="session-create-left session-create-left-edit">
                                <div className="session-create-left-content">
                                    <EditProductMenu
                                        idProduct={`${id}`}
                                        nameEdit="general"
                                    />
                                </div>
                            </div>
                            <div className="session-create-right">
                                <div className="session-create-content">
                                    <div className="item-form">
                                        <Form.Item
                                            name="title"
                                            label="Tên sản phẩm"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="slug"
                                            label="Slug"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form item-form-textarea">
                                        <Form.Item
                                            name="excerpt"
                                            label="Mô tả ngắn"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>
                                    </div>
                                    <Form.Item name="description">
                                        <TextareaEditor
                                            content={
                                                productStore.dataProduct
                                                    ?.description?.value
                                            }
                                            textLabel={`Mô tả chi tiết`}
                                            onChange={(value) =>
                                                (productStore.dataProduct.description =
                                                    value)
                                            }
                                        />
                                    </Form.Item>
                                    <div className="item-flex grid-2 gap-20">
                                        <div className="item-form">
                                            <Form.Item
                                                name="status"
                                                label="Trạng thái"
                                            >
                                                <SelectStatus
                                                    selected={() =>
                                                        `${productStore.dataProduct.status}`
                                                    }
                                                    onChange={(value: any) =>
                                                        (productStore.dataProduct.status =
                                                            value)
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="item-form">
                                            <label htmlFor="show_price">
                                                Hiện giá: (Tắt hiện giá sản phẩm
                                                sẽ hiện là: <span>Liên hệ</span>
                                                )
                                            </label>
                                            <Form.Item name="show_price">
                                                <SelectStatus
                                                    selected={() =>
                                                        `${productStore.dataProduct.show_price}`
                                                    }
                                                    onChange={(value: any) =>
                                                        (productStore.dataProduct.show_price =
                                                            value)
                                                    }
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="feature_id"
                                            label="Chọn loại nổi bật"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                            ]}
                                        >
                                            <SelectCustom
                                                list={productStore.listFeature}
                                                placeholder={`-- Chọn --`}
                                                onChange={(value: any) =>
                                                    (productStore.dataProduct.feature_id =
                                                        value)
                                                }
                                                selected={() => {
                                                    const featureId =
                                                        productStore.dataProduct
                                                            .feature_id;
                                                    const defaultId =
                                                        productStore
                                                            .listProduct?.[0]
                                                            ?.id;
                                                    return (
                                                        featureId ||
                                                        defaultId ||
                                                        undefined
                                                    ); // Return undefined if both are unavailable
                                                }}
                                                getLabel={(item: any) =>
                                                    item.name
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="categories"
                                            label="Danh mục sản phẩm"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                            ]}
                                        >
                                            <SelectMultipleAnt
                                                list={productStore.listCategory}
                                                defaultValue={
                                                    productStore.dataProduct
                                                        .categories
                                                }
                                                onChange={(value: any) =>
                                                    (productStore.dataProduct.categories =
                                                        value)
                                                }
                                                placeholder={`-- Chọn --`}
                                                getLabel={(item: any) =>
                                                    item.name
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="brand_id"
                                            label="Thương hiệu"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Không được để trống!",
                                                },
                                            ]}
                                        >
                                            <SelectCustom
                                                list={productStore.listFeature}
                                                placeholder={`-- Chọn --`}
                                                onChange={(value: any) =>
                                                    (productStore.dataProduct.brand_id =
                                                        value)
                                                }
                                                selected={() => {
                                                    const brandId =
                                                        productStore.dataProduct
                                                            .brand_id;
                                                    const defaultId =
                                                        productStore
                                                            .listBrand?.[0]?.id;
                                                    return (
                                                        brandId ||
                                                        defaultId ||
                                                        undefined
                                                    );
                                                }}
                                                getLabel={(item: any) =>
                                                    item.name
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="item-form">
                                        <Form.Item
                                            name="tags"
                                            label="Thẻ sản phẩm"
                                        >
                                            <SelectMultipleAnt
                                                list={productStore.listTags}
                                                defaultValue={
                                                    productStore.dataProduct
                                                        .tags
                                                }
                                                onChange={(value: any) =>
                                                    (productStore.dataProduct.tags =
                                                        value)
                                                }
                                                placeholder={`-- Chọn --`}
                                                getLabel={(item: any) =>
                                                    item.name
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Hoàn tất
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
export default observer(EditProductGeneral);
