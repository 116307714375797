export default class StorageService {
    public setAccessToken(token: string, days: number) {
        const expirationDate = new Date();
        expirationDate.setTime(
            expirationDate.getTime() + days * 24 * 60 * 60 * 1000
        );
        document.cookie = `accessToken=${token}; expires=${expirationDate.toUTCString()}`;
    }
    public setAccessTokenSeconds(token: string, seconds: number) {
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + seconds * 1000);
        document.cookie = `accessToken=${token}; expires=${expirationDate.toUTCString()}`;
    }
    public setRefreshToken(token: string, days: number) {
        const expirationDate = new Date();
        expirationDate.setTime(
            expirationDate.getTime() + days * 24 * 60 * 60 * 1000
        );
        document.cookie = `refreshToken=${token}; expires=${expirationDate.toUTCString()}`;
    }
    public getCookie(name: string) {
        let allCookie = document.cookie.split(";");

        for (let i = 0; i < allCookie.length; i++) {
            let cookie = allCookie[i].trim();

            if (cookie.indexOf(name + "=") === 0) {
                return cookie.substring(name.length + 1);
            }
        }
    }
    public removeAccessToken() {
        // document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        document.cookie = "accessToken=";
        window.location.href = "/dang-nhap";
    }
    public setIdTenant(domain: string) {
        localStorage.setItem("domainName", `${domain}`);
    }
    public setIdDomain(idDomain: string) {
        localStorage.setItem("idDomain", `${idDomain}`);
    }
    public setNameUser(name: string) {
        localStorage.setItem("nameDomain", `${name}`);
    }
}
