import { observer } from "mobx-react";
import FilterCategoryProduct from "./components/filterCategoryProduct/FilterCategoryProduct";
import FilterGroupProduct from "./components/filterGroupProduct/FilterGroupProduct";
import { Dropdown } from "react-bootstrap";
import "./styles/product.css";
import React, { useEffect, useState } from "react";
import {
    createSearchParams,
    Link,
    useLocation,
    useNavigate,
} from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import ReactPaginate from "react-paginate";
import { productStore } from "./ProductStore";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const ProductPage = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (productStore.staticDataListProduct.search !== "")
            params.keyword = productStore.staticDataListProduct.search;
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = selectedPage;
        productStore.fetchListProduct();
    };
    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const searchKeyword = (e.target as HTMLFormElement).keyword.value;
        const params: any = {};
        if (searchKeyword !== "") params.keyword = searchKeyword;
        navigate({
            search: createSearchParams(params).toString(),
        });
        productStore.staticDataListProduct.page_number = 0;
        productStore.fetchListProduct();
    };

    const [selected, setSelected] = useState<string[]>([]);
    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selected, name]);
        } else {
            setSelected(selected.filter((item) => item !== name));
        }
    }
    function selectAll(value: boolean) {
        if (value) {
            setSelected(productStore.listProduct.map((item: any) => item.id));
        } else {
            setSelected([]);
        }
    }
    const allSelected = selected.length === productStore.listProduct.length;

    const handleEditClick = (id: number) => {
        navigate(`/hang-hoa/san-pham/${id}/thong-tin-chung`);
        
    };

    useEffect(() => {
        productStore.fetchListProduct().then();
        productStore.fetchListBrand().then();
        productStore.fetchListCategory().then();
    }, []);
    return (
        <div className="container">
            <div className="mainContentProduct">
                <div className="filter">
                    <h1>Sản phẩm</h1>
                    <FilterCategoryProduct />
                    <FilterGroupProduct />
                    {/*<FilterLabelProduct/>*/}
                </div>
                <div className="content-page">
                    <div className="content-page-header">
                        <div className="content-page-header-left">
                            <form
                                className="content-page-header-search"
                                onSubmit={handleSearch}
                            >
                                <input
                                    type="text"
                                    name="keyword"
                                    value={
                                        productStore.staticDataListProduct
                                            .search
                                    }
                                    id="keyword"
                                    onChange={(e: any) =>
                                        (productStore.staticDataListProduct.search =
                                            e.currentTarget.value)
                                    }
                                    placeholder="Nhập tên sản phẩm"
                                />
                                <button type="submit">
                                    <img
                                        src="/assets/icon/search.svg"
                                        alt="search"
                                    />
                                </button>
                            </form>
                            <div className="content-page-header-sorting">
                                <button>
                                    <img
                                        src="/assets/icon/sorting.svg"
                                        alt="sorting"
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="content-page-header-right">
                            <Link
                                to="/hang-hoa/san-pham/them-moi"
                                className="content-page-header-right-plus"
                            >
                                <span>
                                    Thêm mới
                                    <img
                                        src="/assets/icon/plus.svg"
                                        alt="sorting"
                                    />
                                </span>
                            </Link>
                            <button className="content-page-header-right-import">
                                <span>
                                    Nhập sản phẩm
                                    <img
                                        src="/assets/icon/import-file.svg"
                                        alt="sorting"
                                    />
                                </span>
                            </button>
                            <button className="content-page-header-right-export">
                                <span>
                                    Xuất sản phẩm
                                    <img
                                        src="/assets/icon/export-file.svg"
                                        alt="sorting"
                                    />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className={`content-table`}>
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                width: "5%",
                                                textAlign: "center",
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                className="checkAll"
                                                checked={allSelected}
                                                onChange={(e) =>
                                                    selectAll(e.target.checked)
                                                }
                                            />
                                        </th>
                                        <th
                                            style={{
                                                width: "45%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Tên sản phẩm
                                        </th>
                                        <th
                                            style={{
                                                width: "18%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Loại
                                        </th>
                                        <th
                                            style={{
                                                width: "10%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Trạng thái
                                        </th>
                                        <th
                                            style={{
                                                width: "10%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Ngày tạo
                                        </th>
                                        <th
                                            style={{
                                                width: "12%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productStore.isLoading ? (
                                        <tr>
                                            <td
                                                colSpan={6}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : (
                                        productStore?.listProduct?.map(
                                            (item: any, index: number) => (
                                                <tr>
                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <div
                                                            className={`checkboxTable`}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                name="ids[]"
                                                                value={item.id}
                                                                checked={selected.includes(
                                                                    item.id
                                                                )}
                                                                onChange={(e) =>
                                                                    handleSelect(
                                                                        e.target
                                                                            .checked,
                                                                        item.id
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="info">
                                                            <div className="image">
                                                                <img
                                                                    src="/assets/images/no-image.svg"
                                                                    alt="no-image"
                                                                />
                                                            </div>
                                                            <div className="title-code">
                                                                <div className="title-code-content">
                                                                    <p className="title">
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {item.is_variation ===
                                                            1
                                                                ? "Biến thể"
                                                                : "Sản phẩm đơn giản"}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        {item.status === 1 ? (
                                                            <span className="success-status">
                                                                Hoạt động
                                                            </span>
                                                        ) : (
                                                            <span className="disable-status">
                                                                Vô hiệu hóa
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <p>
                                                            {new Date(
                                                                item.created_at
                                                            ).toLocaleDateString(
                                                                "en-GB"
                                                            )}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <Dropdown className="action-table">
                                                            <Dropdown.Toggle
                                                                variant="success"
                                                                id={`dropdown-basic${index}`}
                                                            >
                                                                Thao tác
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        handleEditClick(
                                                                            item.id
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fa fa-edit"></i>
                                                                    Sửa
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="javascript:void(0)">
                                                                    <i className="fa fa-trash"></i>
                                                                    Xóa
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    )}
                                </tbody>
                            </table>
                            {productStore.isLoading
                                ? ""
                                : Math.ceil(productStore.metadata.total_page) >
                                      1 && (
                                      <ReactPaginate
                                          previousLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-left.svg"
                                                  alt="right"
                                              />
                                          }
                                          nextLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-right.svg"
                                                  alt="right"
                                              />
                                          }
                                          breakLabel={"..."}
                                          pageCount={Math.ceil(
                                              productStore.metadata.total_page
                                          )}
                                          marginPagesDisplayed={2}
                                          pageRangeDisplayed={3}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          activeClassName={"active"}
                                          forcePage={
                                              productStore.staticDataListProduct
                                                  .page_number
                                          }
                                      />
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default observer(ProductPage);
