import { orderService } from "./OrderService";
import { makeAutoObservable } from "mobx";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";
import { Result } from "antd";

interface StaticParamOrder {
    endDate: string;
    startDate: string | null;
    order_by: string;
    order_status: string;
    order_time: string;
    order_type: string;
    size: string;
    page: number;
    payment_method: string;
    payment_status: string;
    search: string;
    store_id: string;
    total_type: string;
}

class OrderStore {
    isLoading = false;
    isLoadingDetail = false;
    isModalDetailOpen = false;

    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }

    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamOrder.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamOrder.startDate = query.get("startDate")
            ? String(query.get("startDate"))
            : "";
        this.staticParamOrder.endDate = query.get("endDate")
            ? String(query.get("endDate"))
            : "";
        this.staticParamOrder.payment_method = query.get("payment_method")
            ? String(query.get("payment_method"))
            : "";
        this.staticParamOrder.payment_status = query.get("payment_status")
            ? String(query.get("payment_status"))
            : "";
        this.staticParamOrder.order_status = query.get("order_status")
            ? String(query.get("order_status"))
            : "";
    }

    staticParamOrder: StaticParamOrder = {
        endDate: "",
        startDate: "",
        order_by: "",
        order_status: "",
        order_time: "",
        order_type: "",
        page: 0,
        size: "",
        payment_method: "",
        payment_status: "",
        search: "",
        store_id: "",
        total_type: "",
    };

    metadata: any = {
        size: 0,
        page: "",
        total_page: "",
        total: "",
        total_amount: "",
        total_order: "",
    };

    staticOrder: {
        id: string;
        order_status: string;
        payment_status: string;
    } = {
        id: "",
        order_status: "",
        payment_status: "",
    };

    handeClose = () => {
        orderStore.staticParamOrder.startDate = "";
        orderStore.staticParamOrder.endDate = "";
        orderStore.staticParamOrder.order_type = "";
        orderStore.staticParamOrder.payment_method = "";
        orderStore.staticParamOrder.order_status = "";
        orderStore.staticParamOrder.order_time = "";
        orderStore.staticParamOrder.order_by = "";
        orderStore.staticParamOrder.page = 0;
        orderStore.staticParamOrder.size = "";
        orderStore.staticParamOrder.payment_status = "";
        orderStore.staticParamOrder.search = "";
    };

    dataListOrder: any[] = [];
    dataListOrderItems = [];
    dataListOderById: any = [];

    async fetctAllListOder() {
        this.isLoading = true;
        let {
            endDate,
            startDate,
            order_by,
            order_status,
            order_time,
            order_type,
            page,
            size,
            payment_method,
            payment_status,
            search,
            store_id,
            total_type,
        } = this.staticParamOrder;

        const paramsOrder = {
            endDate: endDate,
            startDate: startDate,
            order_by: order_by,
            order_status: order_status,
            order_time: order_time,
            order_type: order_type,
            page: page,
            size: size,
            payment_method: payment_method,
            payment_status: payment_status,
            search: search,
            store_id: store_id,
            total_type: total_type,
        };
        const result = await orderService.fetchAllOrder(paramsOrder);
        this.isLoading = false;
        console.log("cherck reulst all order", result);
        if (result.status === HttpStatusCode.Ok) {
            this.dataListOrder = result.body.data;
            this.metadata = result.body.metadata;
        }
    }

    async fetchDetailOrder() {
        this.isLoadingDetail = true;
        let { id } = this.staticOrder;
        const result = await orderService.fetchGetDetail(id);
        this.isLoadingDetail = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataListOderById = result.body;
            this.dataListOrderItems = result.body.orderitems;
            // window.location.href = `/don-hang/${this.staticOrder.id}`;
        }
    }

    async fetchUpdateOrder() {
        let { id, order_status, payment_status } = this.staticOrder;
        const params = {
            id: id,
            order_status: order_status,
            payment_status: payment_status,
        };

        const resutl = await orderService.fetchPutOrder(params);
        if (resutl.status === HttpStatusCode.Ok) {
            toastUtils.success("Sửa thành công", "");
        }
        console.log("check result", resutl);
    }
}

export const orderStore = new OrderStore();
