import { Select } from "antd";
import { observer } from "mobx-react";
import React from "react";

interface SelectMultipleProps {
    list: any[];
    placeholder?: string;
    labelFirst?: string;
    onChange?: (value: any) => void;
    defaultValue?: any;
    getLabel: (item: any) => string;
}

const SelectMultipleAnt: React.FC<SelectMultipleProps> = ({
    list,
    placeholder,
    labelFirst,
    onChange,
    defaultValue,
    getLabel,
}) => {
    const options = [
        ...(labelFirst ? [{ value: '', label: labelFirst }] : []),
        ...list.map((item: any) => ({
            value: item.id,
            label: getLabel(item),
        })),
    ];
    return (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={defaultValue}
            onChange={onChange}
            options={options}
        />
    );
};

export default observer(SelectMultipleAnt);