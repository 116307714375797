import { makeAutoObservable } from "mobx";
import { dashboardService } from "./DashboardService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../common/utils/Toastutils";
interface DataItem {}

const today = new Date();
const date = today.getDate();

class DashboardStore {
    isLoading: boolean = false;
    isLoadingRevenue: boolean = false;
    listBanner: string[] = [];
    dataListStore: any[] = [];
    textDay: string = "7 ngày trước";
    textStore: string = "Tất cả chi nhánh";
    typeTimeSalesChart: string = "last_7_days";
    typeSalesChart: string = "date";
    typeCustomer: string = "date";
    typeTopSellers: string = "total";
    summary: any = {
        total_order: "",
        total_revenue: "",
        pending_count: "",
        cancel_count: "",
        completed_count: "",
        refund_count: "",
    };
    dataCustomer: any[] = [];
    dataRevenue: any[] = [];
    dataTopSellers: any[] = [];
    dataListNotifications: any[] = [];
    metadataNotifications: any = {
        size: "",
        page: "",
        total_page: "",
        total: "",
    };
    staticDataSalesChart: {
        startDate: string;
        endDate: string;
        store: number | undefined;
    } = {
        startDate: "",
        endDate: "",
        store: 0,
    };

    staticDataNotifications: {
        page: string;
        size: string;
        status: string | null;
    } = {
        page: "1",
        size: "20",
        status: null,
    };
    initializeDates(typeTimeSalesChart: string) {
        const today = new Date();
        let endDateString: any, startDate: any;
        if (typeTimeSalesChart == "today") {
            endDateString = today.toISOString().split("T")[0];
            const endDate = new Date(today);
            endDate.setDate(endDate.getDate() - 0);
            startDate = endDate.toISOString().split("T")[0];
        } else if (typeTimeSalesChart == "yesterday") {
            endDateString = today.toISOString().split("T")[0];
            const endDate = new Date(today);
            endDate.setDate(endDate.getDate() - 1);
            startDate = endDate.toISOString().split("T")[0];
            endDateString = startDate;
        } else if (typeTimeSalesChart == "last_7_days") {
            endDateString = today.toISOString().split("T")[0];
            const endDate = new Date(today);
            endDate.setDate(endDate.getDate() - 6);
            startDate = endDate.toISOString().split("T")[0];
        } else if (typeTimeSalesChart == "this_month") {
            startDate = new Date(today.getFullYear(), today.getMonth(), 1);
            startDate.setDate(startDate.getDate() + 1);
            endDateString = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0
            );
            startDate = startDate.toISOString().split("T")[0];
            endDateString = endDateString.toISOString().split("T")[0];
        } else if (typeTimeSalesChart == "last_month") {
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            startDate.setDate(startDate.getDate() + 1);
            endDateString = new Date(today.getFullYear(), today.getMonth(), 0);
            endDateString.setDate(endDateString.getDate() + 1);
            startDate = startDate.toISOString().split("T")[0];
            endDateString = endDateString.toISOString().split("T")[0];
        }
        this.staticDataSalesChart.startDate = startDate;
        this.staticDataSalesChart.endDate = endDateString;
    }
    async getBanner() {}
    async fetchDataStore() {
        this.isLoading = true;
        try {
            const result = await dashboardService.fetchDataStore();
            dashboardStore.dataListStore = result.body.data ?? [];
        } catch (error) {
            console.error("Error fetching revenue data:", error); // Xử lý lỗi nếu có
        } finally {
            this.isLoading = false;
        }
    }
    async fetchDataRevenue() {
        let { startDate, endDate, store } = this.staticDataSalesChart;
        const paramsRevenue = {
            startDate: startDate,
            endDate: endDate,
            store: store,
            type: this.typeSalesChart,
        };

        this.isLoadingRevenue = true;
        const result = await dashboardService.fetchDataRevenue(paramsRevenue);
        if (result.status == HttpStatusCode.Ok) {
            this.isLoadingRevenue = false;
            this.summary = result.body.summary;
            this.dataRevenue = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }

    async fetchDataCustomer() {
        this.initializeDates(this.typeTimeSalesChart);
        let { startDate, endDate, store } = this.staticDataSalesChart;
        const paramsRevenue = {
            startDate: startDate,
            endDate: endDate,
            store: store,
            type: this.typeCustomer,
        };
        const resultCustomer = await dashboardService.fetchDataCustomer(
            paramsRevenue
        );
        if (resultCustomer.status == HttpStatusCode.Ok) {
            this.dataCustomer = resultCustomer.body.data;
        } else {
            toastUtils.error(resultCustomer.body.message, "");
        }
    }
    async fetchDataTopSellers() {
        this.initializeDates(this.typeTimeSalesChart);
        let { startDate, endDate, store } = this.staticDataSalesChart;
        const paramsRevenue = {
            startDate: startDate,
            endDate: endDate,
            store: store,
            type: this.typeTopSellers,
        };
        const resultTopSellers = await dashboardService.fetchDataTopSellers(
            paramsRevenue
        );
        if (resultTopSellers.status == HttpStatusCode.Ok) {
            this.dataTopSellers = resultTopSellers.body;
        } else {
            toastUtils.error(resultTopSellers.body.message, "");
        }
    }
    async fetchDataNotifications() {
        let { page, size, status } = this.staticDataNotifications;
        let paramsNotifications = {
            page: page,
            size: size,
            status: status,
        };
        this.dataListNotifications = [
            ...this.dataListNotifications,
            { isLoading: true, title: "" },
            { isLoading: true, title: "" },
            { isLoading: true, title: "" },
        ];
        const resultNotifications = await dashboardService.fetchDataNotifications(paramsNotifications);
        if (resultNotifications.status === HttpStatusCode.Ok) {
            this.dataListNotifications = [
                ...this.dataListNotifications,
                ...resultNotifications.body.data.map((notification: any) =>
                    notification.status === 0
                        ? { ...notification, viewed: false }
                        : { ...notification, viewed: true }
                ),
            ].filter((item) => !item.isLoading);
            this.metadataNotifications = resultNotifications.body.metadata;
        } else {
            toastUtils.error(resultNotifications.body.message, "");
        }
    }
    async fetchUpdateStatusNotification(id: number | undefined) {
        const resultNotifications =
            await dashboardService.fetchUpdateStatusNotification(id);
        if (resultNotifications.status === HttpStatusCode.Ok) {
        } else {
            toastUtils.error(resultNotifications.body.message, "");
        }
    }
    async fetchUpdateStatusAllNotification() {
        const resultNotifications =
            await dashboardService.fetchUpdateStatusAllNotification();
        if (resultNotifications.status === HttpStatusCode.Ok) {
        } else {
            toastUtils.error(resultNotifications.body.message, "");
        }
    }
    constructor() {
        makeAutoObservable(this);
    }
}
export const dashboardStore = new DashboardStore();
