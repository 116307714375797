import { observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";
import "./styles/TagProduct.css";
import { useEffect } from "react";
import { tagProductStore } from "./TagProductStore";
import CreateTagProduct from "./components/CreateTagProduct";
import PutTagProduct from "./components/PutTagProduct";
import ReactPaginate from "react-paginate";
import Loading from "../../../shared/components/loading/Loading";
import React from "react";
import { Modal } from "antd";
import { createSearchParams, useNavigate } from "react-router-dom";
import { showDelete } from "../../../shared/components/showDelete";
import NoContent from "../../../shared/components/NoContent";

const { confirm } = Modal;
const TagProduct = () => {
    const navigate = useNavigate();
    useEffect(() => {
        tagProductStore.fetchAllTagProductList().then();
    }, []);

    const handleModalCustom = (item: any) => {
        console.log("item", item.id);
        tagProductStore.staticDataTagProduct.id = item.id;
        // tagProductStore.getDetailTagProduct();
        tagProductStore.setIsModalCustomOpen(true);
    };

    const handleChangePage = (e: any) => {
        const params: any = {};
        if (tagProductStore.staticParamTagProduct.page !== 0) {
            params.page = tagProductStore.staticParamTagProduct.page;
        }
        params.page = e.selected + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        console.log("check params", params);

        tagProductStore.staticParamTagProduct.page = e.selected;
        tagProductStore.fetchAllTagProductList();
    };

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const params: any = {};
        if (tagProductStore.staticParamTagProduct.search !== "") {
            params.search = tagProductStore.staticParamTagProduct.search;
            console.log(params);
        }
        navigate({
            search: createSearchParams(params).toString(),
        });

        tagProductStore.staticParamTagProduct.page = 0;
        tagProductStore.fetchAllTagProductList();
    };

    const showDeleteConfirm = (item: any) => {
        tagProductStore.staticDataTagProduct.id = item.id;
        showDelete.showDeleteConfirm(
            "Xác nhận xóa",
            "Bạn có chắc muốn xóa mục này",
            () => {
                tagProductStore.fetchDeleteTagProduct();
            }
        );
    };

    return (
        <>
            <div className="container containerTagProduct">
                <CreateTagProduct />
                <PutTagProduct />
                <div className="mainContentTagProduct">
                    <div className="filter">
                        <div className="filter-left">
                            <h1>Thẻ sản phẩm</h1>
                            <div className="filter-search">
                                <form
                                    className="filter-search"
                                    onSubmit={handleSearch}
                                >
                                    <input
                                        type="text"
                                        placeholder="Nhập thẻ sản phẩm"
                                        value={
                                            tagProductStore
                                                .staticParamTagProduct.search
                                        }
                                        onChange={(e) =>
                                            (tagProductStore.staticParamTagProduct.search =
                                                e.target.value)
                                        }
                                    />
                                    <button type="submit">
                                        <img
                                            src="/assets/icon/search.svg"
                                            alt="search"
                                        />
                                    </button>
                                </form>
                            </div>
                        </div>

                        <button
                            className="btn-add-modal"
                            onClick={tagProductStore.showModal}
                        >
                            <span>
                                Thêm mới
                                <img
                                    src="/assets/icon/plus.svg"
                                    alt="sorting"
                                />
                            </span>
                        </button>
                    </div>
                    <div className={`content-table`}>
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                width: "12%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Tên
                                        </th>
                                        <th
                                            style={{
                                                width: "27.666%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Ngày Tạo
                                        </th>
                                        <th
                                            style={{
                                                width: "27.666%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Nổi bật
                                        </th>
                                        <th
                                            style={{
                                                width: "27.666%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Trạng Thái
                                        </th>
                                        <th
                                            style={{
                                                width: "12%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tagProductStore.isLoading ? (
                                        <tr>
                                            <td
                                                colSpan={5}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : tagProductStore.dataListTagProduct
                                          .length === 0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                {" "}
                                                <NoContent />{" "}
                                            </td>
                                        </tr>
                                    ) : (
                                        tagProductStore.dataListTagProduct.map(
                                            (item: any, index: number) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="info d-flex justify-content-center">
                                                                    <div className="title-code">
                                                                        <div className="title-code-content">
                                                                            <p
                                                                                className="code"
                                                                                style={{
                                                                                    textAlign:
                                                                                        "center",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <span className="create_at">
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}{" "}
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleTimeString(
                                                                        "en-GB"
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {item.is_featured ===
                                                                1 ? (
                                                                    <span className="hot hot-active">
                                                                        Nổi bật
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className="hot hot-disable"
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#FE5139",
                                                                        }}
                                                                    >
                                                                        Không
                                                                        nổi bật
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {item.status ===
                                                                1 ? (
                                                                    <span className="status status-active">
                                                                        Hoạt
                                                                        động
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className="status status-disable"
                                                                        style={{
                                                                            backgroundColor:
                                                                                "#888888",
                                                                        }}
                                                                    >
                                                                        Vô hiệu
                                                                        hóa
                                                                    </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Dropdown className="action-table">
                                                                    <Dropdown.Toggle
                                                                        variant="success"
                                                                        id="dropdown-basic1"
                                                                    >
                                                                        Thao tác
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            href="javascript:void(0)"
                                                                            onClick={() => {
                                                                                tagProductStore.initialValues.id =
                                                                                    item.id;
                                                                                tagProductStore.staticDataTagProduct.id =
                                                                                    item.id;
                                                                                tagProductStore.initialValues.name =
                                                                                    item.name;
                                                                                tagProductStore.initialValues.is_featured =
                                                                                    item.is_featured.toString();
                                                                                tagProductStore.initialValues.status =
                                                                                    item.status.toString();
                                                                                tagProductStore.setIsModalCustomOpen(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                            Sửa
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            href="javascript:void(0)"
                                                                            onClick={() =>
                                                                                showDeleteConfirm(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                            Xóa
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            }
                                        )
                                    )}
                                </tbody>
                            </table>
                            {tagProductStore.isLoading
                                ? ""
                                : Math.ceil(
                                      tagProductStore.metadata.total_page
                                  ) > 1 && (
                                      <ReactPaginate
                                          previousLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-left.svg"
                                                  alt="right"
                                              />
                                          }
                                          nextLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-right.svg"
                                                  alt="right"
                                              />
                                          }
                                          breakLabel={"..."}
                                          pageCount={Math.ceil(
                                              tagProductStore.metadata
                                                  .total_page
                                          )}
                                          marginPagesDisplayed={2}
                                          pageRangeDisplayed={3}
                                          containerClassName={"pagination"}
                                          activeClassName={"active"}
                                          onPageChange={handleChangePage}
                                          forcePage={
                                              tagProductStore
                                                  .staticParamTagProduct.page
                                          }
                                      />
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(TagProduct);
