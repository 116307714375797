import { Select } from "antd";
import { observer } from "mobx-react";
import { groupProductStore } from "../../GroupProductStore";
import React from "react";
interface SelectCompareProps{
    onChange: (value: any)=> void,
    value: string,
}
const SelectCompare : React.FC<SelectCompareProps> = ({onChange, value})=>{
    return(
        <>
        <Select
        value={value}
        onChange={onChange}
        options={[
            { value: '=', label: 'bằng', disabled: true },
            { value: '!=', label: 'không bằng', disabled: true },
            { value: 'like%', label: 'bắt đầu với', disabled: true },
            { value: '%like', label: 'kết thúc với', disabled: true },
            { value: '%like%', label: 'có chứa' },
            { value: 'not like', label: 'không chứa' },
        ]}/>
        </>
    )
}
export default observer(SelectCompare);
