import {
    getRequest,
    postRequest,
    putRequest,
} from "../../../common/helpers/RequestHelper";

class ProductService {
    apiSubdirectory = "api/v1/products";
    apiSubFeature = "api/v1/features";
    apiSubProductCategories = "api/v1/product_categories";
    apiSubBrand = "api/v1/brands";
    apiSubTag = "api/v1/categorytags";
    apiSubAttribute = "api/v1/attributes";
    apiGenerateAttributes = "api/v1/products/generate_variant";
    public fetchListFeature(): Promise<any> {
        return getRequest(`${this.apiSubFeature}`);
    }
    public fetchListCategory(): Promise<any> {
        return getRequest(`${this.apiSubProductCategories}`);
    }
    public fetchListBrand(): Promise<any> {
        return getRequest(`${this.apiSubBrand}`);
    }
    public fetchListTags(): Promise<any> {
        return getRequest(`${this.apiSubTag}`);
    }
    public fetchListAttribute(): Promise<any> {
        return getRequest(`${this.apiSubAttribute}`);
    }
    public fetchGenerateAttributes(params: any): Promise<any> {
        return postRequest(`${this.apiGenerateAttributes}`, params);
    }
    public fetchListProduct(params: any): Promise<any> {
        const queryParameters = [
            params.brand_id ? `brand_id=${params.brand_id}` : "",
            params.category_id ? `category_id=${params.category_id}` : "",
            params.offset ? `offset=${params.offset}` : "",
            params.order_column ? `order_column=${params.order_column}` : "",
            params.order_value ? `order_value=${params.order_value}` : "",
            params.page_number ? `page_number=${params.page_number}` : "",
            params.per_page ? `per_page=${params.per_page}` : "",
            params.search ? `search=${params.search}` : "",
            params.status ? `status=${params.status}` : "",
            params.tag_id ? `tag_id=${params.tag_id}` : "",
        ]
            .filter(Boolean)
            .join("&");
        return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
    }
    public fetchStoreProduct(params: any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`, params);
    }
    public fetchStoreProductUpdate(id: number, params: any): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${id}`, params);
    }
    public fetchGetProduct(id: string, params: any): Promise<any> {
        return getRequest(
            `${this.apiSubdirectory}/${id}?request_type=${params.request_type}`
        );
    }
}

export const productService = new ProductService();
