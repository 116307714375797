import { Modal, Radio, Input, Select, Button } from "antd";
import { observer } from "mobx-react";
import { groupProductStore } from "../GroupProductStore";
import Loading from "../../../../shared/components/loading/Loading";
import { createSearchParams, useNavigate } from "react-router-dom";


const ProductChoosingModal = () => {
        
    function onSubmitSearch(){
        groupProductStore.getDataListProductViaParam(groupProductStore.searchKeywordProduct);
    }
    function onHandleKeyDown(event: any){
        if(event.key === 'Enter'){
            onSubmitSearch();
        }
    }
    return (
        <Modal className="modal-form modal-form--product" title="Tìm kiếm sản phẩm" footer={null} open={groupProductStore.openModalProduct} onCancel={() => {groupProductStore.openModalProduct = false }}>
            <div className="table__container">
                <div className="table__search">
                    <Input onKeyDown={(event)=>{onHandleKeyDown(event)}} placeholder="Vui lòng nhập" onChange={(event)=>{groupProductStore.searchKeywordProduct = event.target.value}}></Input>
                    <Button type="primary" onClick={()=>onSubmitSearch()}><img src="/assets/icon/search.svg" alt="" /></Button>
                </div>
                <div className="table__body">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width: '5%', textAlign: 'center', borderRadius: '4px 0px 0px 4px'}}>
                                </th>
                                <th style={{width: '95%', textAlign: 'left', borderRadius: '0px 4px 4px 0px'}}>
                                    Tên sản phẩm
                                </th>
                            </tr>
                        </thead>
                        {groupProductStore.isLoadingProductList === true?(<tbody><tr><td colSpan={2}><Loading/></td></tr></tbody>):
                        (<tbody>
                            {groupProductStore.dataListProduct.map((product, index)=>(
                                <tr key={index}>
                                    <td>
                                        <input type="checkbox" id={product.id} value={product.id} checked={groupProductStore.idProductList.includes(product.id)} onChange={(e) => groupProductStore.handleSelectProductInTable(e.target.checked, Number(e.target.value))}/>
                                    </td>
                                    <td><label style={{width: '100%', height:'100%'}} htmlFor={product.id}>{product.title}</label></td>
                                </tr>
                            ))}
                        </tbody>)}
                    </table>
                </div>
            </div>
            <div className="btnCreateModal">
                <button className="cancelModal" type="button" onClick={() => { groupProductStore.openModalProduct = false; }}>Huỷ</button>
                <button className="submitForm" type="submit" onClick={()=>{groupProductStore.openModalProduct = false}}>Xác nhận</button>
            </div>
        </Modal>
    )
}

export default observer(ProductChoosingModal)
