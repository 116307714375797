import { observer } from "mobx-react"
import { groupProductStore } from "../../GroupProductStore";
import { Button } from "antd";
import SelectSortConditionProduct from "./SelectSortConditionProduct";
import NoContent from "../../../../../shared/components/NoContent";
import Loading from "../../../../../shared/components/loading/Loading";
const OptionalOption = ()=>{
    return(<>
        <div className="item-form item-form--custom">
            <label>Nhóm sản phẩm</label>
            <div style={{marginTop: "12px", display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '12px'}}>
                <Button disabled={groupProductStore.isLoadingProductList} type="primary" onClick={()=>{
                    groupProductStore.openModalProduct = true;
                    }}>Tìm kiếm sản phẩm
                </Button>
                <SelectSortConditionProduct/>
            </div>
            <div className="product-table">
                <table>
                    <tbody>
                        {groupProductStore.isLoadingProductList === true ? (<><tr><td><Loading/></td></tr></>):(<>
                            {groupProductStore.idProductList.length === 0 ?
                            (<tr><td><NoContent/></td></tr>):
                            (<>
                                {groupProductStore.dataListProductAll.filter((item:any)=> groupProductStore.idProductList.includes(item.id)).map((element:any, index:number)=>(
                                    <tr key={index}>
                                        <td style={{width: '5%'}}><Button type="text" danger onClick={()=>{groupProductStore.handleRemoveItemInIDProductList(Number(index))}}><i className="fas fa-trash"/></Button></td>
                                        <td style={{width: '90%', marginLeft: '5%'}}>{element.title}</td>
                                    </tr>
                                ))}
                            </>)
                            }
                        </>)}
                    </tbody>
                </table>
            </div>
        </div>
    </>)
}
export default observer(OptionalOption)