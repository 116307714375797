import "./printorder.css";
import { observer } from "mobx-react";
import { orderStore } from "../OrderStore";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PrintOrder = () => {
    const navigate = useNavigate();
    const componentRef = useRef<any>(null);
    const { id } = useParams<{ id: string }>();
    orderStore.staticOrder.id = `${id}`;
    useEffect(() => {
        orderStore.fetchDetailOrder();
    }, []);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleDownloadPDF = () => {
        const input = componentRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
            pdf.save(`don-hang-${orderStore.staticOrder.id}.pdf`);
        });
    };

    return (
        <>
            <div className="printWrapper">
                <div className="btnAction d-flex align-items-center justify-content-center">
                    <button
                        className="goBack"
                        onClick={() =>
                            navigate(`/don-hang/${orderStore.staticOrder.id}`)
                        }
                    >
                        Quay lại đơn
                    </button>
                    <button
                        className="downloadBill"
                        onClick={handleDownloadPDF}
                    >
                        Tải hóa đơn
                    </button>
                    <button className="printBill" onClick={handlePrint}>
                        In hóa đơn
                    </button>
                </div>
                <div ref={componentRef} className="print">
                    <div className="info d-flex justify-content-between align-items-center">
                        <p>Thông tin người đặt</p>

                        <ul>
                            <li>
                                Ngày Hôm nay:{" "}
                                <span>
                                    {new Date().toLocaleDateString("en-GB")}
                                </span>
                            </li>
                            <li>
                                {orderStore.dataListOderById?.customer
                                    ?.billingName || "Đang tải dữ liệu"}
                            </li>
                            <li>
                                {orderStore.dataListOderById?.customer?.email ||
                                    "Đang tải dữ liệu"}
                            </li>
                            <li>
                                {orderStore.dataListOderById?.customer
                                    ?.order_phone || "Đang tải dữ liệu"}
                            </li>
                        </ul>
                    </div>

                    <table>
                        <tr
                            style={{
                                backgroundColor: "#EEEEEE",
                            }}
                        >
                            <th>Tiêu đề</th>
                            <th>Chi tiết</th>
                        </tr>
                        <tr>
                            <td>Ngày tạo yêu cầu:</td>
                            <td>
                                {new Date(
                                    orderStore.dataListOderById?.created_at
                                ).toLocaleDateString("en-GB")}
                            </td>
                        </tr>
                        <tr>
                            <td>Hóa đơn:</td>
                            <td>
                                {orderStore.dataListOderById?.invoice_no ||
                                    "Đang tải dữ liệu"}
                            </td>
                        </tr>
                        <tr>
                            <td>Trạng thái:</td>
                            <td>
                                {orderStore.dataListOderById?.status_id
                                    ? orderStore.dataListOderById.status_id ===
                                      "COMPLETED"
                                        ? "Hoàn thành"
                                        : orderStore.dataListOderById
                                              .status_id === "CANCEL"
                                        ? "Hủy bỏ"
                                        : orderStore.dataListOderById
                                              .status_id === "PENDING"
                                        ? "Đang xử lý"
                                        : orderStore.dataListOderById
                                              .status_id === "REFUND"
                                        ? "Hoàn trả"
                                        : ""
                                    : "Đang tải dữ liệu"}
                            </td>
                        </tr>
                        <tr>
                            <td>Trạng thái thanh toán:</td>
                            <td>
                                {orderStore.dataListOderById?.payment_status
                                    ? orderStore.dataListOderById
                                          .payment_status === "COMPLETE"
                                        ? "Hoàn thành"
                                        : orderStore.dataListOderById
                                              .payment_status === "CANCEL"
                                        ? "Hủy bỏ"
                                        : orderStore.dataListOderById
                                              .payment_status === "PENDING"
                                        ? "Đang xử lý"
                                        : orderStore.dataListOderById
                                              .payment_status === "REFUND"
                                        ? "Hoàn trả"
                                        : orderStore.dataListOderById
                                              .payment_status === "FAILED"
                                        ? "Thất bại"
                                        : ""
                                    : "Đang tải dữ liệu"}
                            </td>
                        </tr>
                        <tr>
                            <td>Giảm giá:</td>
                            <td>
                                {orderStore.dataListOderById?.discount_type}
                                {new Intl.NumberFormat("vi-VN").format(
                                    orderStore.dataListOderById?.discount
                                ) || "Đang tải dữ liệu"}{" "}
                                VND
                            </td>
                        </tr>
                        <tr>
                            <td>Phí ship:</td>
                            <td>
                                {orderStore.dataListOderById?.is_shipped ||
                                    "Đang tải dữ liệu"}
                            </td>
                        </tr>
                        <tr>
                            <td>Tổng cộng</td>
                            <td>
                                {new Intl.NumberFormat("vi-VN").format(
                                    orderStore.dataListOderById?.total
                                ) || "Đang tải dữ liệu"}{" "}
                                VND
                            </td>
                        </tr>
                        <tr>
                            <td>Thời gian đặt hàng:</td>
                            <td>
                                {new Date(
                                    orderStore.dataListOderById?.created_at
                                ).toLocaleTimeString("en-GB") ||
                                    "Đang tải dữ liệu"}
                            </td>
                        </tr>
                        <tr
                            style={{
                                backgroundColor: "#EEEEEE",
                            }}
                        >
                            <th>Sản phẩm</th>
                            <th>Số lượng</th>
                        </tr>
                        <tr
                            style={{
                                borderBottom: "none",
                            }}
                        >
                            <td>
                                {orderStore.dataListOderById?.orderitems
                                    ?.length > 0 && (
                                    <>
                                        {
                                            orderStore.dataListOderById
                                                .orderitems[0].product.title
                                        }
                                    </>
                                )}
                            </td>
                            <td>
                                {orderStore.dataListOderById?.orderitems
                                    ?.length > 0 && (
                                    <>
                                        {
                                            orderStore.dataListOderById
                                                .orderitems[0].qty
                                        }
                                    </>
                                )}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </>
    );
};

export default observer(PrintOrder);
