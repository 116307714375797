import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {inventoryStore} from "./InventoryStore";
import Loading from "../../../shared/components/loading/Loading";
import {productStore} from "../product/ProductStore";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
const Inventory = () => {
    const navigate = useNavigate();
    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const searchKeyword = (e.target as HTMLFormElement).keyword.value;
        const params: any = {};
        if (searchKeyword !== '') params.keyword = searchKeyword;
        navigate({
            search: createSearchParams(params).toString()
        });
        inventoryStore.staticAllInventoryProduct.page_number = 0
        productStore.fetchListProduct();
    };
    useEffect(() => {
        inventoryStore.fetchAllInventoryProduct().then()
    }, []);
    return(
        <>
            <div className="container">
                <div className="mainContentProduct">
                    <div className="filter">
                        <h1>Tồn kho</h1>
                        {/*<FilterCategoryProduct/>*/}
                        {/*<FilterGroupProduct/>*/}
                        {/*<FilterLabelProduct/>*/}
                    </div>
                    <div className="content-page">
                        <div className="content-page-header">
                            <div className="content-page-header-left">
                                <form className="content-page-header-search" onSubmit={handleSearch}>
                                    <input
                                        type="text"
                                        name="keyword"
                                        value={inventoryStore.staticAllInventoryProduct.search}
                                        id="keyword"
                                        onChange={(e: any) => inventoryStore.staticAllInventoryProduct.search = e.currentTarget.value}
                                        placeholder="Nhập tên sản phẩm, sku, ..."
                                    />
                                    <button type="submit">
                                        <img src="/assets/icon/search.svg" alt="search"/>
                                    </button>
                                </form>
                                {/*<div className="content-page-header-sorting">*/}
                                {/*    <button>*/}
                                {/*        <img src="/assets/icon/sorting.svg" alt="sorting"/>*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className={`content-table`}>
                            <div className="table-responsive">
                                <table className="table table-striped" id="table-2">
                                    <thead>
                                    <tr>
                                        <th style={{width: '20%', textAlign: 'left'}}>Sản phẩm</th>
                                        <th style={{width: '20%', textAlign: 'left'}}>Hình ảnh</th>
                                        <th style={{width: '10%', textAlign: 'left'}}>Biến thể</th>
                                        <th style={{width: '10%', textAlign: 'left'}}>Loại</th>
                                        <th style={{width: '12%', textAlign: 'left'}}>Sku</th>
                                        <th style={{width: '12%', textAlign: 'left'}}>Hành động</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {inventoryStore.isLoading ? (
                                        <tr>
                                            <td colSpan={4} style={{textAlign: 'center'}}>
                                                <Loading/>
                                            </td>
                                        </tr>
                                    ) : (
                                        inventoryStore?.listInventoryProduct?.map((item: any, index: number) => (
                                            <tr>
                                                <td>
                                                    Sản phẩm
                                                </td>
                                                <td>
                                                    Hình ảnh
                                                </td>
                                                <td>
                                                    Biến thể
                                                </td>
                                                <td>
                                                    Đơn giản
                                                </td>
                                                <td>
                                                    Sku
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        ))
                                    )
                                    }
                                    </tbody>
                                </table>
                                {/*{productStore.isLoading ? '' : (Math.ceil(productStore.metadata.total_page) > 1 && (*/}
                                {/*    <ReactPaginate*/}
                                {/*        previousLabel={<img src="/assets/icon/fi-rr-angle-small-left.svg" alt="right"/>}*/}
                                {/*        nextLabel={<img src="/assets/icon/fi-rr-angle-small-right.svg" alt="right"/>}*/}
                                {/*        breakLabel={"..."}*/}
                                {/*        pageCount={Math.ceil(productStore.metadata.total_page)}*/}
                                {/*        marginPagesDisplayed={2}*/}
                                {/*        pageRangeDisplayed={3}*/}
                                {/*        // onPageChange={handlePageClick}*/}
                                {/*        containerClassName={"pagination"}*/}
                                {/*        activeClassName={"active"}*/}
                                {/*        forcePage={productStore.staticDataListProduct.page_number}*/}
                                {/*    />))}*/}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(Inventory)