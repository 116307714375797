import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./styles/Login.css";
import { observer } from "mobx-react-lite";
import { loginStore } from "./LoginStore";
import Loading from "../../shared/components/loading/Loading";
import StorageService from "../../common/service/StorageService";
const storageService = new StorageService();
const Login = () => {
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        loginStore.checkDomain();
    };

    const endpoint: any = localStorage.getItem("domainName");

    const handleSubmitLogin = (e: React.FormEvent) => {
        e.preventDefault();
        loginStore.login(endpoint);
    };

    // console.log("domain store", loginStore.domain_name);
    // console.log("isforgotpass", loginStore.isForgetPassWord);

    return (
        <div className="login d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="loginForm">
                    <div className="loginFormSignIn d-flex">
                        <div className="loginFormSigninLeft d-flex flex-column">
                            {localStorage.getItem("domainName") ? (
                                <p
                                    className="back"
                                    onClick={() => {
                                        localStorage.removeItem("domainName");
                                        window.location.reload();
                                    }}
                                    style={{
                                        color: "#0e72ed",
                                    }}
                                >
                                    Trở lại
                                </p>
                            ) : (
                                ""
                            )}

                            <h2 className="icon">
                                <img src="/assets/images/logo.svg" alt="logo" />
                            </h2>

                            <div className="heading">
                                {localStorage.getItem("domainName") ? (
                                    <>
                                        <p>Đăng Nhập</p>
                                        <p>để vào Trang Quản Trị</p>
                                    </>
                                ) : loginStore.isForgetPassWord ? (
                                    <>
                                        <p>Lấy Lại Mật Khẩu</p>
                                    </>
                                ) : (
                                    <>
                                        <p>Nhập Domain</p>
                                        <p>để vào Đăng nhập</p>
                                    </>
                                )}
                            </div>

                            {!localStorage.getItem("domainName") ? (
                                <Form
                                    className={`idTenant d-flex flex-column`}
                                    onSubmit={handleSubmit}
                                >
                                    <div className="formIdTenant d-flex align-items-center">
                                        <Form.Control
                                            type="text"
                                            id="inputUsername"
                                            aria-describedby="usernameHelpBlock"
                                            placeholder="Nhập Domain Của Bạn"
                                            style={{
                                                paddingLeft: "0px",
                                            }}
                                            onChange={(e) =>
                                                loginStore.setApiUrl(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>

                                    <button
                                        className="btnSubmit d-flex align-items-center justify-content-center"
                                        type="submit"
                                    >
                                        {loginStore.isLoading ? (
                                            <Loading color="#fff" />
                                        ) : (
                                            `Tiếp Tục`
                                        )}
                                    </button>
                                </Form>
                            ) : (
                                <>
                                    {!loginStore.isForgetPassWord ? (
                                        <Form
                                            className={`account d-flex flex-column`}
                                            onSubmit={handleSubmitLogin}
                                        >
                                            <div className="formUser d-flex align-items-center">
                                                <Form.Control
                                                    type="text"
                                                    id="inputUsername"
                                                    aria-describedby="usernameHelpBlock"
                                                    placeholder="Nhập email"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                    onChange={(e) =>
                                                        loginStore.setUserName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="formPassword d-flex align-items-center">
                                                <Form.Control
                                                    type="password"
                                                    id="inputPassword"
                                                    aria-describedby="passwordHelpBlock"
                                                    placeholder="Nhập password"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                    onChange={(e) =>
                                                        loginStore.setPassWord(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <button className="btnSubmit d-flex align-items-center justify-content-center">
                                                {loginStore.isLoading ? (
                                                    <Loading color="#fff" />
                                                ) : (
                                                    `Đăng Nhập`
                                                )}
                                            </button>

                                            <span
                                                className="forgetPassword"
                                                onClick={() => {
                                                    loginStore.setisForgetPassWord(
                                                        true
                                                    );
                                                }}
                                            >
                                                Quên mật khẩu?
                                            </span>
                                        </Form>
                                    ) : (
                                        <Form
                                            className={`formForgetPass ${
                                                loginStore.isForgetPassWord
                                                    ? "d-flex"
                                                    : "d-none"
                                            } flex-column`}
                                        >
                                            <div className="formUser d-flex align-items-center">
                                                <Form.Control
                                                    type="text"
                                                    id="inputUsername"
                                                    aria-describedby="usernameHelpBlock"
                                                    placeholder="Nhập email của bạn"
                                                    style={{
                                                        paddingLeft: "0px",
                                                    }}
                                                />
                                            </div>

                                            <button className="btnSubmit d-flex align-items-center justify-content-center">
                                                Đặt lại mật khẩu
                                            </button>

                                            <span
                                                onClick={() => {
                                                    loginStore.setisForgetPassWord(
                                                        false
                                                    );
                                                }}
                                            >
                                                Đăng nhập
                                            </span>
                                        </Form>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="loginFormSigninRight d-flex align-items-center justify-content-center flex-column">
                            <div className="imgWrapper">
                                <img
                                    src="/assets/images/login_img_slide_1svg.svg"
                                    alt=""
                                />
                            </div>

                            <div className="imgHeading d-flex flex-column align-items-center">
                                <p>MFA for all accounts</p>

                                <span>
                                    Secure online accounts with OneAuth 2FA.
                                    Back up OTP secrets and never lose access to
                                    your accounts.
                                </span>

                                <button>Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//* Observer sẽ theo dõi sự thay đổi
export default observer(Login);
