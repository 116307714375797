import {Select} from "antd";
import {observer} from "mobx-react";
import React from "react";

interface SelectCustomProps {
    list: any[];
    placeholder: string;
    labelFirst?: string;
    onChange: (value: any) => void;
    selected: () => any;
    getLabel: (item: any) => string;
}
const SelectCustom: React.FC<SelectCustomProps> = ({ list,placeholder,labelFirst,onChange,selected,getLabel}) =>{
    return(
        <>
            <Select
                placeholder={placeholder}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                value={selected()}
                onChange={onChange}
                options={[
                    ...(labelFirst ? [{ value: '', label: labelFirst }] : []),
                    ...list.map((item: any) => ({
                        value: item.id,
                        label: getLabel(item),
                    })),
                ]}
            />
        </>
    )
}
export default observer(SelectCustom);