import { getRequest, postRequest, deleteRequest, putRequest } from "../../../common/helpers/RequestHelper";

class GroupProductService{
    apiSubdirectory = 'api/v1/group_products';
    public fetchGroupsProduct(paramGroupsProduct:any): Promise<any>{
        const queryParam = [
            paramGroupsProduct.limit ? `limit=${paramGroupsProduct.limit}`:'',
            paramGroupsProduct.offset ? `offset=${paramGroupsProduct.offset}` : '' ,
            paramGroupsProduct.order_by ? `order_by=${paramGroupsProduct.order_by}` : '' ,
            paramGroupsProduct.page_number ? `page_number=${paramGroupsProduct.page_number}` : '' ,
            paramGroupsProduct.per_page ? `per_page=${paramGroupsProduct.per_page}` : '' ,
            paramGroupsProduct.search ? `search=${paramGroupsProduct.search}` : '' ,
            paramGroupsProduct.status ? `status=${paramGroupsProduct.status}` : '' ,
        ].filter(Boolean).join('&');
        return getRequest(`${this.apiSubdirectory}?${queryParam}`)
    }
    public fetchGroupsProductAll(): Promise<any>{
        return getRequest(`${this.apiSubdirectory}?per_page=10`)
    }
    public fetchSpecificGroupProduct(dataGroupProduct:any): Promise<any>{
        return getRequest(`${this.apiSubdirectory}/${dataGroupProduct.id}`)
    }
    public fetchCreatingNewGroupProduct(dataGroupProduct: any):Promise<any>{
        return postRequest(`${this.apiSubdirectory}`, dataGroupProduct)
    }
    public fetchModifyingGroupProduct(dataGroupProduct:any): Promise<any>{
        return putRequest(`${this.apiSubdirectory}/${dataGroupProduct.id}`, dataGroupProduct)
    }
    public fetchDeletingGroupProduct(dataGroupProduct:any): Promise<any>{
        return deleteRequest(`${this.apiSubdirectory}/${dataGroupProduct.id}`)
    }
}

export const groupProductService = new GroupProductService();