import { observer } from "mobx-react";
import { orderStore } from "./OrderStore";
import { useEffect, useState } from "react";
import "./order.css";
import { Button } from "react-bootstrap";
import type { RadioChangeEvent } from "antd";
import { DatePicker, Radio } from "antd";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import Loading from "../../shared/components/loading/Loading";
import NoContent from "../../shared/components/NoContent";
import { RangePickerProps } from "antd/es/date-picker";
import ReactPaginate from "react-paginate";

const Order = () => {
    useEffect(() => {
        orderStore.fetctAllListOder();
    }, []);

    const dateFormat = "YYYY-MM-DD";
    const { RangePicker } = DatePicker;
    const navigate = useNavigate();
    const [valueLTT, setValueLTT] = useState<string>("");
    const [valueTT, setValueTT] = useState<string>("");
    const [valueTTOrder, setValueTTOrder] = useState<string>("");

    const plainOptionsOrdermethod = [
        "Thanh toán khi nhận hàng",
        "Chuyển khoản",
    ];
    const plainOptionsInfoPaymentMethod = [
        "Hoàn trả",
        "Hủy bỏ",
        "Đang xử lý",
        "Hoàn thành",
        "Thất bại",
    ];
    const plainOptionsOderType = ["POS", "Website"];
    const plainOptionsInfoOderType = [
        "Hoàn thành",
        "Hủy bỏ",
        "Đang xử lý",
        "Hoàn trả",
    ];
    const plainOptionsInfoOrderType = [
        "SUCCESS",
        "CANCEL",
        "PENDING",
        "REFUND",
    ];
    const handleSeeDetail = (item: any) => {
        navigate(`/don-hang/${item.id}`);
        orderStore.staticOrder.payment_status = `${item.payment_status}`;
        orderStore.staticOrder.order_status = `${item.status_id}`;
        console.log("check item", item);
    };

    const handleSearchOrder = (e: React.FormEvent) => {
        e.preventDefault();
        const params: any = {};
        if (orderStore.staticParamOrder.search !== "") {
            params.search = orderStore.staticParamOrder.search;
        }
        navigate({
            search: createSearchParams(params).toString(),
        });
        orderStore.fetctAllListOder();
    };

    const handleChangeDate: RangePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        orderStore.staticParamOrder.startDate = dateString[0] as string;
        orderStore.staticParamOrder.endDate = dateString[1] as string;

        const params: any = {};
        if (
            orderStore.staticParamOrder.startDate !== "" &&
            orderStore.staticParamOrder.endDate
        ) {
            params.startDate = orderStore.staticParamOrder.startDate;
            params.endDate = orderStore.staticParamOrder.endDate;
        }
        navigate({
            search: createSearchParams(params).toString(),
        });

        orderStore.fetctAllListOder();
    };

    const handleClearFilter = () => {
        window.scroll(0, 0);
        orderStore.handeClose();
        //* Clear params khi ma xoa loc
        navigate({
            search: createSearchParams({}).toString(),
        });
        setValueLTT("");
        setValueTT("");
        setValueTTOrder("");
        orderStore.fetctAllListOder();
    };

    const handleChangePage = (e: any) => {
        orderStore.staticParamOrder.page = e.selected;
        orderStore.fetctAllListOder();
    };

    console.log("check type orderStatus", orderStore.staticOrder.order_status);

    return (
        <>
            <div className="container">
                <div className="mainContentOrder d-flex">
                    <div className="filter">
                        <h1>Mã giảm giá</h1>

                        <div className="filterWrapper">
                            <div className="filterOrder">
                                <div
                                    className="filterOrder-datePicker d-flex flex-column"
                                    style={{
                                        gap: "8px",
                                    }}
                                >
                                    <label>Chọn thời gian</label>
                                    <RangePicker onChange={handleChangeDate} />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>Loại thanh toán</label>
                                    <Radio.Group
                                        options={plainOptionsOrdermethod}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            setValueLTT(value);
                                            const selectedIndex =
                                                plainOptionsOrdermethod.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex === 0) {
                                                orderStore.staticParamOrder.payment_method =
                                                    "COD";
                                            } else if (selectedIndex === 1) {
                                                orderStore.staticParamOrder.payment_method =
                                                    "BANK_CASH";
                                            }
                                            const params: any = {};
                                            if (
                                                orderStore.staticParamOrder
                                                    .payment_method !== ""
                                            ) {
                                                params.payment_method =
                                                    orderStore.staticParamOrder.payment_method;
                                            }
                                            navigate({
                                                search: createSearchParams(
                                                    params
                                                ).toString(),
                                            });
                                            orderStore.fetctAllListOder();
                                        }}
                                        value={valueLTT}
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>TT Thanh toán</label>
                                    <Radio.Group
                                        options={plainOptionsInfoPaymentMethod}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            setValueTT(value);
                                            const selectedIndex =
                                                plainOptionsInfoPaymentMethod.findIndex(
                                                    (option) => option === value
                                                );
                                            if (selectedIndex === 0) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "refund";
                                            } else if (selectedIndex === 1) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "CANCEL";
                                            } else if (selectedIndex === 2) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "PENDING";
                                            } else if (selectedIndex === 3) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "SUCCESS";
                                            } else if (selectedIndex === 4) {
                                                orderStore.staticParamOrder.payment_status =
                                                    "FAILED";
                                            }
                                            const params: any = {};
                                            if (
                                                orderStore.staticParamOrder
                                                    .payment_status !== ""
                                            ) {
                                                params.payment_status =
                                                    orderStore.staticParamOrder.payment_status;
                                            }
                                            navigate({
                                                search: createSearchParams(
                                                    params
                                                ).toString(),
                                            });
                                            orderStore.fetctAllListOder();
                                        }}
                                        value={valueTT}
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>Loại đơn</label>
                                    <Radio.Group
                                        options={plainOptionsOderType}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            orderStore.staticParamOrder.order_type =
                                                value;
                                            const params: any = {};
                                            if (
                                                orderStore.staticParamOrder
                                                    .order_type !== ""
                                            ) {
                                                params.order_type =
                                                    orderStore.staticParamOrder.order_type;
                                            }
                                            navigate({
                                                search: createSearchParams(
                                                    params
                                                ).toString(),
                                            });
                                            orderStore.fetctAllListOder();
                                        }}
                                        value={
                                            orderStore.staticParamOrder
                                                .order_type
                                        }
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="filterOrder">
                                <div className="filterOder-method">
                                    <label>TT đơn</label>
                                    <Radio.Group
                                        options={plainOptionsInfoOderType}
                                        onChange={({
                                            target: { value },
                                        }: RadioChangeEvent) => {
                                            setValueTTOrder(value);
                                            const selectedIndex =
                                                plainOptionsInfoOderType.findIndex(
                                                    (option) => option === value
                                                );
                                            console.log(
                                                "check index",
                                                selectedIndex
                                            );
                                            if (selectedIndex + 1 === 1) {
                                                orderStore.staticParamOrder.order_status =
                                                    "SUCCESS";
                                            } else if (
                                                selectedIndex + 1 ===
                                                2
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "CANCEL";
                                            } else if (
                                                selectedIndex + 1 ===
                                                3
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "PENDING";
                                            } else if (
                                                selectedIndex + 1 ===
                                                4
                                            ) {
                                                orderStore.staticParamOrder.order_status =
                                                    "REFUND";
                                            }
                                            const params: any = {};
                                            if (
                                                orderStore.staticParamOrder
                                                    .order_status !== ""
                                            ) {
                                                params.payment_status =
                                                    orderStore.staticParamOrder.order_status;
                                            }
                                            navigate({
                                                search: createSearchParams(
                                                    params
                                                ).toString(),
                                            });
                                            orderStore.fetctAllListOder();
                                        }}
                                        className="d-flex flex-column"
                                        style={{
                                            gap: "8px",
                                            padding: "8px 0",
                                        }}
                                        value={valueTTOrder}
                                    />
                                </div>
                            </div>
                            <button
                                className="btnClearFilter"
                                onClick={handleClearFilter}
                            >
                                Xóa lọc <span>X</span>
                            </button>
                        </div>
                    </div>
                    <div className="content d-flex flex-column">
                        <div className="contentSearch">
                            <div className="content-page-header-left">
                                <form
                                    className="content-page-header-search d-flex align-items-center"
                                    onSubmit={handleSearchOrder}
                                >
                                    <input
                                        type="text"
                                        name="keyword"
                                        id="keyword"
                                        placeholder="Nhập tên sản phẩm"
                                        onChange={(e) =>
                                            (orderStore.staticParamOrder.search =
                                                e.target.value)
                                        }
                                    />
                                    <button type="submit">
                                        <img
                                            src="/assets/icon/search.svg"
                                            alt="search"
                                        />
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="contentListOrder">
                            <div className="table-responsive">
                                <table
                                    className="table table-striped"
                                    id="table-2"
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                style={{
                                                    width: "20%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Đơn hàng
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Ngày
                                                <span
                                                    style={{
                                                        paddingLeft: "4px",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "14px",
                                                        }}
                                                        src="/assets/icon/sorting.svg"
                                                        alt=""
                                                    />
                                                </span>
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Loại thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                TT thanh toán
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Loại đơn
                                            </th>
                                            <th
                                                style={{
                                                    width: "10%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                TT đơn
                                            </th>
                                            <th
                                                style={{
                                                    width: "15%",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Thao tác
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderStore.isLoading ? (
                                            <tr>
                                                <td colSpan={8}>
                                                    <Loading />
                                                </td>
                                            </tr>
                                        ) : orderStore.dataListOrder.length ===
                                          0 ? (
                                            <tr>
                                                <td colSpan={8}>
                                                    <NoContent />
                                                </td>
                                            </tr>
                                        ) : (
                                            orderStore.dataListOrder.map(
                                                (item, index) => {
                                                    return (
                                                        <>
                                                            <tr key={index}>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <p className="text">
                                                                        <span
                                                                            style={{
                                                                                paddingLeft:
                                                                                    "0px",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            <Link to="">
                                                                                {
                                                                                    item.transaction_id
                                                                                }
                                                                            </Link>
                                                                        </span>
                                                                    </p>
                                                                    <p className="text">
                                                                        <span
                                                                            style={{
                                                                                color: "#6777EF",
                                                                                paddingLeft:
                                                                                    "0px",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {
                                                                                item
                                                                                    .customer
                                                                                    .name
                                                                            }
                                                                        </span>
                                                                        -
                                                                        <span
                                                                            style={{
                                                                                color: "#6777EF",
                                                                                fontSize:
                                                                                    "14px",
                                                                                textAlign:
                                                                                    "left",
                                                                            }}
                                                                        >
                                                                            {item
                                                                                .customer
                                                                                ?.phone ||
                                                                                "Chưa có số điện thoại"}
                                                                        </span>
                                                                    </p>
                                                                    <p className="text">
                                                                        <strong>
                                                                            Tổng
                                                                            đơn:
                                                                        </strong>
                                                                        <span
                                                                            className="price"
                                                                            style={{
                                                                                fontSize:
                                                                                    "14px",
                                                                            }}
                                                                        >
                                                                            {new Intl.NumberFormat(
                                                                                "vi-VN"
                                                                            ).format(
                                                                                item.total
                                                                            )}{" "}
                                                                            VND
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className="price"
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                        }}
                                                                    >
                                                                        {new Intl.NumberFormat(
                                                                            "vi-VN"
                                                                        ).format(
                                                                            item.total
                                                                        )}{" "}
                                                                        VND
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleDateString(
                                                                        "en-GB"
                                                                    )}{" "}
                                                                    {new Date(
                                                                        item.created_at
                                                                    ).toLocaleTimeString(
                                                                        "en-GB"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <span className="ordermethod">
                                                                        {item.order_method ===
                                                                        "COD"
                                                                            ? "Thanh toán khi nhận hàng"
                                                                            : "Chuyển khoản"}
                                                                    </span>
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.payment_status ===
                                                                    0 ? (
                                                                        <span className="infoPaymentmethod-refund">
                                                                            Hoàn
                                                                            trả
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      1 ? (
                                                                        <span className="infoPaymentmethod-cancel">
                                                                            Hủy
                                                                            bỏ
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      2 ? (
                                                                        <span className="infoPaymentmethod">
                                                                            Đang
                                                                            xử
                                                                            lý
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      3 ? (
                                                                        <span className="infoPaymentmethod-success">
                                                                            Hoàn
                                                                            thành
                                                                        </span>
                                                                    ) : item.payment_status ===
                                                                      4 ? (
                                                                        <span className="infoPaymentmethod-failed">
                                                                            Thất
                                                                            bại
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.source_type ===
                                                                    "website" ? (
                                                                        <span className="orderType orderType-web">
                                                                            Website
                                                                        </span>
                                                                    ) : (
                                                                        <span className="orderType orderType-pos">
                                                                            Pos
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {item.status_id ===
                                                                    1 ? (
                                                                        <span className="infoOrdertype-success">
                                                                            Hoàn
                                                                            thành
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      2 ? (
                                                                        <span className="infoOrdertype-cancel">
                                                                            Hủy
                                                                            bỏ
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      3 ? (
                                                                        <span className="infoOrdertype-pending">
                                                                            Đang
                                                                            xử
                                                                            lý
                                                                        </span>
                                                                    ) : item.status_id ===
                                                                      4 ? (
                                                                        <span className="infoOrdertype-refund">
                                                                            Hoàn
                                                                            trả
                                                                        </span>
                                                                    ) : (
                                                                        "Chưa có dữ liệu"
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        textAlign:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="seeDetail"
                                                                        onClick={() =>
                                                                            handleSeeDetail(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        Xem ngay
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                }
                                            )
                                        )}
                                    </tbody>
                                </table>
                                {orderStore.isLoading
                                    ? ""
                                    : Math.ceil(
                                          orderStore.metadata.total_page
                                      ) > 1 && (
                                          <ReactPaginate
                                              previousLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-left.svg"
                                                      alt="right"
                                                  />
                                              }
                                              nextLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-right.svg"
                                                      alt="right"
                                                  />
                                              }
                                              breakLabel={"..."}
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={3}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              pageCount={Math.ceil(
                                                  orderStore.metadata.total_page
                                              )}
                                              onPageChange={handleChangePage}
                                              forcePage={
                                                  orderStore.staticParamOrder
                                                      .page
                                              }
                                          />
                                      )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Order);
