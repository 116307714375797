import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Routers from "../../../../routing/routers";
const Navbar = () => {
    return (
        <ul className="navbar-list">
            <li className="">
                <Link to="/" title="Tổng quan">
                    <img src="/assets/icon/icon_goods.svg" alt="Tổng quan" />
                    <span>Tổng quan</span>
                </Link>
            </li>
            <li className="navbar-list-item">
                <Link to="/hang-hoa/san-pham" title="Hàng hóa">
                    <img src="/assets/icon/icon_goods.svg" alt="Tổng quan" />
                    <span>Hàng hóa</span>
                </Link>
                <ul className="dropdown-list">
                    <li className="dropdown-list-item">
                        <Link to="/hang-hoa/san-pham" title="Sản phẩm">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Tổng quan"
                            />
                            <span>Sản phẩm</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <Link to="/hang-hoa/ton-kho" title="Sản phẩm">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Tổng quan"
                            />
                            <span>Tồn kho</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <Link
                            to="/hang-hoa/nhom-san-pham"
                            title="Nhóm sản phẩm"
                        >
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Nhóm sản phẩm"
                            />
                            <span>Nhóm sản phẩm</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <Link to="/hang-hoa/danh-muc" title="Danh mục">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Danh mục"
                            />
                            <span>Danh mục</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <Link to="/hang-hoa/thuong-hieu" title="Thương hiệu">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Tổng quan"
                            />
                            <span>Thương hiệu</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <Link to="/hang-hoa/thuoc-tinh" title="Thuộc tính">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="thuoc-tinh"
                            />
                            <span>Thuộc tính</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <Link to="/hang-hoa/ma-giam-gia" title="Danh mục">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Danh mục"
                            />
                            <span>Mã giảm giá</span>
                        </Link>
                    </li>
                    <li className="dropdown-list-item">
                        <a href="/hang-hoa/the-san-pham" title="Thẻ sản phẩm">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Tổng quan"
                            />
                            <span>Thẻ sản phẩm</span>
                        </a>
                    </li>
                    <li className="dropdown-list-item">
                        <a href="/hang-hoa/nhan" title="Nhãn">
                            <img
                                src="/assets/icon/icon_goods.svg"
                                alt="Tổng quan"
                            />
                            <span>Nhãn</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li className="navbar-list-item">
                <a href="/don-hang" title="Đối tác">
                    <img src="/assets/icon/icon_partner.svg" alt="Đối tác" />
                    <span>Đơn hàng</span>
                </a>
            </li>
            <li className="navbar-list-item">
                <Link to="/may-pos" title="Tổng quan">
                    <img src="/assets/icon/icon_laptop.svg" alt="Máy POS" />
                    <span>Máy POS</span>
                </Link>
            </li>
        </ul>
    );
};
export default observer(Navbar);
