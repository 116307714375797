import { useEffect } from "react";
import "./styles/label.css";
import { labelstore } from "./LabelStore";
import Loading from "../../../shared/components/loading/Loading";
import { Dropdown } from "react-bootstrap";
import NoContent from "../../../shared/components/NoContent";
import { observer } from "mobx-react";
import CreateLabel from "./components/CreateLabel";
import { showDelete } from "../../../shared/components/showDelete";
import { createSearchParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PutLabel from "./components/PutLabel";
import { log } from "node:console";
const Label = () => {
    const navigate = useNavigate();
    useEffect(() => {
        labelstore.fetchAllLabel();
    }, []);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        const params: any = {};
        if (labelstore.staticParamsLabel.search !== "") {
            params.search = labelstore.staticParamsLabel.search;
            console.log("params", params);
        }
        navigate({
            search: createSearchParams(params).toString(),
        });
        labelstore.fetchAllLabel();
    };

    const handleChangePage = (e: any) => {
        const params: any = {};
        if (labelstore.staticParamsLabel.page !== 0) {
            params.page = labelstore.staticParamsLabel.page;
        }
        navigate({
            search: createSearchParams(params).toString(),
        });

        labelstore.staticParamsLabel.page = e.selected;
        labelstore.fetchAllLabel();
    };

    const handlePut = (item: any) => {
        labelstore.initialValues = {
            id: item.id,
            name: item.name,
            status: item.status.toString(),
            created_at: item.created_at,
            descriptions: item.description,
            image: item.image,
        };
    };

    return (
        <>
            <div className="container">
                <div className="mainContentLabel">
                    <CreateLabel />
                    <PutLabel />
                    <div className="filter">
                        <div className="filter-left">
                            <h1>Nhãn</h1>
                            <form
                                className="filter-search"
                                onSubmit={handleSearch}
                            >
                                <input
                                    type="text"
                                    name="keyword"
                                    id="keyword"
                                    placeholder="Vui lòng nhập"
                                    onChange={(e) =>
                                        (labelstore.staticParamsLabel.search =
                                            e.target.value)
                                    }
                                />
                                <button type="submit">
                                    <img
                                        src="/assets/icon/search.svg"
                                        alt="search"
                                    />
                                </button>
                            </form>
                        </div>

                        <button
                            className="btnCreate"
                            onClick={() => {
                                labelstore.showModal();
                            }}
                        >
                            <span>
                                Thêm mới
                                <img
                                    src="/assets/icon/plus.svg"
                                    alt="sorting"
                                />
                            </span>
                        </button>
                    </div>

                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped" id="table-2">
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                width: "15%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Tên
                                        </th>
                                        <th
                                            style={{
                                                width: "23.333%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Mô tả
                                        </th>
                                        <th
                                            style={{
                                                width: "23.333%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Ngày tạo
                                        </th>
                                        <th
                                            style={{
                                                width: "23.333%",
                                                textAlign: "left",
                                            }}
                                        >
                                            Trạng thái
                                        </th>
                                        <th
                                            style={{
                                                width: "15%",
                                                textAlign: "right",
                                            }}
                                        >
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {labelstore.isLoading === true ? (
                                        <tr>
                                            <td
                                                colSpan={5}
                                                style={{ textAlign: "center" }}
                                            >
                                                <Loading />
                                            </td>
                                        </tr>
                                    ) : labelstore.dataListLabel.length ===
                                      0 ? (
                                        <tr>
                                            <td
                                                colSpan={5}
                                                style={{ textAlign: "center" }}
                                            >
                                                <NoContent />
                                            </td>
                                        </tr>
                                    ) : (
                                        labelstore.dataListLabel.map(
                                            (item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            <span>
                                                                {item.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {item.description &&
                                                                item.description.trim() !==
                                                                    ""
                                                                    ? item.description
                                                                    : "Chưa có mô tả"}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {new Date(
                                                                item.created_at
                                                            ).toLocaleDateString(
                                                                "en-GB"
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.status ===
                                                            1 ? (
                                                                <span className="status status-active">
                                                                    Hoạt động
                                                                </span>
                                                            ) : (
                                                                <span className="status">
                                                                    Vô hiệu hóa
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <Dropdown className="action-table">
                                                                <Dropdown.Toggle
                                                                    variant="success"
                                                                    id="dropdown-basic1"
                                                                >
                                                                    Thao tác
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu
                                                                    onClick={() =>
                                                                        handlePut(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <Dropdown.Item
                                                                        href="javascript:void
                                                                    (0)"
                                                                        onClick={() =>
                                                                            labelstore.showModalPut()
                                                                        }
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Sửa
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            labelstore.staticDataLabel.id =
                                                                                item.id;
                                                                            showDelete.showDeleteConfirm(
                                                                                "Xác nhận xóa",
                                                                                "Bạn có chắc muốn xóa mục này",
                                                                                () => {
                                                                                    labelstore.fetchDelete();
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                        Xóa
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    )}
                                </tbody>
                            </table>

                            {labelstore.isLoading
                                ? ""
                                : Math.ceil(labelstore.metadata.total_page) >
                                      1 && (
                                      <ReactPaginate
                                          previousLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-left.svg"
                                                  alt="right"
                                              />
                                          }
                                          nextLabel={
                                              <img
                                                  src="/assets/icon/fi-rr-angle-small-right.svg"
                                                  alt="right"
                                              />
                                          }
                                          breakLabel={"..."}
                                          marginPagesDisplayed={2}
                                          pageRangeDisplayed={3}
                                          containerClassName={"pagination"}
                                          activeClassName={"active"}
                                          pageCount={Math.ceil(
                                              labelstore.metadata.total_page
                                          )}
                                          onPageChange={handleChangePage}
                                          forcePage={
                                              labelstore.staticParamsLabel.page
                                          }
                                      />
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(Label);
