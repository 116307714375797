import { Select } from "antd";
import { observer } from "mobx-react";
import { groupProductStore } from "../../GroupProductStore";

const SelectSortConditionProduct = (handleChange: any)=>{
    return(<>
        <Select
        className="select-sort"
        defaultValue="Tuỳ chọn"
        onChange={(e)=>{groupProductStore.handleSortProducts(e)}}
        options={[
            { value: 'A-Z', label: 'Tên sản phẩm A-Z' },
            { value: 'Z-A', label: 'Tên sản phẩm Z-A' },
            { value: 'PriceDecrease', label: 'Giá thấp đến cao' },
            { value: 'PriceIncrease', label: 'Giá cao đến thấp' },
            { value: 'Newest', label: 'Mới nhất' },
            { value: 'Oldest', label: 'Cũ nhất' },
        ]}
        />
    </>)
}
export default observer(SelectSortConditionProduct);