import { observer } from 'mobx-react';
import React from 'react';

const NoContent = () => {
    return (
        <div className='no-content__container'>
            <img src="/assets/images/Search-none.svg" alt="none" />
            <div>Không tìm thấy nội dung phù hợp</div>
        </div>
    );
}

export default observer(NoContent);