import React, { useEffect } from "react";
import {observer} from "mobx-react";
import './group_product.css'
import {Dropdown} from "react-bootstrap";
import { groupProductStore } from "./GroupProductStore";
import { parseISO, format } from "date-fns";
import GroupProductModal from "./form/GroupProductModal";
import { createSearchParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { showDelete } from "../../../shared/components/showDelete";
import ProductChoosingModal from "./form/ProductChoosingModal";
import Loading from "../../../shared/components/loading/Loading";
import NoContent from "../../../shared/components/NoContent";
const GroupProduct = () => {
    function handleSelect(value: boolean, name: string) { // handle select checkbox for each item in the table
        if (value) {
            groupProductStore.selectedTargets.push(name)
        } else {
            groupProductStore.selectedTargets = groupProductStore.selectedTargets.filter(item => item !== name)
        }
    }
    var allSelectedTargetInTable: boolean = (groupProductStore.dataListGroupProduct === undefined ? false : (groupProductStore.selectedTargets.length === groupProductStore.dataListGroupProduct.length));
    function selectAllTargetInTable(value: boolean){ //handle select The Header Checkbox in table
        if (value) {
            groupProductStore.selectedTargets = groupProductStore.dataListGroupProduct.map((item: any) => item.id);
        } else {
            groupProductStore.selectedTargets = []
        }
    }
    const navigate = useNavigate();
    
    const handlePageClick = (data: { selected: number }) => {//Handle page change when paginating
        const selectedPage = data.selected;
        const params: any = {};
        if (groupProductStore.staticParamGroupProduct.search !== '') {
            params.keyword = `search=${groupProductStore.staticParamGroupProduct.search}`
        };
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString()
        });
        groupProductStore.staticParamGroupProduct.page_number = selectedPage;
        groupProductStore.getDataListViaParam();
    };

    function onSubmitSearch(){
        const searchKeyword = groupProductStore.staticParamGroupProduct.search;
        const params: any = {}
        if(searchKeyword !== ''){
            params.keyword = `${searchKeyword}`;
        }
        navigate({
            search: createSearchParams(params).toString()
        })
        groupProductStore.getDataListViaParam();
    }
    function onHandleKeyDown(event: any){
        if(event.key === 'Enter'){
            onSubmitSearch();
        }
    }
    useEffect(()=>{
        groupProductStore.getDataListAll();
    },[])
    return (
        <div className="container">
            <div className="mainContentGroupProduct">
                <div className="filter">
                    <div className="filter-left">
                        <h1>Nhóm sản phẩm</h1>
                        <div className="filter-search">
                            <input type="text" placeholder="Nhập tên nhóm sản phẩm" value={groupProductStore.staticParamGroupProduct.search} onChange={ (e)=>{groupProductStore.staticParamGroupProduct.search = e.target.value} } onKeyDown={(event)=>{onHandleKeyDown(event)}}/>
                            <button onClick={()=>{onSubmitSearch()}}>
                                <img
                                    src="/assets/icon/search.svg"
                                    alt="search"
                                />
                            </button>
                        </div>
                    </div>
                    <button className="btn-add-modal" onClick={()=>{groupProductStore.isCreating = true; groupProductStore.handleResetModal() ;groupProductStore.openModal = true; groupProductStore.modalName = "Tạo nhóm sản phẩm mới"}}>
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                        </span>
                    </button>
                </div>
                <div className={`content-table`}>
                    <div className="table-responsive">
                        <table className="table table-striped" id="table-2">
                            <thead>
                                <tr>
                                    <th style={{width: '5%', textAlign: 'center'}}>
                                        <input type="checkbox" checked={allSelectedTargetInTable} onChange={(e) => selectAllTargetInTable(e.target.checked)}/>
                                    </th>
                                    <th style={{width: '28%', textAlign: 'left'}}>Tên</th>
                                    <th style={{width: '28%', textAlign: 'left'}}>Url</th>
                                    <th style={{width: '27%', textAlign: 'left'}}>Ngày tạo</th>
                                    <th style={{width: '12%', textAlign: 'left'}}>Thao tác</th>
                                </tr>
                            </thead>
                            {groupProductStore.isLoadingTable === true? (<tbody><tr><td colSpan={5}><Loading/></td></tr></tbody>):
                            (<>{groupProductStore.dataListGroupProduct.length === 0 ? (<tbody><tr><td colSpan={5}><NoContent/></td></tr></tbody>):
                            (
                                <>
                                <tbody>
                                {groupProductStore.dataListGroupProduct.map((item: any, index: number)=>(
                                <tr key={index}>
                                    <td style={{textAlign: 'center'}}>
                                        <div className={`checkboxTable`}>
                                            <input type="checkbox" name="ids[]" value={item.id} checked={groupProductStore.selectedTargets.includes(item.id)} onChange={(e:any) => handleSelect(e.target.checked, item.id)}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="info">
                                            <div className="title-code">
                                                <div className="title-code-content">
                                                    <p className="code">{item.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <p>{item.slug}</p>
                                    </td>
                                    <td>
                                        {format(parseISO(item.created_at), 'dd-MM-yyy')}
                                    </td>
                                    <td>
                                        <Dropdown align={"start"} className="action-table"> 
                                            <Dropdown.Toggle> 
                                                Thao tác
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="javascript:void(0)" onClick={()=>{
                                                    groupProductStore.openModal = true;
                                                    groupProductStore.staticDataGroupProduct.id = item.id;
                                                    groupProductStore.staticDataGroupProduct.name = item.name;
                                                    groupProductStore.staticDataGroupProduct.description = item.description;
                                                    groupProductStore.staticDataGroupProduct.seo_description = item.seo_description;
                                                    groupProductStore.staticDataGroupProduct.seo_title = item.seo_title;
                                                    groupProductStore.staticDataGroupProduct.type = item.type;
                                                    groupProductStore.staticDataGroupProduct.thumbnail = item.thumbnail;
                                                    groupProductStore.getSpecificGroupProduct();
                                                    groupProductStore.isCreating = false;
                                                    groupProductStore.modalName = "Chỉnh sửa Nhóm sản phẩm";
                                                    }}>
                                                    <i className="fa fa-edit"></i>
                                                    Sửa
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={()=>{showDelete.showDeleteConfirm('Xác nhận xoá thuộc tính', `Bạn chắc chắn muốn xoá Nhóm sản phẩm: "${item.name}" không?`, ()=>{groupProductStore.deleteGroupProduct()}); 
                                                    groupProductStore.staticDataGroupProduct.id = item.id;
                                                    //groupProductStore.getSpecificAttribute()
                                                    }}>
                                                    <i className="fa fa-trash"></i>
                                                    Xóa
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                                </>
                            )}</>)}
                        </table>
                        {groupProductStore.isLoadingTable ? '' : (Math.ceil(groupProductStore.metadata.total_page) > 1 && (
                        <div className="pagination__container">
                            <ReactPaginate
                                previousLabel={<img src="/assets/icon/fi-rr-angle-small-left.svg" alt="right"/>}
                                nextLabel={<img src="/assets/icon/fi-rr-angle-small-right.svg" alt="right"/>}
                                breakLabel={"..."}
                                pageCount={Math.ceil(groupProductStore.metadata.total_page)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                activeClassName={"active"}
                                forcePage={groupProductStore.staticParamGroupProduct.page_number}
                            />
                            <div className="pagination__info">
                                Hiển thị  {groupProductStore.metadata.page != groupProductStore.metadata.total_page ? groupProductStore.metadata.size: (groupProductStore.metadata.total - (groupProductStore.metadata.page-1)*(groupProductStore.metadata.size))}/Tổng số {groupProductStore.metadata.total} thuộc tính
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            <GroupProductModal/>
            <ProductChoosingModal/>
        </div>
    );
};
export default observer(GroupProduct);
