import { HttpStatusCode } from "axios";
import { labelservice } from "./LabelService";
import { toastUtils } from "../../../common/utils/Toastutils";
import { makeAutoObservable } from "mobx";
import { Form } from "antd";

interface StaticParamsLabel {
    offset: number | null;
    order_by: string | null;
    page: number;
    per_page: number;
    search: string | null;
}

class LabelStore {
    isLoading = true;
    isModalOpen = false;
    isModalCustom = false;
    isModalDelete = false;

    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }

    initializeParams() {
        const query = new URLSearchParams(window.location.search);
        this.staticParamsLabel.search = query.get("search")
            ? String(query.get("search"))
            : "";
        this.staticParamsLabel.page = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
    }

    staticParamsLabel: StaticParamsLabel = {
        offset: 1,
        order_by: "id",
        page: 0,
        per_page: 10,
        search: "",
    };

    metadata: any = {
        total: "",
        page: 1,
        total_page: "",
        size: "",
    };

    staticDataLabel: {
        id: number;
        name: string;
        slug: string;
        image: string | null;
        description: string;
        status: string;
        deleted_at: null;
        created_at: string;
        updated_at: string;
    } = {
        id: 0,
        name: "",
        slug: "",
        image: null,
        description: "",
        status: "1",
        deleted_at: null,
        created_at: "",
        updated_at: "",
    };

    initialValues: {
        id: number;
        name: string;
        created_at: string;
        status: string;
        descriptions: string;
        image: string;
    } = {
        id: 1,
        name: "",
        created_at: "",
        status: "1",
        descriptions: "",
        image: "",
    };

    dataListLabel: any[] = [];

    setIsModalOpen = (isOpen: boolean) => {
        this.isModalOpen = isOpen;
    };
    setIsModalCusTom = (isOpen: boolean) => {
        this.isModalCustom = isOpen;
    };
    setIsModalDeleteOpen = (isOpen: boolean) => {
        this.isModalDelete = isOpen;
    };
    showModalDelete = () => {
        this.setIsModalDeleteOpen(true);
    };
    showModal = () => {
        this.setIsModalOpen(true);
    };
    showModalPut = () => {
        this.setIsModalCusTom(true);
    };
    handeOk = () => {
        this.setIsModalOpen(true);
        this.setIsModalCusTom(true);
    };
    handleCancel = () => {
        this.setIsModalCusTom(false);
        this.setIsModalOpen(false);
    };
    handleClose = () => {
        this.setIsModalOpen(false);
        this.staticDataLabel.name = "";
        this.staticDataLabel.description = "";
        this.staticDataLabel.status = "1";
        this.staticDataLabel.image = "";
    };
    changeFileImage = (pathFile: string) => {
        this.staticDataLabel.image = pathFile;
        this.initialValues.image = pathFile;
    };
    handleCloseIcon = () => {
        this.staticDataLabel.image = "";
        this.initialValues.image = "";
    };

    async fetchAllLabel() {
        this.isLoading = true;
        let { offset, order_by, page, per_page, search } =
            this.staticParamsLabel;
        const paramsLabel = {
            offset: offset,
            order_by: order_by,
            page: page + 1,
            per_page: per_page,
            search: search,
        };
        const result = await labelservice.fetchAllLabel(paramsLabel);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.dataListLabel = result.body.data;
            this.metadata = result.body.metadata;
        }
    }

    async fetchCreate() {
        this.isLoading = true;
        let { name, description, status, image } = this.staticDataLabel;
        const params = {
            name: name,
            status: status,
            description: description,
            image: image,
        };

        const result = await labelservice.fetchCreateLabel(params);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Thêm thành công", "");
            this.handleClose();
            this.fetchAllLabel();
            console.log("update ");
        } else {
            toastUtils.error("Lỗi thêm mới", "");
        }
    }

    async fetchUpdate() {
        this.isLoading = true;
        let { id, name, status, descriptions, image } = this.initialValues;
        const params = {
            id: id,
            name: name,
            status: status,
            description: descriptions,
            image: image,
        };
        const result = await labelservice.fetchUpdateLabel(params);
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            toastUtils.success("Sửa thành công", "");
            this.handleClose();
            this.fetchAllLabel();
        } else {
            toastUtils.error("Lỗi thêm mới", "");
        }
    }

    async fetchDelete() {
        let { id } = this.staticDataLabel;
        this.isLoading = true;
        const result = await labelservice.fetchDeleteLable(id);
        if (result.status === HttpStatusCode.Ok) {
            this.isLoading = false;
            toastUtils.success("Xóa thành công", "");
            this.fetchAllLabel();
        }
    }
}

export const labelstore = new LabelStore();
