import { observer } from "mobx-react";
import { Modal, Form, DatePicker, Input, DatePickerProps } from "antd";
import { couponStore } from "./CouponStore";
import UploadFile from "../../../shared/components/form/UploadFile";
import SelectStatus from "../../../shared/components/form/SelectStatus";
import { handleFile } from "../../../common/helpers/UploadFileHelper";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toastUtils } from "../../../common/utils/Toastutils";
import { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
dayjs.extend(customParseFormat);
const CreateCoupon = () => {
    const navigate = useNavigate();
    const dateFormat = "YYYY-MM-DD";
    const [form] = Form.useForm();

    const generateRandomLetters = () => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let result = "";
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * letters.length);
            result += letters[randomIndex];
        }
        couponStore.staticCoupon.code = result.toUpperCase();
        console.log(couponStore.staticCoupon.code);
        navigator.clipboard
            .writeText(couponStore.staticCoupon.code)
            .then(() => {
                toastUtils.success(
                    `Mã giảm giá đã được sao chép ${couponStore.staticCoupon.code}`,
                    ""
                );
            });
    };

    const onFinish = (values: any) => {
        const params: any = {};
        params.page = 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        couponStore.staticParamCoupon.page = 0;
        couponStore.fetchCreateCoupon();
        couponStore.handleCancel();
    };

    const handleChangeDateStart: DatePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        couponStore.staticCoupon.start_from = dateString as string;
    };
    console.log("check date store", couponStore.staticCoupon.start_from);
    const handleChangeDateEnd: DatePickerProps["onChange"] = (
        date,
        dateString
    ) => {
        couponStore.staticCoupon.will_expire = dateString as string;
    };

    useEffect(() => {
        form.setFieldsValue(couponStore.staticCoupon);
    }, [couponStore.staticCoupon, form]);

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Thêm mã giảm giá"
                open={couponStore.isModalOpenCreate}
                onOk={couponStore.handleOK}
                onCancel={couponStore.handleCancel}
                afterClose={couponStore.handeClearFormCreate}
            >
                <UploadFile
                    text={`Hình ảnh`}
                    description={`Hình ảnh mã giảm giá`}
                    file={couponStore.staticCoupon.preview}
                    onIconRemove={couponStore.handleCloseIcon}
                    onFileChange={(e) => {
                        handleFile(e, couponStore.changeFileImage);
                    }}
                />
                <Form
                    form={form}
                    onFinish={onFinish}
                    className="formContainer"
                    initialValues={couponStore.staticCoupon}
                >
                    <div className="item-form item-input">
                        <label className="label-form d-flex justify-content-between">
                            <p
                                style={{
                                    marginBottom: "0px",
                                }}
                            >
                                Mã giảm giá <span>*</span>
                            </p>

                            <p
                                className="btn-createcoupon"
                                style={{
                                    backgroundColor: "#0E72ED",
                                    padding: "4px 10px",
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    cursor: "pointer",
                                    marginBottom: "0px",
                                }}
                                onClick={generateRandomLetters}
                            >
                                Tạo mã
                            </p>
                        </label>

                        <Form.Item
                            name="code"
                            rules={[
                                {
                                    required: true,
                                    message: "Mã giảm giá là bắt buộc",
                                },
                                {
                                    max: 10,
                                    message:
                                        "Mã giảm giá không được vượt quá 10 ký tự",
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    textTransform: "uppercase",
                                }}
                                placeholder="Vui lòng nhập"
                                defaultValue={couponStore.staticCoupon.code}
                                onChange={(e) =>
                                    (couponStore.staticCoupon.code =
                                        e.target.value)
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label
                            className="label-form"
                            title="Giảm giá cố định: giảm giá số tiền nhất định; Phần trăm: giảm theo % trên tổng số tiền"
                        >
                            Loại giảm giá<span>*</span>
                        </label>
                        <SelectStatus
                            onChange={(value: any) => {
                                couponStore.staticCoupon.is_percentage = value;
                            }}
                            selected={() =>
                                couponStore.staticCoupon.is_percentage
                            }
                            textOn="Phần trăm"
                            textOff="Giảm giá cố định"
                        />
                    </div>

                    <div className="item-form">
                        <label
                            className="label-form"
                            title="Nhập số tiền giảm theo giảm giá cố định. Nhập số % theo giảm giá %"
                        >
                            Số tiền giảm/Phần trăm giảm<span>*</span>
                        </label>

                        <Form.Item
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Số tiền giảm/Phần trăm giảm là bắt buộc",
                                },
                                {
                                    max: 10,
                                    message:
                                        "Số tiền giảm/Phần trăm giảm không được vượt quá 10 ký tự",
                                },
                                {
                                    pattern: /^[0-9]+$/,
                                    message:
                                        "Số tiền giảm/Phần trăm giảm chỉ được chứa số",
                                },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder="Vui lòng nhập"
                                value={couponStore.staticCoupon.value}
                                onChange={(e) => {
                                    couponStore.staticCoupon.value = parseFloat(
                                        e.target.value
                                    );
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label
                            className="label-form"
                            title="Giảm giá cố định: giảm giá số tiền nhất định; Phần trăm: giảm theo % trên tổng số tiền"
                        >
                            Có điều kiện<span>*</span>
                        </label>
                        <SelectStatus
                            onChange={(value: any) => {
                                couponStore.staticCoupon.is_conditional = value;
                                console.log("loaigiamgia", value);
                            }}
                            selected={() =>
                                couponStore.staticCoupon.is_conditional
                            }
                            textOn="Có"
                            textOff="Không"
                        />
                    </div>

                    {couponStore.staticCoupon.is_conditional === "1" ? (
                        <div className="item-form">
                            <label className="label-form">
                                Số tiền tối thiểu<span>?</span>
                            </label>

                            <Form.Item
                                name="min_amout"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Số tiền tối thiểu là bắt buộc",
                                    },
                                    {
                                        max: 10,
                                        message:
                                            "Số tiền tối thiểu không được vượt quá 10 ký tự",
                                    },
                                    {
                                        pattern: /^[0-9]+$/,
                                        message:
                                            "Số tiền tối thiểu chỉ được chứa số",
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    placeholder="Số tiền tối thiểu"
                                    value={couponStore.staticCoupon.min_amount}
                                    onChange={(e) =>
                                        (couponStore.staticCoupon.min_amount =
                                            parseFloat(e.target.value))
                                    }
                                />
                            </Form.Item>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="item-form date-form date-formStart">
                        <label
                            className="label-form"
                            title="Ngày bắt đầu giảm giá"
                        >
                            Bắt đầu từ<span>*</span>
                        </label>
                        <Form.Item
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn ngày bắt đầu!",
                                },
                            ]}
                        >
                            <DatePicker
                                onChange={handleChangeDateStart}
                                style={{
                                    border: "none",
                                    width: "100%",
                                    paddingLeft: "0px",
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form date-form date-formEnd">
                        <label
                            className="label-form"
                            title="Ngày bắt đầu giảm giá"
                        >
                            Sẽ hết hạn<span>*</span>
                        </label>
                        <Form.Item
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn ngày kết thúc!",
                                },
                            ]}
                        >
                            <DatePicker
                                onChange={handleChangeDateEnd}
                                minDate={dayjs(
                                    `${couponStore.staticCoupon.start_from}`,
                                    dateFormat
                                )}
                                style={{
                                    border: "none",
                                    width: "100%",
                                    paddingLeft: "0px",
                                }}
                            />
                        </Form.Item>
                    </div>

                    <div className="item-form item-selected">
                        <label
                            className="label-form"
                            title="Giảm giá cố định: giảm giá số tiền nhất định; Phần trăm: giảm theo % trên tổng số tiền"
                        >
                            Trạng thái<span>*</span>
                        </label>
                        <SelectStatus
                            onChange={(value: any) => {
                                couponStore.staticCoupon.status = value;
                            }}
                            selected={() => couponStore.staticCoupon.status}
                            textOn="Hoạt động"
                            textOff="Vô hiệu hóa"
                        />
                    </div>

                    <div className="btnCreateModal">
                        <button
                            className="cancelModal"
                            type="reset"
                            onClick={() => couponStore.handleCancel()}
                        >
                            Đóng
                        </button>
                        <button className="submitForm" type="submit">
                            Lưu
                        </button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default observer(CreateCoupon);
