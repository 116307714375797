import React from 'react';
import axios, {HttpStatusCode} from 'axios';
import {postRequest} from "./RequestHelper";
import {toastUtils} from "../utils/Toastutils";

export async function handleFile(e: React.ChangeEvent<HTMLInputElement>, changeFile: (pathFile: string) => void) {
    const filePreview = e.target.files?.[0];
    if (filePreview) {
        try {
            const params = {
                'file_name': filePreview.name,
            };
            const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
            if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                const preSignedUrl = resultCategoryProductAll.body.pre_signed;
                await axios.put(preSignedUrl, filePreview, {
                    headers: {
                        'Content-Type': filePreview.type,
                        'Content-Disposition': `attachment; filename=${filePreview.name}`
                    }
                });

                const previewPath = resultCategoryProductAll.body.full_path;
                changeFile(previewPath);
            } else {
                toastUtils.error(resultCategoryProductAll.body.message, '');
            }
        } catch (error) {
            console.error('File upload failed', error);
        }
    }
}

export async function handleFileVariant(e: React.ChangeEvent<HTMLInputElement>,index:number, changeFileVariant: (pathFile: string,ordering:number) => void) {

    const filePreview = e.target.files?.[0];
    if (filePreview) {
        try {
            const params = {
                'file_name': filePreview.name,
            };
            const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
            if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                const preSignedUrl = resultCategoryProductAll.body.pre_signed;
                await axios.put(preSignedUrl, filePreview, {
                    headers: {
                        'Content-Type': filePreview.type,
                        'Content-Disposition': `attachment; filename=${filePreview.name}`
                    }
                });

                const previewPath = resultCategoryProductAll.body.full_path;
                changeFileVariant(previewPath,index);
            } else {
                toastUtils.error(resultCategoryProductAll.body.message, '');
            }
        } catch (error) {
            console.error('File upload failed', error);
        }
    }
}
export async function handleFileMultiple(e: React.ChangeEvent<HTMLInputElement>, changeFile: (pathFile: string[]) => void) {
    const filePreview = e.target.files;
    if (filePreview && filePreview.length > 0) {
        const filePreviewArray: string[] = [];
        for (let i = 0; i < filePreview.length; i++) {
            const file = filePreview[i];
            try {
                const params = {
                    'file_name': file.name,
                };
                const resultCategoryProductAll = await postRequest(`api/v1/medias/upload`, params);
                if (resultCategoryProductAll.status === HttpStatusCode.Ok) {
                    const preSignedUrl = resultCategoryProductAll.body.pre_signed;
                    await axios.put(preSignedUrl, file, {
                        headers: {
                            'Content-Type': file.type,
                            'Content-Disposition': `attachment; filename=${file.name}`
                        }
                    });
                    const previewPath = resultCategoryProductAll.body.full_path;
                    filePreviewArray.push(previewPath);
                } else {
                    toastUtils.error(resultCategoryProductAll.body.message, '');
                }
            } catch (error) {
                console.error('File upload failed', error);
            }
        }
        changeFile(filePreviewArray);
    }
}