import {makeAutoObservable} from "mobx";
import StorageService from "../../../common/service/StorageService";
const storageService = new StorageService();

class LogoutStore{
    constructor() {
        makeAutoObservable(this)
    }
    async getBanner(){

    }
    logout(){
        storageService.removeAccessToken();
    }
}
export const logoutStore = new LogoutStore()