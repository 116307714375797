import {getRequest} from "../../../common/helpers/RequestHelper";

class InventoryService {
    apiSubdirectory = 'api/v1/inventory_products' ;
    public fetchAllInventoryProduct(params:any): Promise<any> {
        const queryParameters = [
            params.search ? `search=${params.search}` : '',
            params.store_id ? `store_id=${params.store_id}` : '',
        ].filter(Boolean).join('&');
        return getRequest(`${this.apiSubdirectory}?${queryParameters}`);
    }
}
export const inventoryService = new InventoryService()