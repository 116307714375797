import { observer } from "mobx-react";
import { Modal, Select } from "antd";
import { tagProductStore } from "../TagProductStore";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { createSearchParams, useNavigate } from "react-router-dom";

const CreateTagProduct = () => {
    const navigate = useNavigate();
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .max(20, "Tên quá dài")
            .required("Không dược để trống")
            .matches(
                /^[^<>(){}[\]`\/!@#$%^&*~"'.,:?|\\]+$/,
                "Tên không được chứa các ký tự đặc biệt"
            ),

        is_featured: Yup.number().required("Không được để trống"),
        status: Yup.number().required("Không được để trống"),
    });

    console.log("check static", tagProductStore.staticDataTagProduct);

    return (
        <>
            <Modal
                className="modal-form"
                footer={null}
                title="Thêm thẻ"
                open={tagProductStore.isModalOpen}
                onOk={tagProductStore.handleOK}
                onCancel={tagProductStore.handleCancel}
                afterClose={tagProductStore.handleClose}
            >
                <Formik
                    initialValues={{
                        name: tagProductStore.staticDataTagProduct.name,
                        is_featured:
                            tagProductStore.staticDataTagProduct.is_featured,
                        status: tagProductStore.staticDataTagProduct.status,
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(value: any, { resetForm }: any) => {
                        tagProductStore.staticDataTagProduct.name = value.name;
                        tagProductStore.fetchCreateTagProduct();
                        const params: any = {};
                        params.page = 1;
                        navigate({
                            search: createSearchParams(params).toString(),
                        });
                        tagProductStore.staticParamTagProduct.page = 0;
                        tagProductStore.handleCancel();
                        resetForm();
                        console.log("check sbumit");
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="item-form">
                                <label className="label-form">
                                    Tên thẻ <span>*</span>
                                </label>
                                <Field
                                    id="name"
                                    name="name"
                                    placeholder="Vui lòng nhập"
                                />
                                <ErrorMessage component="span" name="name" />
                            </div>

                            <div className="item-form item-selected">
                                <label className="label-form">Nổi bật</label>
                                <SelectStatus
                                    onChange={(value: any) => {
                                        tagProductStore.staticDataTagProduct.is_featured =
                                            value;
                                    }}
                                    selected={() =>
                                        tagProductStore.staticDataTagProduct
                                            .is_featured
                                    }
                                    textOn="Có"
                                    textOff="Không"
                                />
                            </div>

                            <div className="item-form item-selected">
                                <label className="label-form">Trạng Thái</label>
                                <SelectStatus
                                    onChange={(value: any) => {
                                        tagProductStore.staticDataTagProduct.status =
                                            value;
                                        console.log(value);
                                    }}
                                    selected={() =>
                                        tagProductStore.staticDataTagProduct
                                            .status
                                    }
                                    textOn="Hoạt động"
                                    textOff="Vô hiệu hóa"
                                />
                            </div>

                            <div className="btnCreateModal">
                                <button
                                    className="cancelModal"
                                    type="button"
                                    onClick={tagProductStore.handleCancel}
                                >
                                    Đóng
                                </button>
                                <button className="submitForm" type="submit">
                                    Lưu
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default observer(CreateTagProduct);
