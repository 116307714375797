import "./editorder.css";
import { observer } from "mobx-react";
import { orderStore } from "../OrderStore";
import { Col, Row, Select } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../shared/components/loading/Loading";
import { useEffect } from "react";

const EditOrder = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    orderStore.staticOrder.id = `${id}`;
    const listPaymentMethod = [
        { id: "0", name: "Hoàn trả", label: "Hoàn trả", value: "Hoàn trả" },
        { id: "1", name: "Hủy bỏ", label: "Hủy bỏ", value: "Hủy bỏ" },
        {
            id: "2",
            name: "Đang xử lý",
            label: "Đang xử lý",
            value: "Đang xử lý",
        },
        {
            id: "3",
            name: "Hoàn thành",
            label: "Hoàn thành",
            value: "Hoàn thành",
        },
        { id: "4", name: "Thất bại", label: "Thất bại", value: "Thất bại" },
    ];
    const listOrderMethod = [
        {
            id: "1",
            name: "Hoàn thành",
            label: "Hoàn thành",
            value: "Hoàn thành",
        },
        { id: "2", name: "Hủy bỏ", label: "Hủy bỏ", value: "Hủy bỏ" },
        {
            id: "3",
            name: "Đang xử lý",
            label: "Đang xử lý",
            value: "Đang xử lý",
        },
        { id: "4", name: "Hoàn trả", label: "Hoàn trả", value: "Hoàn trả" },
    ];

    const handleSaveChange = () => {
        orderStore.fetchUpdateOrder();
        navigate("/don-hang");
    };

    useEffect(() => {
        orderStore.fetchDetailOrder();
    }, []);

    return (
        <>
            <div className="container">
                <div className={`mainContentDetailOrder`}>
                    <Row gutter={[30, 0]}>
                        {orderStore.isLoadingDetail ? (
                            <Col>
                                <Loading />
                            </Col>
                        ) : (
                            <>
                                <Col span={16}>
                                    <div className="labelLeft d-flex flex-column">
                                        <div className="labelLeft-body">
                                            <div className="table table-striped">
                                                <thead>
                                                    <tr
                                                        style={{
                                                            backgroundColor:
                                                                "rgba(0, 0, 0, 0.04)",
                                                        }}
                                                    >
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "10%",
                                                            }}
                                                        >
                                                            Ảnh
                                                        </th>
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                                width: "60%",
                                                            }}
                                                        >
                                                            Thông tin sản phẩm
                                                        </th>
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "10%",
                                                            }}
                                                        >
                                                            Số tiền
                                                        </th>
                                                        <th
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "20%",
                                                            }}
                                                        >
                                                            Tổng cộng
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        style={{
                                                            backgroundColor:
                                                                "#fff",
                                                        }}
                                                    >
                                                        <td
                                                            className="d-flex align-items-center justify-content-center"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "10%",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    orderStore
                                                                        .dataListOderById
                                                                        ?.ordermeta
                                                                        ?.length >
                                                                    0
                                                                        ? orderStore
                                                                              .dataListOderById
                                                                              .ordermeta[2]
                                                                              .value
                                                                        : undefined
                                                                }
                                                                alt=""
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "left",
                                                                width: "60%",
                                                            }}
                                                        >
                                                            <div className="title">
                                                                <Link to="">
                                                                    Sản phẩm 2
                                                                </Link>
                                                                <p className="mb-0">
                                                                    <strong>
                                                                        Mã sản
                                                                        phẩm:
                                                                    </strong>{" "}
                                                                    {orderStore
                                                                        .dataListOderById
                                                                        ?.orderitems
                                                                        ?.length >
                                                                    0
                                                                        ? orderStore
                                                                              .dataListOderById
                                                                              .orderitems[0]
                                                                              .product
                                                                              .sku_origin
                                                                        : undefined}
                                                                </p>
                                                                <p className="mb-0">
                                                                    <strong>
                                                                        Biến
                                                                        thể:
                                                                    </strong>
                                                                    red/x
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="d-flex align-items-center justify-content-center"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "10%",
                                                            }}
                                                        >
                                                            {orderStore
                                                                .dataListOderById
                                                                ?.orderitems
                                                                ?.length >
                                                                0 && (
                                                                <>
                                                                    {new Intl.NumberFormat(
                                                                        "vi-VN"
                                                                    ).format(
                                                                        orderStore
                                                                            .dataListOderById
                                                                            .orderitems[0]
                                                                            .amount
                                                                    )}{" "}
                                                                    x{" "}
                                                                    {
                                                                        orderStore
                                                                            .dataListOderById
                                                                            .orderitems[0]
                                                                            .qty
                                                                    }
                                                                </>
                                                            )}
                                                        </td>
                                                        <td
                                                            className="d-flex align-items-center justify-content-center"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                width: "20%",
                                                            }}
                                                        >
                                                            {orderStore
                                                                .dataListOderById
                                                                ?.orderitems
                                                                ?.length >
                                                                0 && (
                                                                <>
                                                                    {new Intl.NumberFormat(
                                                                        "vi-VN"
                                                                    ).format(
                                                                        orderStore
                                                                            .dataListOderById
                                                                            .orderitems[0]
                                                                            .amount
                                                                    )}{" "}
                                                                    VND
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </div>
                                            <ul className="list d-flex flex-column">
                                                <li className="row list-item list-coupon d-flex align-items-end justify-content-end">
                                                    <div className="col-9 text-left">
                                                        Giảm giá
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        -
                                                        {new Intl.NumberFormat(
                                                            "vi-VN"
                                                        ).format(
                                                            orderStore
                                                                .dataListOderById
                                                                ?.discount
                                                        )}{" "}
                                                        VND
                                                    </div>
                                                </li>
                                                <li className="row list-item list-delivery d-flex align-items-end justify-content-end">
                                                    <div className="col-9 text-left">
                                                        Phí ship
                                                    </div>
                                                    <div className="col-3 text-right">
                                                        {
                                                            orderStore
                                                                .dataListOderById
                                                                ?.is_shipped
                                                        }{" "}
                                                        VND
                                                    </div>
                                                </li>
                                                <li
                                                    className="row list-item list-total d-flex align-items-end justify-content-end"
                                                    style={{
                                                        borderBottom: "none",
                                                    }}
                                                >
                                                    <div
                                                        className="col-9 text-left"
                                                        style={{
                                                            color: "#6c657d",
                                                            fontSize: "22px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Tổng cộng
                                                    </div>
                                                    <div
                                                        className="col-3 text-right"
                                                        style={{
                                                            fontSize: "22px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        {new Intl.NumberFormat(
                                                            "vi-VN"
                                                        ).format(
                                                            orderStore
                                                                .dataListOderById
                                                                .total
                                                        )}{" "}
                                                        VND
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="labelLeft-footer d-flex flex-column">
                                            <div className="optionsStatus d-flex">
                                                <div className="paymentStatus d-flex flex-column">
                                                    <label htmlFor="">
                                                        Chọn trạng thái thanh
                                                        toán
                                                    </label>
                                                    <Select
                                                        defaultValue={
                                                            orderStore
                                                                .dataListOderById
                                                                .payment_status ===
                                                            "REFUND"
                                                                ? "Hoàn trả"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "CANCEL"
                                                                ? "Hủy bỏ"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "PENDING"
                                                                ? "Đang xử lý"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "COMPLETE"
                                                                ? "Hoàn thành"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .payment_status ===
                                                                  "FAILED"
                                                                ? "Thất bại"
                                                                : ""
                                                        }
                                                        placeholder="Chọn trạng thái thanh toán"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(
                                                            value: string
                                                        ) => {
                                                            const selectedIndex =
                                                                listPaymentMethod.findIndex(
                                                                    (option) =>
                                                                        option.value ===
                                                                        value
                                                                );
                                                            orderStore.staticOrder.payment_status = `${selectedIndex}`;
                                                        }}
                                                        options={
                                                            listPaymentMethod
                                                        }
                                                    />
                                                </div>
                                                <div className="orderStatus">
                                                    <label htmlFor="">
                                                        Chọn trạng thái đơn hàng
                                                    </label>
                                                    <Select
                                                        placeholder="Chọn trạng thái đơn hàng"
                                                        defaultValue={
                                                            orderStore
                                                                .dataListOderById
                                                                .status_id ===
                                                            "COMPLETED"
                                                                ? "Hoàn thành"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .status_id ===
                                                                  "CANCEL"
                                                                ? "Hủy bỏ"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .status_id ===
                                                                  "PENDING"
                                                                ? "Đang xử lý"
                                                                : orderStore
                                                                      .dataListOderById
                                                                      .status_id ===
                                                                  "REFUND"
                                                                ? "Hoàn trả"
                                                                : ""
                                                        }
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onChange={(
                                                            value: string
                                                        ) => {
                                                            const selectedIndex =
                                                                listOrderMethod.findIndex(
                                                                    (option) =>
                                                                        option.value ===
                                                                        value
                                                                );
                                                            orderStore.staticOrder.order_status = `${
                                                                selectedIndex +
                                                                1
                                                            }`;
                                                        }}
                                                        options={
                                                            listOrderMethod
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="optionsBtn">
                                                <button
                                                    className="btnBack"
                                                    onClick={() =>
                                                        navigate("/don-hang")
                                                    }
                                                >
                                                    Quay lại đơn
                                                </button>
                                                <button
                                                    className="btnPrint"
                                                    onClick={() =>
                                                        navigate(
                                                            `/in-hoa-don/${orderStore.staticOrder.id}`
                                                        )
                                                    }
                                                >
                                                    In hóa đơn
                                                </button>
                                                <button
                                                    className="btnSave"
                                                    onClick={handleSaveChange}
                                                >
                                                    Lưu thay đổi
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className="labelRight d-flex flex-column">
                                        <div className="labelStatus">
                                            <div className="labelStatus-title">
                                                <p className="mb-0">
                                                    Trạng thái
                                                </p>
                                            </div>

                                            <div className="labelStatus-info d-flex flex-column">
                                                <div className="orderStatus d-flex justify-content-between">
                                                    <span>
                                                        Trạng thái thanh toán
                                                    </span>
                                                    {orderStore.dataListOderById
                                                        .payment_status ===
                                                    "PENDING" ? (
                                                        <span className="badge badge-pending">
                                                            Đang xử lý
                                                        </span>
                                                    ) : orderStore
                                                          .dataListOderById
                                                          .payment_status ===
                                                      "COMPLETE" ? (
                                                        <span className="badge badge-success">
                                                            Hoàn thành
                                                        </span>
                                                    ) : orderStore
                                                          .dataListOderById
                                                          .payment_status ===
                                                      "FAILED" ? (
                                                        <span className="badge badge-failed">
                                                            Thất bại
                                                        </span>
                                                    ) : orderStore
                                                          .dataListOderById
                                                          .payment_status ===
                                                      "CANCEL" ? (
                                                        <span className="badge badge-cancel">
                                                            Hủy bỏ
                                                        </span>
                                                    ) : orderStore
                                                          .dataListOderById
                                                          .payment_status ===
                                                      "REFUND" ? (
                                                        <span className="badge badge-refund">
                                                            Hoàn trả
                                                        </span>
                                                    ) : (
                                                        "Chưa có dữ liệu"
                                                    )}
                                                </div>
                                                <div className="paymentStatus d-flex justify-content-between">
                                                    <span>
                                                        Phương thức thanh toán
                                                    </span>
                                                    <span className="badge badge-success">
                                                        {
                                                            orderStore
                                                                .dataListOderById
                                                                .order_method
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="labelInfoUser d-flex flex-column">
                                            <div className="labelInfoUser-title">
                                                <p className="mb-0">
                                                    Thông tin người đặt
                                                </p>
                                            </div>

                                            <div className="labelInfoUser-list">
                                                <p>
                                                    Tên:{" "}
                                                    <b>
                                                        {orderStore
                                                            .dataListOderById
                                                            ?.customer
                                                            ?.billingName ||
                                                            "Chưa có tên khách hàng"}
                                                    </b>
                                                    {" - "}
                                                    <Link to="">
                                                        tuananh141101(#1)
                                                    </Link>
                                                </p>
                                                <p>
                                                    Email:{" "}
                                                    <b>
                                                        {orderStore
                                                            .dataListOderById
                                                            .customer?.email ||
                                                            "Chưa có email"}
                                                    </b>
                                                </p>
                                                <p>
                                                    Số điện thoại:{" "}
                                                    <b>
                                                        {orderStore
                                                            .dataListOderById
                                                            ?.customer
                                                            ?.order_phone ||
                                                            "Chưa có số điện thoại"}
                                                    </b>
                                                </p>
                                                <p>
                                                    Địa chỉ:{" "}
                                                    <b>
                                                        {orderStore
                                                            .dataListOderById
                                                            ?.customer
                                                            ?.address ||
                                                            "Chưa có địa chỉ"}
                                                    </b>
                                                </p>
                                                <p>
                                                    Thời gian đặt hàng:{" "}
                                                    <b>
                                                        {new Date(
                                                            orderStore.dataListOderById.created_at
                                                        ).toLocaleDateString(
                                                            "en-GB"
                                                        )}
                                                        {" - "}
                                                        {new Date(
                                                            orderStore.dataListOderById.created_at
                                                        ).toLocaleTimeString(
                                                            "en-GB"
                                                        )}
                                                    </b>
                                                </p>
                                                <p>
                                                    Lời nhắn:{" "}
                                                    <b>
                                                        {orderStore
                                                            ?.dataListOderById
                                                            ?.customer?.note ||
                                                            "Chưa có ghi chú"}
                                                    </b>
                                                </p>
                                                <p>
                                                    Kiểu giao hàng:{" "}
                                                    <span
                                                        style={{
                                                            color: "#fc544b",
                                                        }}
                                                    >
                                                        {orderStore
                                                            .dataListOderById
                                                            ?.customer
                                                            ?.shippingFee ===
                                                        "HT"
                                                            ? "Hỏa tốc"
                                                            : "Giao hàng thường"}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            </div>
        </>
    );
};

export default observer(EditOrder);
