import { observer } from "mobx-react";
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { dashboardStore } from "../../DashboardStore";

interface NotificationsProps {
    dataListNotifications: any[];
    metadataNotifications: {
        size: number;
        page: number;
        total_page: number;
        total: number;
    };
}

const Notifications: React.FC<NotificationsProps> = ({
    dataListNotifications,
    metadataNotifications,
}) => {
    const replaceWithSpan = (text: string) => {
        const regex = /%(.*?)%/g;
        const html = text.replace(regex, "<span>$1</span>");
        return { __html: html };
    };
    const markAsViewed = (id: number) => {
        const notification = dashboardStore.dataListNotifications.find(
            (notif) => notif.id === id
        );
        if (notification) {
            notification.viewed = true;
        }
    };
    const markAllAsViewed = () => {
        dashboardStore.dataListNotifications.map((notification) => {
            notification.viewed = true;
        });
    };

    return (
        <>
            <div className="mainContentRightNoti">
                <p className="mainContentRightNotiTitle">
                    Thông báo
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            markAllAsViewed();
                            dashboardStore.fetchUpdateStatusAllNotification();
                        }}
                    >
                        <img
                            src="/assets/icon/fi-rr-done-all.svg"
                            alt="fi-rr-done-all"
                        />
                    </button>
                </p>
                <div className="mainContentRightNotiList">
                    {dataListNotifications.map((item: any, index: number) => {
                        if (item.isLoading)
                            return (
                                <a
                                    key={index}
                                    className="itemNoti"
                                    href="javascript:void(0)"
                                >
                                    <Skeleton
                                        circle={true}
                                        height={32}
                                        width={32}
                                    />
                                    <div className="itemNotiText">
                                        <p className="text">
                                            <Skeleton width={226} />
                                        </p>
                                        <p className="time">
                                            <Skeleton width={206} />
                                        </p>
                                    </div>
                                </a>
                            );
                        else
                            return (
                                <a
                                    key={index}
                                    className={
                                        item.viewed === true
                                            ? "itemNoti itemNotiWatched"
                                            : "itemNoti"
                                    }
                                    href={`don-hang/${item.meta_data.order_id}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        if (item.status === 0) {
                                            markAsViewed(item.id);
                                            dashboardStore.fetchUpdateStatusNotification(
                                                item.id
                                            );
                                        }
                                    }}
                                >
                                    <div className="itemNotiIcon">
                                        <img
                                            src="/assets/icon/fi-rr-bell.svg"
                                            alt="fi-rr-bell"
                                        />
                                    </div>
                                    <div className="itemNotiText">
                                        <p
                                            className="text"
                                            dangerouslySetInnerHTML={replaceWithSpan(
                                                item.title
                                            )}
                                        />
                                        <p className="time">
                                            <img
                                                src="/assets/icon/fi-rr-clock.svg"
                                                alt="fi-rr-clock"
                                            />
                                            {item.time_create}
                                        </p>
                                    </div>
                                </a>
                            );
                    })}
                    {metadataNotifications.total_page > 0 &&
                        metadataNotifications.page <
                            metadataNotifications.total_page && (
                            <div
                                className="more"
                                onClick={(event) => {
                                    event.preventDefault();
                                    dashboardStore.staticDataNotifications.page = `${
                                        metadataNotifications.page + 1
                                    }`;
                                    dashboardStore.fetchDataNotifications();
                                }}
                            >
                                <button>Xem thêm</button>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};
export default observer(Notifications);
