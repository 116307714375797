import { makeAutoObservable } from "mobx";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { productService } from "./ProductService";
import { dashboardStore } from "../../dashboard/DashboardStore";
import { object } from "yup";
import { dashboardService } from "../../dashboard/DashboardService";
class ProductStore {
    selectedItem: any = null;
    isModalOpen = false;
    isLoading: boolean = false;
    isLoadingStore: boolean = false;
    isLoadingGenerateAttributes: boolean = false;
    listBanner: string[] = [];
    dataListStore: any[] = [];
    listFeature: any[] = [];
    listCategory: any[] = [];
    listBrand: any[] = [];
    listTags: any[] = [];
    listProduct: any[] = [];
    dataProduct: any = object;
    lisAttribute: any[] = [];
    arrayQtyStore: any[] = []; // Tồn kho cửa hàng của sản phẩm đơn giản
    listGenerateAttributes: any[] = [];
    arrayAttributeProduct: any[] = []; // Data map đổ ra font-end
    productType: string = "";
    // Loại giá và data của loại giá đó
    statusProduct: string = "";
    dataStatusProduct: any[] = [];

    metadata: any = {
        total: "",
        page: 0,
        total_page: "",
        size: "",
    };
    initialValues: {
        name: string;
        description: string;
        parent_id: string;
        allow_order: number;
        store: any;
    } = {
        name: "",
        description: "",
        parent_id: "",
        allow_order: 0,
        store: [],
    };
    staticDataGenerate: {
        title: string;
        slug: string;
        brand_id: string;
        status: string;
        price_origin: string;
        price_compare_origin: string;
        sku_origin: string;
        weight_origin: number;
        unit: string;
        show_price: string;
    } = {
        title: "",
        slug: "",
        brand_id: "",
        status: "",
        price_origin: "",
        price_compare_origin: "",
        sku_origin: "",
        weight_origin: 0,
        unit: "",
        show_price: "",
    };

    constructor() {
        this.initializeParams();
        makeAutoObservable(this);
    }
    staticDataGenerateAttributes: { attribute_ids: any } = {
        attribute_ids: [],
    };
    staticDataProduct: {
        preview: string;
        multi_images: [];
        name: string;
        short_description: string;
        status: string;
        show_price: string;
        featured: string;
        categories: [];
        brand: string;
        tags: string;
        price_origin: string;
        price_compare_origin: string;
        sku_origin: string;
        weight_origin: number | null;
        unit: string;
        height_origin: number | null;
        length_origin: number | null;
        width_origin: number | null;
        product_type: string;
        allow_inventory: string;
        product_attribute_sku: any;
        attributeParent: any;
        qty: any;
        long_description: string;
    } = {
        preview: "",
        multi_images: [],
        name: "",
        short_description: "",
        status: "1",
        show_price: "1",
        featured: "",
        categories: [],
        brand: "",
        tags: "",
        price_origin: "0",
        price_compare_origin: "0",
        sku_origin: "",
        weight_origin: 0,
        unit: "",
        height_origin: 0,
        length_origin: 0,
        width_origin: 0,
        product_type: "0",
        allow_inventory: "1",
        product_attribute_sku: [],
        attributeParent: [],
        qty: [],
        long_description: "",
    };
    staticDataListProduct: {
        brand_id: any[];
        category_id: any[];
        offset: number | null;
        order_column: string;
        order_value: string;
        page_number: number;
        per_page: number;
        search: string;
        status: string;
        tag_id: number | null;
    } = {
        brand_id: [],
        category_id: [],
        offset: null,
        order_column: "",
        order_value: "DESC",
        page_number: 0,
        per_page: 10,
        search: "",
        status: "",
        tag_id: null,
    };

    staticDataDetailProduct: { id: string; request_type: string } = {
        id: "",
        request_type: "",
    };

    changeFilePreview = (pathFile: string) => {
        this.staticDataProduct.preview = pathFile;
    };
    changeFileVariant = (pathFile: string, ordering: number) => {
        this.listGenerateAttributes[ordering].thumbnail = pathFile;
    };
    handleClosePreview = () => {
        this.staticDataProduct.preview = "";
    };
    changeFileMultiImages = (pathFile: any) => {
        // this.staticDataProduct.multi_images = [...this.staticDataProduct.multi_images,pathFile];
        this.staticDataProduct.multi_images = pathFile;
    };
    handleCloseMultiImages = (index: number) => {
        this.staticDataProduct.multi_images.splice(index, 1);
    };
    handleCloseThumnail = (index: number) => {
        this.dataProduct[index].thumbnail = "";
    };
    changeFileThumbnail = (pathFile: string) => {
        this.dataProduct[0].thumbnail = pathFile;
    };
    initializeParams() {
        const query = new URLSearchParams(window.location.search); // Using window.location instead of useLocation()
        this.staticDataListProduct.page_number = query.get("page")
            ? Number(query.get("page")) - 1
            : 0;
        this.staticDataListProduct.search = query.get("keyword")
            ? String(query.get("keyword"))
            : "";
    }
    async fetchListProduct() {
        let {
            brand_id,
            category_id,
            offset,
            order_column,
            order_value,
            page_number,
            per_page,
            search,
            status,
            tag_id,
        } = this.staticDataListProduct;
        const params = {
            brand_id: brand_id,
            category_id: category_id,
            offset: offset,
            order_column: order_column,
            order_value: order_value,
            page_number: page_number + 1,
            per_page: per_page,
            search: search,
            status: status,
            tag_id: tag_id,
        };
        this.isLoading = true;
        const result = await productService.fetchListProduct(params);
        if (result.status === HttpStatusCode.Ok) {
            this.isLoading = false;
            this.listProduct = result.body.data;
            this.metadata = result.body.metadata;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchStoreProduct(form: any) {
        let {
            preview,
            multi_images,
            name,
            short_description,
            status,
            show_price,
            featured,
            categories,
            brand,
            tags,
            price_origin,
            price_compare_origin,
            sku_origin,
            weight_origin,
            unit,
            height_origin,
            length_origin,
            width_origin,
            product_type,
            allow_inventory,
            product_attribute_sku,
            qty,
            long_description,
        } = this.staticDataProduct;
        // console.log(this.staticDataProduct)
        const params = {
            preview: preview,
            multi_images: multi_images,
            name: name,
            short_description: short_description,
            status: status ? status : "1",
            show_price: show_price ? show_price : "1",
            featured: featured,
            categories: categories,
            brand: brand,
            tags: tags,
            price_origin: price_origin,
            price_compare_origin: price_compare_origin,
            sku_origin: sku_origin,
            weight_origin: weight_origin,
            unit: unit,
            height_origin: height_origin,
            length_origin: length_origin,
            width_origin: width_origin,
            product_type: product_type,
            product_attribute_sku: product_attribute_sku,
            allow_inventory: allow_inventory,
            qty: qty,
            attributeParent: this.arrayAttributeProduct.map(
                (item: any) => item.id
            ),
            long_description: long_description,
        };
        this.isLoadingStore = true;
        try {
            const result = await productService.fetchStoreProduct(params);
            if (result.status === HttpStatusCode.Ok) {
                toastUtils.success("Thêm mới thành công", "");
                this.staticDataProduct.preview = "";
                this.staticDataProduct.multi_images = [];
                this.arrayAttributeProduct = [];
                this.staticDataGenerateAttributes.attribute_ids = [];
                this.listGenerateAttributes = [];
                this.staticDataProduct.product_type = "0";
                console.log(this.arrayAttributeProduct);
                form.resetFields();
            } else {
                this.handleErrors(result.body.message);
            }
        } catch (error) {
            console.error("Error fetching product:", error);
        } finally {
            this.isLoadingStore = false;
        }
    }

    handleErrors(message: any) {
        if (message === "variant_sku_codes_are_overlapping") {
            toastUtils.error("Mã SKU bị trùng", "");
        } else {
            if (message.product_type === "the_product_type_field_is_required") {
                toastUtils.error("Chưa chọn loại sản phẩm", "0");
            }
            if (
                message.sku_origin === "the_sku_origin_has_already_been_taken"
            ) {
                toastUtils.error("Mã SKU đã tồn tại", "1");
            }
            if (message.name === "this_product_already_exists") {
                toastUtils.error("Tên sản phẩm đã tồn tại", "2");
            }
        }
    }
    async fetchListAttribute() {
        const result = await productService.fetchListAttribute();
        if (result.status === HttpStatusCode.Ok) {
            this.lisAttribute = result.body.data;
            if (this.lisAttribute.length > 0) {
                this.lisAttribute[0].options = [
                    ...this.lisAttribute.map((item) => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    }),
                ];
                this.arrayAttributeProduct = [
                    ...this.arrayAttributeProduct,
                    { ...this.lisAttribute[0] },
                ];
            }
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchGenerateAttributes() {
        let { attribute_ids } = this.staticDataGenerateAttributes;
        const params = {
            attribute_ids: attribute_ids,
            price_original:
                this.staticDataProduct.price_origin !== "null"
                    ? this.staticDataProduct.price_origin
                    : "0",
            price_compare_original:
                this.staticDataProduct.price_compare_origin !== "null"
                    ? this.staticDataProduct.price_compare_origin
                    : "0",
            sku_origin: this.staticDataProduct.sku_origin,
            width_original: this.staticDataProduct.width_origin,
            height_original: this.staticDataProduct.height_origin,
            length_original: this.staticDataProduct.length_origin,
            weight_original: this.staticDataProduct.weight_origin,
        };
        this.isLoadingGenerateAttributes = true;
        const result = await productService.fetchGenerateAttributes(params);
        if (result.status === HttpStatusCode.Ok) {
            this.isLoadingGenerateAttributes = false;
            this.listGenerateAttributes = result.body.map((item: any) => ({
                ...item,
                status_attribute: "1",
                allow_inventory_attribute: "1",
                allow_order_attribute: "1",
                qty_attribute: this.dataListStore.map((itemStore: any) => {
                    return {
                        store_id: itemStore.id,
                        qty: 0,
                        name: itemStore.name,
                    };
                }),
            }));
        } else {
            this.isLoadingGenerateAttributes = false;
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }
    async fetchListFeature() {
        const result = await productService.fetchListFeature();
        if (result.status === HttpStatusCode.Ok) {
            this.listFeature = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListCategory() {
        const result = await productService.fetchListCategory();
        if (result.status === HttpStatusCode.Ok) {
            this.listCategory = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListBrand() {
        const result = await productService.fetchListBrand();
        if (result.status === HttpStatusCode.Ok) {
            this.listBrand = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchListTags() {
        const result = await productService.fetchListTags();
        if (result.status === HttpStatusCode.Ok) {
            this.listTags = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }

    async fetchDataStore() {
        this.isLoading = true;
        try {
            const result = await dashboardService.fetchDataStore();
            this.dataListStore = result.body.data ?? [];
            this.arrayQtyStore = this.dataListStore.map((item: any) => {
                return { store_id: item.id, qty: 0, name: item.name };
            });
        } catch (error) {
            console.error("Error fetching revenue data:", error); // Xử lý lỗi nếu có
        } finally {
            this.isLoading = false;
        }
    }

    async fetchGetProduct() {
        let { id, request_type } = this.staticDataDetailProduct;
        const param = {
            request_type: request_type,
        };
        const result = await productService.fetchGetProduct(id, param);
        if (result.status === HttpStatusCode.Ok) {
            this.dataProduct = result.body;
            console.log(this.dataProduct);
        } else {
            toastUtils.error("Đã có lỗi xảy ra", "");
        }
    }

    setIsModalOpen = (isOpen: boolean) => {
        this.isModalOpen = isOpen;
    };
    handleClose = () => {};

    handleCancel = () => {
        this.setIsModalOpen(false);
    };
    handleEditClick = (item: any) => {
        console.log(item);
        this.selectedItem = item;
        this.setIsModalOpen(true);
    };

    handleModalClose = () => {
        this.setIsModalOpen(false);
        this.selectedItem = null;
    };
}

export const productStore = new ProductStore();
