import { observer } from "mobx-react"
import { Button } from "antd"
import { useState } from "react";
import { Collapse } from "react-bootstrap"
import { posStore } from "../PosStore";
import { toastUtils } from "../../../common/utils/Toastutils";

const ActionFilterTab = ()=>{
    const  [filterOpen, setFilterOpen] = useState(true);
    return(<>
    
        <div className="filter-tab">
            <Button className="filter-collapse-btn" onClick={() => setFilterOpen(!filterOpen)}
                    aria-controls="example-collapse-text" aria-expanded={filterOpen}>
                <span>Hành động</span>
                <i className="fas fa-chevron-down"></i>
            </Button>
            {posStore.loadingDataAll === true ? (<></>):
            (<Collapse in={filterOpen}>
                <div className="filter-tab__body">
                    <div className="filter-tab__option">
                        <input type="radio" id="makeActive" name="pos-action" onChange={(event)=>{if(event.target.checked){posStore.neededActionPosValue = 'active'}}}/>
                        <label className="radio-button" htmlFor={'makeActive'}>
                            Hoạt động
                        </label>
                    </div>
                    <div className="filter-tab__option">
                        <input type="radio" id="makeInactive" name="pos-action" onChange={(event)=>{if(event.target.checked){posStore.neededActionPosValue = 'inactive'}}}/>
                        <label className="radio-button" htmlFor={'makeInactive'}>
                            Ngừng hoạt động
                        </label>
                    </div>
                    <div className="filter-tab__option">
                        <input type="radio" id="makeDeleted" name="pos-action" onChange={(event)=>{if(event.target.checked){posStore.neededActionPosValue = 'delete'}}}/>
                        <label className="radio-button" htmlFor={'makeDeleted'}>
                            Xoá
                        </label>
                    </div>
                </div>
            </Collapse>)}
            <Button className="filter-action-btn" type="primary" disabled={posStore.listPos.length === 0} onClick={()=>{posStore.handlePosActionFilter()}}>Áp dụng</Button>
        </div>
    </>)
}
export default observer(ActionFilterTab)
