import { Modal, Form as AntdForm, Input } from "antd";
import { observer } from "mobx-react";
import UploadFile from "../../../../shared/components/form/UploadFile";
import { brandStore } from "../BrandStore";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import SelectCustom from "../../../../shared/components/form/SelectCustom";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { title } from "process";
import Loading from "../../../../shared/components/loading/Loading";
import { createSearchParams, useNavigate } from "react-router-dom";

const BrandModal = () => {
    const { TextArea } = Input;
    const validBrandSchema = yup.object().shape({
        brand_name: yup.string().required("Vui lòng nhập Tên thương hiệu"),
    });
    const navigate = useNavigate();
    return (
        <Modal
            className="modal-form"
            title={brandStore.handlerName}
            footer={null}
            open={brandStore.openModal}
            onCancel={() => {
                brandStore.openModal = false;
            }}
        >
            {brandStore.isLoadingModal == true ? (
                <div className="loading__container">
                    <Loading />
                </div>
            ) : (
                <>
                    <UploadFile
                        text={`Hình ảnh`}
                        description={`Hình ảnh thương hiệu`}
                        file={
                            brandStore.staticDataBrand.image == ""
                                ? ""
                                : brandStore.staticDataBrand.image == null
                                ? ""
                                : brandStore.staticDataBrand.image
                        }
                        onIconRemove={brandStore.handleCloseIcon}
                        onFileChange={(e) =>
                            handleFile(e, brandStore.changeFileIcon)
                        }
                    />
                    <Formik
                        initialValues={{
                            brand_name:
                                brandStore.staticDataBrand.name == ""
                                    ? ""
                                    : brandStore.staticDataBrand.name,
                            brand_description:
                                brandStore.staticDataBrand.description == ""
                                    ? ""
                                    : brandStore.staticDataBrand.description,
                        }}
                        enableReinitialize={true}
                        validationSchema={validBrandSchema}
                        onSubmit={(values, { resetForm }) => {
                            brandStore.staticDataBrand.name = values.brand_name;
                            brandStore.staticDataBrand.description =
                                values.brand_description;
                            brandStore.isCreating === true
                                ? brandStore.fetchAddBrand()
                                : brandStore.fetchUpdateBrand();
                            if (brandStore.isCreating === true) {
                                const params: any = {};
                                params.page = 0;
                                navigate({
                                    search: createSearchParams(
                                        params
                                    ).toString(),
                                });
                            }
                            resetForm();
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <div className="item-form">
                                    <label htmlFor="brand_name">
                                        Thương hiệu <span>*</span>
                                    </label>
                                    <Field
                                        as={Input}
                                        name="brand_name"
                                        placeholder="Vui lòng nhập"
                                    />
                                    <ErrorMessage
                                        name="brand_name"
                                        component="span"
                                    />
                                </div>
                                <div className="item-form">
                                    <label htmlFor="brand_description">
                                        Mô tả
                                    </label>
                                    <Field
                                        as="textarea"
                                        id="brand_description"
                                        name="brand_description"
                                        placeholder="Vui lòng nhập"
                                    />
                                </div>
                                <div className="item-form">
                                    <label htmlFor="brand_status">
                                        Trạng thái
                                    </label>
                                    <SelectStatus
                                        selected={() =>
                                            brandStore.staticDataBrand.status ==
                                            "1"
                                                ? "Hoạt động"
                                                : "Vô hiệu hoá"
                                        }
                                        onChange={(value: any) => {
                                            brandStore.staticDataBrand.status =
                                                value;
                                        }}
                                    />
                                </div>

                                <div className="btnCreateModal">
                                    <button
                                        className="cancelModal"
                                        type="reset"
                                        onClick={() => {
                                            brandStore.openModal = false;
                                        }}
                                    >
                                        Đóng
                                    </button>
                                    <button
                                        className="submitForm"
                                        type="submit"
                                    >
                                        {brandStore.waitingText === ""
                                            ? "Lưu"
                                            : brandStore.waitingText}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    );
};

export default observer(BrandModal);
