import React from "react";
import {observer} from "mobx-react";
import {Modal, Select} from "antd";
import {categoryProductStore} from "./CategoryProductStore";
import {handleFile} from "../../../common/helpers/UploadFileHelper";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {createSearchParams, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import SelectCustom from "../../../shared/components/form/SelectCustom";
import UploadFile from "../../../shared/components/form/UploadFile";
const CreateCategoryProduct = () => {
    const navigate = useNavigate();
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .max(70, 'Tên quá dài')
            .required('Không dược để trống'),
    });
    return (
        <>
            <Modal className="modal-form" title={categoryProductStore.isCreate === true ? 'Thêm danh mục' : 'Sửa danh mục'} footer={null} open={categoryProductStore.isModalOpen}
                   onOk={categoryProductStore.handleOk}
                   onCancel={categoryProductStore.handleCancel}
                   afterClose={categoryProductStore.handleClose}
            >
                <UploadFile
                    text={`hình ảnh`}
                    description={`Hình ảnh thương hiệu`}
                    file={categoryProductStore.staticDataCategoryProduct.preview}
                    onIconRemove={categoryProductStore.handleClosePreview}
                    onFileChange={(e) => handleFile(e, categoryProductStore.changeFile)}
                />
                <UploadFile
                    text={`icon`}
                    description={`Biểu tượng danh mục`}
                    file={categoryProductStore.staticDataCategoryProduct.icon}
                    onIconRemove={categoryProductStore.handleCloseIcon}
                    onFileChange={(e) => handleFile(e, categoryProductStore.changeFileIcon)}
                />
                <Formik
                    initialValues={categoryProductStore.initialValues}
                    validationSchema={SignupSchema}
                    enableReinitialize
                    onSubmit={(values:any, {resetForm}) => {
                        categoryProductStore.staticDataCategoryProduct.name = values.name
                        categoryProductStore.staticDataCategoryProduct.description = values.description
                        categoryProductStore.isCreate === true ? categoryProductStore.fetchCreateNewCategory() : categoryProductStore.fetchUpadateCategoryProduct();
                        if(categoryProductStore.isCreate === true) {
                            const params: any = {};
                            params.page = 1;
                            navigate({
                                search: createSearchParams(params).toString()
                            });
                        }
                        resetForm();
                    }}
                >
                    {({errors, touched}) => (
                        <Form>
                            <div className="item-form">
                                <label htmlFor="name">Tên <span>*</span></label>
                                <Field type="text"
                                       id="name" name="name" placeholder="Vui lòng nhập" />
                                <ErrorMessage component="span" name="name"/>
                            </div>
                            <div className="item-form item-form-select">
                                <label htmlFor="name">Danh mục Cha</label>
                                <Field  as="select" name="parent_id" id="parent_id">
                                    {({ field }: any) => (
                                        <SelectCustom
                                            list={categoryProductStore.dataListCategoryProductAll}
                                            placeholder={`Chọn danh mục cha`} labelFirst={`Chọn danh mục cha`}
                                            onChange={(value: any) => categoryProductStore.staticDataCategoryProduct.parent_id = value}
                                            selected={() => categoryProductStore.staticDataCategoryProduct.parent_id}
                                            getLabel={(item: any) => item.name}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className="item-form item-form-textarea">
                                <label htmlFor="description">Mô tả</label>
                                <Field as='textarea' id="description" name="description" placeholder="Vui lòng nhập"/>
                            </div>

                            <div className="btnCreateModal">
                                <button className="cancelModal"  onClick={(event) => {
                                    event.preventDefault();
                                    categoryProductStore.setIsModalOpen(false)
                                }}>Đóng
                                </button>
                                <button className="submitForm" type="submit">
                                    {categoryProductStore.isLoadingSave == false ? 'Lưu' : 'Đang lưu...'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
export default observer(CreateCategoryProduct);