import React from "react";
import { observer } from "mobx-react";
import {productStore} from "../../ProductStore";
import {Form} from "antd";
import SelectStatus from "../../../../../shared/components/form/SelectStatus";
const TypeCreateProduct = () => {
    return(
        <>
            <div className="session-create">
                <div className="session-create-left">
                    <div className="session-create-left-content">
                        <p className="title">Loại sản phẩm</p>
                        <p className="description">Chọn loại sản phẩm từ đây</p>
                    </div>
                </div>
                <div className="session-create-right">
                    <div className="session-create-content">
                        <div className="item-form">
                            <Form.Item name="product_type" label="Loại sản phẩm">
                                <SelectStatus selected={() => productStore.staticDataProduct.product_type}
                                              onChange={(value: any) => productStore.staticDataProduct.product_type = value}
                                              textOn={`Sản phẩm biến thể`} textOff={`Sản phẩm đơn giản`}/>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(TypeCreateProduct)