import React, { useEffect, useState } from 'react';
import {observer} from "mobx-react";
import { toastUtils } from '../../../common/utils/Toastutils';
import Loading from '../loading/Loading';
interface IconUploadProps {
    text: string | null;
    description: string | null;
    file: string[] | null;
    onIconRemove: (index: number) => void;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const UploadFileMultiple: React.FC<IconUploadProps> = ({ text, description, file, onIconRemove, onFileChange }) => {
    const [uploadingStatusList, setUploadingStatusList] = useState<boolean[]>([])
    const [dummyImageList, setDummyImageList] = useState<any[]>([])
    function handleUploadingStatus(event:any){
        var numberOfImg = event.target.files.length;
        for(let index=1; index <= numberOfImg; index++){
            setUploadingStatusList((prevStatus) => [...prevStatus, true] )
        }
    }
    useEffect(()=>{
        if(file){
            setUploadingStatusList([]);
            file.map((filePath: string, index:number)=>{
                if(filePath){
                    uploadingStatusList[index] = false;
                    dummyImageList[index] = filePath;
                }
                else{
                    uploadingStatusList[index] = true;
                }
            })
        }
        else{
            setDummyImageList([])
        }
    },[file])
    return (
        <div className="upload-form">
            <label>{text}</label>
            <div className="upload">
                <p>{description}</p>
                <div className="uploadChild">
                    <div>
                        <img
                            className="icon_upload"
                            width="38"
                            height="38"
                            src="/assets/icon/icon_upload.svg"
                            alt="icon_upload"
                        />
                        <span className="text-upload">Chọn {text}</span>
                        <input
                            onChange={(event)=>{onFileChange(event); handleUploadingStatus(event);}}
                            accept="video/mp4, image/jpeg, image/jpg, image/png, application/pdf, .doc, .docx, .ppt, .pptx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            multiple
                        />
                    </div>
                </div>
            </div>
            <div className='upload-files-multiple'>
                {file ? (<>
                    {uploadingStatusList.length !== 0 ? (<>
                        {uploadingStatusList.map((item:any, index:number)=>(
                            <div className="upload-item-image" key={index}>
                                <Loading/>
                            </div>
                        ))}
                    </>):(<>
                        {file.map((item:any, index:number)=>(
                            <div className="upload-item-image">
                                <a href={item} target="_blank" rel="noopener noreferrer">
                                    {item.endsWith('.png') || item.endsWith('.jpg') || item.endsWith('.jpeg') ?
                                    (<img src={item} alt={`uploaded-file-${index}`} width="100" />)
                                    :
                                    (<span style={{wordBreak:'break-all', padding:'10px 10px 0px'}}>{item.split('/').pop()}</span>)}
                                </a>
                                <button onClick={() => onIconRemove(index)} className="close_image">
                                    <img src="/assets/icon/minus-circle.svg" className="icon-action" alt="" title="Xóa" />
                                </button>
                            </div>
                        ))}
                    </>)}
                </>):(<>
                </>)}
            </div>
            {/* {file && file.length > 0 && (
                <div className="upload-files-multiple">
                    {file.map((filePath, index) =>  (
                        <div key={index} className="upload-item-image">
                            <a href={filePath} target="_blank" rel="noopener noreferrer">
                                {filePath.endsWith('.png') || filePath.endsWith('.jpg') || filePath.endsWith('.jpeg') ? (
                                    <img src={filePath} alt={`uploaded-file-${index}`} width="100" />
                                ) : (
                                    <span style={{wordBreak:'break-all', padding:'10px 10px 0px'}}>{filePath.split('/').pop()}</span>
                                )}
                            </a>
                            
                        </div>
                    ))}
                </div>
            )} */}
        </div>
    );
};

export default observer(UploadFileMultiple);
