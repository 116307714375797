import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashBoard from "../pages/dashboard/DashBoardPage";
import Product from "../pages/goodsmanager/product/ProductPage";
import GroupProduct from "../pages/goodsmanager/groupProduct/GroupProduct";
import CategoryProduct from "../pages/goodsmanager/categoryProduct/CategoryProduct";
import PrivateRoute from "./privaterouters";
import TagProduct from "../pages/goodsmanager/tagProduct/TagProduct";
import Brand from "../pages/goodsmanager/brand/Brand";
import Login from "../pages/login/Login";
import CreateProduct from "../pages/goodsmanager/product/createProduct/CreateProductPage";
import Coupon from "../pages/goodsmanager/coupon/Coupon";
import Attribute from "../pages/goodsmanager/attribute/Attribute";
import EditProductGeneral from "../pages/goodsmanager/product/editProduct/EditProductGeneral";
import EditProductVariant from "../pages/goodsmanager/product/editProduct/EditProductVariant";
import EditProductBarcode from "../pages/goodsmanager/product/editProduct/EditProductBarcode";
import EditProductQrcode from "../pages/goodsmanager/product/editProduct/EditProductQrcode";
import EditProductImage from "../pages/goodsmanager/product/editProduct/EditProductImage";
import Label from "../pages/goodsmanager/label/Label";
import Info from "../pages/user/info/Info";
import Inventory from "../pages/goodsmanager/inventory/Inventory";
import Order from "../pages/order/Order";
import EditOrder from "../pages/order/editOrder/EditOrder";
import PrintOrder from "../pages/order/printOrder/PrintOrder";
import Pos from "../pages/pos/Pos";

const Redirect = () => {
    return (
        <Routes>
            <Route path={`/dang-nhap`} element={<Login />} />
            <Route path={`/`} element={<DashBoard />} />
            <Route path={`/hang-hoa/`}>
                <Route
                    path={`san-pham`}
                    element={<PrivateRoute element={<Product />} />}
                />
                <Route
                    path={`san-pham/them-moi`}
                    element={<PrivateRoute element={<CreateProduct />} />}
                />
                <Route
                    path={`san-pham/:id/thong-tin-chung`}
                    element={<PrivateRoute element={<EditProductGeneral />} />}
                />
                <Route
                    path={`san-pham/:id/gia`}
                    element={<PrivateRoute element={<EditProductVariant />} />}
                />
                <Route
                    path={`san-pham/:id/tao-ma-barcode`}
                    element={<PrivateRoute element={<EditProductBarcode />} />}
                />
                <Route
                    path={`san-pham/:id/tao-ma-qrcode`}
                    element={<PrivateRoute element={<EditProductQrcode />} />}
                />
                <Route
                    path={`san-pham/:id/hinh-anh`}
                    element={<PrivateRoute element={<EditProductImage />} />}
                />
                <Route
                    path={`thuong-hieu`}
                    element={<PrivateRoute element={<Brand />} />}
                />
                <Route
                    path={`nhom-san-pham`}
                    element={<PrivateRoute element={<GroupProduct />} />}
                />
                <Route
                    path={`danh-muc`}
                    element={<PrivateRoute element={<CategoryProduct />} />}
                />
                <Route
                    path={`ma-giam-gia`}
                    element={<PrivateRoute element={<Coupon />} />}
                />
                <Route
                    path={`the-san-pham`}
                    element={<PrivateRoute element={<TagProduct />} />}
                />
                <Route
                    path={`nhan`}
                    element={<PrivateRoute element={<Label />} />}
                />
                <Route
                    path={`ton-kho`}
                    element={<PrivateRoute element={<Inventory />} />}
                />
                <Route
                    path={`thuoc-tinh`}
                    element={<PrivateRoute element={<Attribute />} />}
                />
            </Route>
            <Route
                path={`thong-tin-tai-khoan`}
                element={<PrivateRoute element={<Info />} />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route
                path={`don-hang`}
                element={<PrivateRoute element={<Order />} />}
            />
            <Route
                path={`don-hang/:id`}
                element={<PrivateRoute element={<EditOrder />} />}
            />
            <Route
                path={`in-hoa-don/:id`}
                element={<PrivateRoute element={<PrintOrder />} />}
            />
            <Route
                path={`may-pos`}
                element={<PrivateRoute element={<Pos />} />}
            />
        </Routes>
    );
};

export default Redirect;
