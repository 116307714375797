import React from "react";
import {observer} from "mobx-react";
import SelectCustom from "../../../../../shared/components/form/SelectCustom";
import {productStore} from "../../ProductStore";
import {Form, Select} from "antd";
import SelectMultipleAnt from "../../../../../shared/components/form/SelectMultipleAnt";
const  CategoryAndBrand = () => {
    return (
        <>
            <div className="session-create">
                <div className="session-create-left">
                    <div className="session-create-left-content">
                        <p className="title">Danh mục & Thương hiệu</p>
                        <p className="description">Chọn thương hiệu và danh mục sản phẩm từ đây</p>
                    </div>
                </div>
                <div className="session-create-right">
                    <div className="session-create-content">
                        <div className="item-form">
                            <Form.Item name="featured" label="Chọn loại nổi bật"
                                       rules={[{required: true, message: 'Không được để trống!'}]}>
                                <SelectCustom
                                    list={productStore.listFeature}
                                    placeholder={`-- Chọn --`}
                                    onChange={(value: any) => productStore.staticDataProduct.featured = value}
                                    selected={() => {
                                        const featureId = productStore.staticDataProduct.featured;
                                        return featureId || undefined;  // Return undefined if both are unavailable
                                    }}
                                    getLabel={(item: any) => item.name}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item name="categories" label="Danh mục sản phẩm"
                                       rules={[{required: true, message: 'Không được để trống!'}]}>
                                <SelectMultipleAnt
                                    list={productStore.listCategory}
                                    placeholder={`-- Chọn --`}
                                    getLabel={(item: any) => item.name}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item name="brand" label="Thương hiệu"
                                       rules={[{required: true, message: 'Không được để trống!'}]}>
                                <SelectCustom
                                    list={productStore.listBrand}
                                    placeholder={`-- Chọn --`}
                                    onChange={(value: any) => productStore.staticDataProduct.brand = value}
                                    selected={() => {
                                        const featureId = productStore.staticDataProduct.brand;
                                        return featureId || undefined;  // Return undefined if both are unavailable
                                    }}
                                    getLabel={(item: any) => item.name}
                                />
                            </Form.Item>
                        </div>
                        <div className="item-form">
                            <Form.Item name="tags" label="Thẻ sản phẩm">
                                <SelectMultipleAnt
                                    list={productStore.listTags}
                                    placeholder={`-- Chọn --`}
                                    getLabel={(item: any) => item.name}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(CategoryAndBrand)