import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { productStore } from "../ProductStore";
import { Link, useParams } from "react-router-dom";
import { Checkbox, Col, theme, Form, GetProp, Input, Row } from "antd";
import EditProductMenu from "./EditProductMenu";
import "../createProduct/styles/create_product.css";
import SelectStatus from "../../../../shared/components/form/SelectStatus";
import { Accordion } from "react-bootstrap";
import UploadFile from "../../../../shared/components/form/UploadFile";
import { handleFile } from "../../../../common/helpers/UploadFileHelper";

const EditProductVariant = () => {
    const { token } = theme.useToken();
    const { id } = useParams<{ id: string }>();
    const getID = id;
    const [isVariant, setIsVariant] = useState<any>(id);
    const [form] = Form.useForm();
    productStore.staticDataDetailProduct.id = `${getID}`;
    productStore.staticDataDetailProduct.request_type = `variant`;
    useEffect(() => {
        productStore.fetchGetProduct();
        productStore.fetchListProduct();
    }, []);

    useEffect(() => {
        // Lọc qua để lấy được biến thể của chi tiết giá
        const statusPriceVariant = productStore.listProduct?.filter(
            (item) => item.id.toString() === isVariant
        );
        // Kiểm tra nếu statusPriceVariant có dữ liệu
        if (statusPriceVariant && statusPriceVariant[0]) {
            productStore.statusProduct =
                statusPriceVariant[0].is_variation?.toString() || "";

            const getDataVariant = Array.isArray(productStore.dataProduct)
                ? productStore.dataProduct.filter(
                      (item: any) =>
                          item.is_variant ===
                          parseInt(productStore.statusProduct)
                  )
                : [];

            productStore.dataStatusProduct = getDataVariant;
        } else {
            // Nếu không tìm thấy dữ liệu, gán giá trị mặc định nếu cần
            productStore.statusProduct = "";
            productStore.dataStatusProduct = [];
        }

        console.log("status", productStore.statusProduct);
        console.log("data", productStore.dataStatusProduct);
    }, [isVariant, productStore.listProduct, productStore.dataProduct]);

    const plainOptions = ["Cho phép đặt hàng khi sản phẩm hết hàng"];
    const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (
        checkedValues
    ) => {
        console.log("checked = ", checkedValues);
    };

    const [value, setValue] = useState<number>(100);
    const increQty = () => {
        setValue(value + 1);
    };
    const decreQty = () => {
        setValue(value - 1);
    };

    console.log("check statusproduct", productStore.statusProduct);

    // const panelStyle: React.CSSProperties = {
    //     marginBottom: 24,
    //     background: token.colorFillAlter,
    //     borderRadius: token.borderRadiusLG,
    //     border: "none",
    // };

    return (
        <>
            <div className="container">
                <div className="mainContentCreateProduct">
                    <div className="title-create-product">
                        <Link to="/hang-hoa/san-pham">
                            <span>
                                <img
                                    src="/assets/icon/arrow-left.svg"
                                    alt="fi-sr-caret-down"
                                />
                                Sửa giá sản phẩm
                            </span>
                        </Link>
                    </div>
                    <Form
                        className="form-create-product"
                        form={form}
                        name="control-hooks"
                        onFinish={(values) => {
                            console.log(values);
                        }}
                        style={{ maxWidth: 600 }}
                    >
                        <div className="session-create">
                            <div
                                className="session-create-left session-create-left-edit"
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                }}
                            >
                                <div
                                    className="session-create-left-content"
                                    style={{ width: "100%" }}
                                >
                                    <EditProductMenu
                                        idProduct={`${getID}`}
                                        nameEdit="variant"
                                    />
                                </div>
                            </div>

                            <div className="session-create-right">
                                <div className="item-flex item-selected gap-20">
                                    <Form.Item name="status" label="Loại giá">
                                        <SelectStatus
                                            selected={() =>
                                                productStore.statusProduct
                                                    ? productStore.statusProduct
                                                    : "Đang Tải"
                                            }
                                            onChange={(value: any) => {
                                                console.log(
                                                    "check value",
                                                    value
                                                );
                                                productStore.statusProduct =
                                                    value;
                                                setIsVariant(value);
                                            }}
                                            textOn={"Sản phẩm biến thể"}
                                            textOff={"Sản phẩm đơn giản"}
                                        />
                                    </Form.Item>
                                </div>

                                <Accordion defaultActiveKey="0" alwaysOpen>
                                    {productStore.dataProduct.length > 0 ? (
                                        productStore.dataStatusProduct.map(
                                            (item: any, index: number) => {
                                                return (
                                                    <Accordion.Item
                                                        eventKey={`${index}`}
                                                        key={index}
                                                    >
                                                        <Accordion.Header>
                                                            <p className="mb-0">
                                                                {item.attribute_compare ===
                                                                    "" ||
                                                                item.attribute_compare ===
                                                                    null
                                                                    ? "Mặc định"
                                                                    : item.attribute_compare}
                                                            </p>
                                                            <span
                                                                style={{
                                                                    padding:
                                                                        "12px",
                                                                    fontSize:
                                                                        "12px",
                                                                    borderRadius:
                                                                        "4px",
                                                                }}
                                                            >
                                                                xóa
                                                            </span>
                                                        </Accordion.Header>
                                                        <Accordion.Body className="no-border-body">
                                                            <div
                                                                className="accordion"
                                                                style={{
                                                                    marginTop:
                                                                        "16px",
                                                                }}
                                                            >
                                                                <div className="accordion_wrapper">
                                                                    <div className="accordion_content d-flex justify-content-center flex-column">
                                                                        <Row
                                                                            gutter={
                                                                                16
                                                                            }
                                                                        >
                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        padding:
                                                                                            "0px",
                                                                                        border: "none",
                                                                                    }}
                                                                                >
                                                                                    <UploadFile
                                                                                        text={`Hình ảnh`}
                                                                                        file={
                                                                                            item.thumbnail
                                                                                        }
                                                                                        onIconRemove={() =>
                                                                                            productStore.handleCloseThumnail
                                                                                        }
                                                                                        onFileChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleFile(
                                                                                                e,
                                                                                                productStore.changeFileThumbnail
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </Col>

                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="price"
                                                                                        label="Giá gốc:"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.price
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="SKU:"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.sku
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>

                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="Giá bán( nhập bằng giá gốc nếu không giảm ) "
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.price_compare
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="Đơn vị tính"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.unit
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>

                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="Cân nặng(gram)"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.weight
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="Chiều cao (cm)"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.height
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>

                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="Chiều rộng(cm)"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.width
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="title"
                                                                                        label="Độ dài (cm)"
                                                                                        rules={[
                                                                                            {
                                                                                                required:
                                                                                                    true,
                                                                                                message:
                                                                                                    "Không được để trống!",
                                                                                            },
                                                                                        ]}
                                                                                    >
                                                                                        <Input
                                                                                            defaultValue={
                                                                                                item.length
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>

                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="status"
                                                                                        label="Tình trạng"
                                                                                    >
                                                                                        <SelectStatus
                                                                                            selected={() =>
                                                                                                item.status >
                                                                                                1
                                                                                                    ? "Còn hàng"
                                                                                                    : "Hết hàng"
                                                                                            }
                                                                                            onChange={(
                                                                                                value: any
                                                                                            ) =>
                                                                                                (productStore.dataProduct.status =
                                                                                                    value)
                                                                                            }
                                                                                            textOn={
                                                                                                "Còn hàng"
                                                                                            }
                                                                                            textOff={
                                                                                                "Hết hàng"
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form"
                                                                                    style={{
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <Form.Item
                                                                                        name="status"
                                                                                        label="Loại tồn kho"
                                                                                    >
                                                                                        <SelectStatus
                                                                                            selected={() =>
                                                                                                item.stock_status ===
                                                                                                1
                                                                                                    ? "Không cho phép quản lý tồn kho"
                                                                                                    : "Cho phép quản lý tồn kho"
                                                                                            }
                                                                                            onChange={(
                                                                                                value: any
                                                                                            ) =>
                                                                                                (productStore.dataProduct.status =
                                                                                                    value)
                                                                                            }
                                                                                            textOn={
                                                                                                "Cho phép quản lý tồn kho"
                                                                                            }
                                                                                            textOff={
                                                                                                "Không cho phép quản lý tồn kho"
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Col>

                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                                style={{
                                                                                    marginTop:
                                                                                        "16px",
                                                                                    paddingBottom:
                                                                                        "16px",
                                                                                }}
                                                                                className="border-bottom"
                                                                            >
                                                                                <Checkbox.Group
                                                                                    options={
                                                                                        plainOptions
                                                                                    }
                                                                                    defaultValue={[
                                                                                        "Apple",
                                                                                    ]}
                                                                                    onChange={
                                                                                        onChange
                                                                                    }
                                                                                    style={{
                                                                                        fontSize:
                                                                                            "16px",
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                span={
                                                                                    24
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="item-form-inventory d-flex align-item-center justify-content-between"
                                                                                    style={{
                                                                                        border: "none",
                                                                                        marginTop:
                                                                                            "16px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="item-store"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            className="mb-0 d-flex align-items-center"
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "14px",
                                                                                                fontWeight:
                                                                                                    "normal",
                                                                                                gap: "10px",
                                                                                                textTransform:
                                                                                                    "initial",
                                                                                            }}
                                                                                        >
                                                                                            {localStorage
                                                                                                .getItem(
                                                                                                    "idDomain"
                                                                                                )
                                                                                                ?.replace(
                                                                                                    /.xweb.asia/i,
                                                                                                    ""
                                                                                                )}
                                                                                            <span>
                                                                                                (Mặc
                                                                                                định)
                                                                                            </span>
                                                                                        </p>

                                                                                        <div className="qty">
                                                                                            <div className="minus d-grid align-items-center">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={
                                                                                                        decreQty
                                                                                                    }
                                                                                                >
                                                                                                    -
                                                                                                </button>
                                                                                            </div>
                                                                                            <Input
                                                                                                type="number"
                                                                                                value={
                                                                                                    item
                                                                                                        .inventory
                                                                                                        .quantity
                                                                                                }
                                                                                                min="0"
                                                                                                max="99999"
                                                                                                maxLength={
                                                                                                    16
                                                                                                }
                                                                                                onChange={(
                                                                                                    e: any
                                                                                                ) =>
                                                                                                    setValue(
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            <div className="plus">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={
                                                                                                        increQty
                                                                                                    }
                                                                                                >
                                                                                                    +
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                );
                                            }
                                        )
                                    ) : (
                                        <span
                                            style={{
                                                paddingLeft: "12px",
                                            }}
                                        >
                                            Đang tải
                                        </span>
                                    )}
                                </Accordion>

                                <div
                                    className="formGroup d-flex align-items-center justify-content-between"
                                    style={{
                                        marginTop: "16px",
                                    }}
                                >
                                    <div className="button">
                                        <button>Cập nhập</button>
                                    </div>

                                    <span
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            color: "#6777ef",
                                            fontWeight: "500",
                                        }}
                                    >
                                        + Thêm mới biến thể
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
};
export default observer(EditProductVariant);
