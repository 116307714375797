import {observer} from "mobx-react";
import React, {useState} from "react";
import {Button} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import {productStore} from "../../ProductStore";
const FilterGroupProduct = () => {
    const [openFilterBrand, setopenFilterBrandProduct] = useState(true);
    const [selectedBrand, setSelected] = useState<string[]>([]);
    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelected([...selectedBrand, name]);
            productStore.staticDataListProduct.brand_id = [...selectedBrand, name];
        } else {
            setSelected(selectedBrand.filter((item) => item !== name));
            productStore.staticDataListProduct.brand_id = selectedBrand.filter((item) => item !== name);
        }
        productStore.fetchListProduct()
    }
    function selectAll(value: boolean) {
        if (value) {
            setSelected(productStore.listBrand.map((item: any) => item.id));
            productStore.staticDataListProduct.brand_id = productStore.listBrand.map((item: any) => productStore.staticDataListProduct.brand_id.push(item.id));
        } else {
            setSelected([]);
            productStore.staticDataListProduct.brand_id = [];
        }
        productStore.fetchListProduct()
    }
    const allSelected = selectedBrand.length === productStore.listBrand.length;
    return (
        <div className="filter-category-product filter-goods-manager">
            <Button onClick={() => setopenFilterBrandProduct(!openFilterBrand)}
                    aria-controls="example-collapse-text" aria-expanded={openFilterBrand}>
                Thương hiệu
            </Button>
            <Collapse in={openFilterBrand}>
                <div className="listBrandProduct">
                    <label className="radio-button">
                        <input type="checkbox" name="category_product" value="all"  checked={allSelected}
                               onChange={(e) => selectAll(e.target.checked)}/>
                        <span className="radio-tick"></span>
                        Tất cả
                    </label>
                    {productStore.listBrand.map((item:any,index:number) => (
                        <label className="radio-button">
                            <input type="checkbox"
                                   name="category_product[]"
                                   value={item.id}
                                   checked={selectedBrand.includes(item.id)}
                                   onChange={(e) => handleSelect(e.target.checked, item.id)}
                            />
                            <span className="radio-tick"></span>
                            <p>{item.name}</p>
                        </label>
                    ))}
                </div>
            </Collapse>
        </div>
    )
}
export default observer(FilterGroupProduct);