import { Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { posStore } from "../PosStore";

interface SelectStoreProps {
  onChange: (value: any) => void;
  selected: () => string | undefined;  // sửa kiểu trả về từ hàm selected
}

const SelectStore: React.FC<SelectStoreProps> = ({ onChange, selected }) => {
  const storeOptionList = posStore.listStore.map((item: any) => ({
    value: String(item.id),
    label: item.name,
  }));

  return (
    <Select
      placeholder="Vui lòng chọn"
      onChange={onChange}
      value={selected()} // truyền trực tiếp giá trị được chọn
      filterOption={(input, option) =>
        (option?.label ?? "")
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      options={storeOptionList}  // options đã được tạo từ listStore
    />
  );
};

export default observer(SelectStore);
