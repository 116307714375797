import React from "react";
class Utils {
    public formatCurrency(amount: number, currency?: string): string {
        const formatter = new Intl.NumberFormat('vi-VN', {
            style: 'currency',
            currency: currency ? currency : 'VND',
            minimumFractionDigits: 0,
        });
        return formatter.format(amount);
    }
}
export const utils = new Utils();
