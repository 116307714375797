import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import { Dropdown} from "react-bootstrap";
import { attributeStore } from "./AttributeStore";
import ReactPaginate from "react-paginate";
import { Input, Button, Form as AntdForm, Flex, Upload, Popconfirm } from 'antd';
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import './attribute.css';
import Loading from "../../../shared/components/loading/Loading";
import AttributeModal from "./AttributeModal";
import NoContent from "../../../shared/components/NoContent";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Attribute = ()=>{
    function handleSelect(value: boolean, name: string) { // handle select checkbox for each item in the table
        if (value) {
            attributeStore.selectedTargets.push(name)
        } else {
            attributeStore.selectedTargets = attributeStore.selectedTargets.filter(item => item !== name)
        }
    }
    var allSelectedTargetInTable: boolean = (attributeStore.dataListAttribute === undefined ? false : (attributeStore.selectedTargets.length === attributeStore.dataListAttribute.length));
    function selectAllTargetInTable(value: boolean){ //handle select The Header Checkbox in table
        if (value) {
            attributeStore.selectedTargets = attributeStore.dataListAttribute.map((item: any) => item.id);
        } else {
            attributeStore.selectedTargets = []
        }
    }

    const navigate = useNavigate();
    
    const handlePageClick = (data: { selected: number }) => {//Handle page change when paginating
        const selectedPage = data.selected;
        const params: any = {};
        if (attributeStore.staticParamAttribute.search !== '') {
            params.keyword = `search=${attributeStore.staticParamAttribute.search}`
        };
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString()
        });
        attributeStore.staticParamAttribute.page = selectedPage;
        attributeStore.getDataListAttribute();
    };

    function onSubmitSearch(event:any){
        const searchKeyword = attributeStore.staticParamAttribute.search;
        event.preventDefault();
        const params: any = {}
        if(searchKeyword !== ''){
            params.keyword = `${searchKeyword}`;
        }
        navigate({
            search: createSearchParams(params).toString()
        })
        attributeStore.getDataListAttribute();
    }
    function onHandleKeyDown(event: any){
        if(event.key === 'Enter'){
            onSubmitSearch(event);
        }
    }

    useEffect(()=>{
        attributeStore.getDataListAttribute();
        attributeStore.getDataListAttributeAll();
    },[])


    return(
        <div className="container">
            <div className="mainContentAttribute">
                <div className="topbar">
                    <div className="topbar__title">Thuộc tính</div>
                    <form className="topbar__search" onSubmit={onSubmitSearch}>
                        <input id="search" type="text" onKeyDown={(event:any)=>{onHandleKeyDown(event)}} value={attributeStore.staticParamAttribute.search} onChange={(event)=>attributeStore.staticParamAttribute.search = event.target.value} placeholder="Nhập tên thuộc tính"/>
                        <button type="submit"><img src="/assets/icon/search.svg" alt="" /></button>
                    </form>
                    <button className="btn-add-modal" onClick={()=>{
                        attributeStore.openModal = true; attributeStore.isCreating = true; attributeStore.modalName = "Thêm mới thuộc tính"; attributeStore.handleResetModal()
                        }}>
                        
                        <span><img src="/assets/icon/plus.svg" alt="sorting"/>Thêm mới</span>
                    </button>
                </div>
                <div className="body-page">
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th style={{width: '5%', textAlign: 'center'}}>
                                            <input type="checkbox" checked={allSelectedTargetInTable} onChange={(e) => selectAllTargetInTable(e.target.checked)}/>
                                        </th>
                                        <th style={{width: '20%'}}>Tên</th>
                                        <th style={{width: '20%'}}>Chọn loại</th>
                                        <th style={{width: '40%'}}>Thuộc tính con</th>
                                        <th style={{width: "15%"}}>Thao tác</th>
                                    </tr>        
                                </thead>
                                <tbody>

                                    {attributeStore.isLoadingTable === true ?
                                    (<tr>
                                        <td colSpan={5}>
                                            <div className="loading__container">
                                                <Loading/>
                                            </div>
                                        </td>
                                    </tr>)
                                    : (attributeStore.dataListAttribute.length == 0 ? 
                                    (<tr><td colSpan={5}> <NoContent/> </td></tr>)
                                    :attributeStore.dataListAttribute.map(
                                        (item:any, index:any)=>(
                                            <>
                                            <tr key={index}>
                                                    <td style={{textAlign: 'end'}}>
                                                        <div style={{textAlign: 'center'}}>
                                                            <input type="checkbox" name="ids[]" value={item.id} checked={attributeStore.selectedTargets.includes(item.id)} onChange={(e:any) => handleSelect(e.target.checked, item.id)}/>
                                                        </div>
                                                    </td>
                                                    <td className="name">
                                                        <span>{item.name}</span>
                                                    </td>
                                                    <td>
                                                        {item.slug}
                                                    </td>
                                                    <td><div className="attr__container">{(item.attributes.map((element:any, index: any)=><div key={index} className="attr__item">{element.name}</div>))}</div></td>
                                                    <td>
                                                        <Dropdown align={"start"} className="action-table"> 
                                                            <Dropdown.Toggle> 
                                                                Thao tác
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="javascript:void(0)" onClick={()=>{
                                                                    attributeStore.handleResetModal();
                                                                    attributeStore.openModal = true;
                                                                    attributeStore.staticDataAttribute.id = item.id;
                                                                    attributeStore.staticDataAttribute.name = item.name;
                                                                    attributeStore.staticDataAttribute.attributes = item.attributes;
                                                                    attributeStore.isCreating = false;
                                                                    attributeStore.modalName = "Chỉnh sửa thuộc tính";
                                                                    // attributeStore.getDataListAttributeChildren()
                                                                    }}>
                                                                    <i className="fa fa-edit"></i>
                                                                    Sửa
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                            </tr>
                                            </>
                                    ))
                                    )
                                }
                                </tbody>
                                <AttributeModal/>
                            </table>
                            {attributeStore.isLoadingTable ? '' : (Math.ceil(attributeStore.meta.total_page) > 1 && (
                                <div className="pagination__container">
                                <ReactPaginate
                                    previousLabel={<img src="/assets/icon/fi-rr-angle-small-left.svg" alt="right"/>}
                                    nextLabel={<img src="/assets/icon/fi-rr-angle-small-right.svg" alt="right"/>}
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(attributeStore.meta.total_page)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={attributeStore.staticParamAttribute.page}
                                />
                                <div className="pagination__info">
                                    Hiển thị  {attributeStore.meta.page != attributeStore.meta.total_page ? attributeStore.meta.size: (attributeStore.meta.total - (attributeStore.meta.page-1)*(attributeStore.meta.size))}/Tổng số {attributeStore.meta.total} thuộc tính
                                </div>
                                </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Attribute);