import axios, { HttpStatusCode } from "axios";
import StorageService from "../service/StorageService";

let storageService = new StorageService();
const token = storageService.getCookie("accessToken");
// const domain = storageService.getCookie("domainName");
const domain = localStorage.getItem("domainName");
let apiBaseUrl = domain;
let newHeaders = {
    "Content-type": "application/json",
    // Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    Authorization: `Bearer ${token}`,
};

export async function getRequest(path: string) {
    return await axios.get(apiBaseUrl + path, { headers: newHeaders }).then(
        (response) => {
            const apiResponse: any = {
                status: response.status,
                body: response.data,
            };
            return apiResponse;
        },
        (error) => {
            return handleError(error);
        }
    );
}

export async function postRequest(path: string, params: any) {
    let data = JSON.stringify(params);

    return await axios
        .post(apiBaseUrl + path, data, {
            headers: newHeaders,
        })
        .then(
            (response) => {
                const apiResponse: any = {
                    status: response.status,
                    body: response.data,
                };

                return apiResponse;
            },
            (error) => {
                return handleError(error);
            }
        );
}
export async function postLoginRequest(path: string, params: any) {
    let data = JSON.stringify(params);

    return await axios
        .post(path, data, {
            headers: newHeaders,
        })
        .then(
            (response) => {
                const apiResponse: any = {
                    status: response.status,
                    body: response.data,
                };

                return apiResponse;
            },
            (error) => {
                return handleError(error);
            }
        );
}
export async function putRequest(path: string, params: any) {
    let data = JSON.stringify(params);
    return await axios
        .put(apiBaseUrl + path, data, {
            headers: newHeaders,
        })
        .then(
            (response) => {
                const apiResponse: any = {
                    status: response.status,
                    body: response.data,
                };
                return apiResponse;
            },
            (error) => {
                return handleError(error);
            }
        );
}
export async function deleteRequest(path: string) {
    return await axios
        .delete(apiBaseUrl + path, {
            headers: newHeaders,
        })
        .then(
            (response) => {
                const apiResponse: any = {
                    status: response.status,
                    body: response.data,
                };
                return apiResponse;
            },
            (error) => {
                return handleError(error);
            }
        );
}
function handleError(error: any) {
    let bodyError: any;
    try {
        if (error?.response?.status === HttpStatusCode.InternalServerError) {
            bodyError = {
                error: HttpStatusCode.InternalServerError,
                message: "Lỗi máy chủ nội bộ, vui lòng thử lại sau!",
            };
        } else {
            bodyError = {
                errorCode:
                    error?.response?.data?.errorCode ?? error?.response?.status,
                messageCode: error?.response?.data?.messageCode,
                message: error?.response?.data?.message,
            };
        }
    } catch (e) {
        bodyError = {
            error: 502,
            message: "Đã có lỗi xảy ra!",
        };
    }
    return {
        status: error?.response?.status,
        body: bodyError,
    };
}
