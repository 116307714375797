import React from "react";
import {observer} from "mobx-react";
import UploadFile from "../../../../../shared/components/form/UploadFile";
import {productStore} from "../../ProductStore";
import {handleFile, handleFileMultiple} from "../../../../../common/helpers/UploadFileHelper";
import UploadFileMultiple from "../../../../../shared/components/form/UploadFileMultiple";
import {Form} from "antd";
const ImageCreateProduct = () => {
    return (
        <>
            <div className="session-create">
                <div className="session-create-left">
                    <div className="session-create-left-content">
                        <p className="title">Hình ảnh</p>
                        <p className="dep">Tải lên hình ảnh sản phẩm tại đây</p>
                    </div>
                </div>
                <div className="session-create-right">
                    <div className="session-create-content">
                        <UploadFile
                            text={`hình ảnh`}
                            description={`Hình ảnh sản phẩm (kích thước 800 x 800)`}
                            file={productStore.staticDataProduct.preview}
                            onIconRemove={productStore.handleClosePreview}
                            onFileChange={(e) => handleFile(e, productStore.changeFilePreview)}
                        />

                        <UploadFileMultiple
                            text={`hình ảnh bộ sưu tập`}
                            description={`Hình ảnh bộ sưu tập (kích thước 800 x 800)`}
                            file={productStore.staticDataProduct.multi_images}
                            onIconRemove={(index) => productStore.handleCloseMultiImages(index)}
                            onFileChange={(e) => handleFileMultiple(e, productStore.changeFileMultiImages)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(ImageCreateProduct);