import React, {useEffect} from "react";
import { observer } from "mobx-react";
import SelectStatus from "../../../../../shared/components/form/SelectStatus";
import { productStore } from "../../ProductStore";
import { dashboardStore } from "../../../../dashboard/DashboardStore";
import { Checkbox, Input } from "antd";
const InventoryAndVariantCreateProduct = () => {
    const handleQtyChange = (index:any, delta:any) => {
        const item = productStore.arrayQtyStore[index];
        const newQty = Math.max(0, (item.qty || 0) + delta);
        item.qty = newQty;
    };

    const handleAllowInventoryChange = (value:any) => {
        productStore.staticDataProduct.allow_inventory = value;
        if (value !== '1') {
            dashboardStore.dataListStore.forEach((item) => {
                item.qty = 0;
            });
        }
    };
    const handleInputChange = (index:any, value:any) => {
        const newQty = Math.max(0, value);
        productStore.arrayQtyStore[index].qty = newQty;
    };

    return (
        <div className="session-create">
            <div className="session-create-left">
                <div className="session-create-left-content">
                    <p className="title">Tồn kho</p>
                    <p className="description">Thêm tồn kho của bạn ở đây</p>
                </div>
            </div>
            <div className="session-create-right">
                <div className="session-create-content">
                    <div className="item-form">
                        <SelectStatus
                            selected={() => productStore.staticDataProduct.allow_inventory}
                            onChange={handleAllowInventoryChange}
                            textOn="Cho phép quản lý tồn kho"
                            textOff="Không cho phép quản lý tồn kho"
                        />
                    </div>
                    {productStore.staticDataProduct.allow_inventory === '1' && (
                        <>
                            {/*<label className="label-allow-order">*/}
                            {/*    <Checkbox*/}
                            {/*        name="allow_order_when_out_of_stock"*/}
                            {/*        onChange={handleCheckboxChange}*/}
                            {/*        checked={productStore.staticDataProduct.allow_inventory === '1'}*/}
                            {/*    >*/}
                            {/*        Cho phép đặt hàng khi sản phẩm hết hàng*/}
                            {/*    </Checkbox>*/}
                            {/*</label>*/}
                            <div className="item-form-inventory">
                                {productStore.arrayQtyStore.map((item, index) => (
                                    <div className="item-store" key={index}>
                                        <p>{item.name}</p>
                                        <div className="qty">
                                            <div className="minus">
                                                <button
                                                    type="button"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleQtyChange(index, -1);
                                                    }}
                                                >
                                                    -
                                                </button>
                                            </div>
                                            <Input
                                                type="number"
                                                placeholder="0"
                                                value={item.qty || "0"}
                                                onChange={(e) => handleInputChange(index, parseInt(e.target.value, 10))}
                                                min="0"
                                                max="99999"
                                                maxLength={16}
                                            />
                                            <div className="plus">
                                                <button
                                                    type="button"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleQtyChange(index, 1);
                                                    }}
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default observer(InventoryAndVariantCreateProduct);
