import {
    deleteRequest,
    getRequest,
    putRequest,
} from "../../../common/helpers/RequestHelper";
import { postRequest } from "../../../common/helpers/RequestHelper";

class TagProductService {
    apiSubdirectory = `api/v1/categorytags`;
    public fetchTagProduct(params: any): Promise<any> {
        return getRequest(
            `${this.apiSubdirectory}?offset=${params.offset}&page=${params.page}&per_page=${params.per_page}&search=${params.search}&status=${params.status}`
        );
    }

    public fetchTagProductAll(): Promise<any> {
        return getRequest(`${this.apiSubdirectory}`);
    }

    public fetchCreateNewTagProduct(paramsCreate: any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`, paramsCreate);
    }

    public fetchDetailTagProduct(paramsDetail: any): Promise<any> {
        return getRequest(`${this.apiSubdirectory}/${paramsDetail.id}`);
    }

    public fetchUpdateTagProduct(paramsPut: any): Promise<any> {
        return putRequest(
            `${this.apiSubdirectory}/${paramsPut.id}?name=${paramsPut.name}&status=${paramsPut.status}&is_featured=${paramsPut.is_featured}`,
            paramsPut
        );
    }

    public fetchDeleteTagProduct(id: number): Promise<any> {
        return deleteRequest(`${this.apiSubdirectory}/${id}`);
    }
}

export const tagproductservice = new TagProductService();
