import {makeAutoObservable} from "mobx";
import {HttpStatusCode} from "axios";
import {toastUtils} from "../../../common/utils/Toastutils";
import {inventoryService} from "./InventoryService";
class InventoryStore{
    isLoading: boolean = false
    listBanner: string[] = []
    listInventoryProduct: any[] = []
    staticAllInventoryProduct: {
        search: string ;
        store_id: string ;
        page_number: number | null ;
    } = {
        search: '',
        store_id: '1',
        page_number: 0,
    };
    constructor() {
        makeAutoObservable(this)
    }
    async fetchAllInventoryProduct() {
        let {search,store_id} = this.staticAllInventoryProduct
        const params = {
            search: search,
            store_id: store_id
        };
        const result = await inventoryService.fetchAllInventoryProduct(params);
        if (result.status == HttpStatusCode.Ok) {
            this.listInventoryProduct = result.body.data
        } else {
            // toastUtils.error(resultCreate.body.message, "");
        }
    }

}

export const inventoryStore = new InventoryStore()