import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Loading from '../loading/Loading';
interface IconUploadProps {
    text?: string | null;
    description?: string | null;
    file: string | null;
    onIconRemove: () => void;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const UploadFile: React.FC<IconUploadProps> = ({ text,description,file, onIconRemove, onFileChange }) => {
    const [uploadingStatus, setUploadingStatus] = useState(false)
    useEffect(()=>{
        if(file){
            setUploadingStatus(false)
        }
    },[file])
    return (
        <div className="upload-form">
            {text && <label>{text}</label>}
            {file ? (
                <div className="upload" title="Thêm file">
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img className="image-upload" src={file} alt="Preview" />
                    </a>
                    <div onClick={()=>{
                        onIconRemove()
                        setUploadingStatus(false)
                    }} className="close_image">
                        <img src="/assets/icon/minus-circle.svg" className="icon-action" alt="" title="Xóa" />
                    </div>
                </div>
            ) : (
                <div className="upload">
                    {description && <p>{description}</p>}
                    <div className="uploadChild">
                        <div>
                            {uploadingStatus === true? 
                            (<><Loading/></>)
                            :(<><img className="icon_upload" width="38" height="38" src="/assets/icon/icon_upload.svg" alt="icon_upload" />
                            {text && <span className="text-upload">Chọn {text}</span>}
                            <input
                                onChange={(event)=>{
                                    setUploadingStatus(true);
                                    onFileChange(event)
                                }}
                                accept="video/mp4, image/jpeg, image/jpg, image/png, application/pdf, .doc, .docx, .ppt, .pptx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                type="file"
                            /></>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default observer(UploadFile);
