import { makeAutoObservable } from "mobx";
import { headerService } from "./HeaderService";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import StorageService from "../../../common/service/StorageService";
const storageService = new StorageService();
class HeaderStore {
    isLoading: boolean = false;
    timePackageWebsite = "";

    async getBanner() {}
    async fetchDomain() {
        if (localStorage.getItem("idDomain")) {
            let params = {
                domain_name: localStorage.getItem("idDomain"),
            };
            this.isLoading = true;
            const result = await headerService.fetchDomain(params);
            if (result.status == HttpStatusCode.Ok) {
                this.isLoading = false;
                const today = new Date();
                const expirationDate = new Date(result.body.will_expire);
                const timeDifference =
                    expirationDate.getTime() - today.getTime();
                const daysLeft = Math.ceil(timeDifference / (1000 * 3600 * 24));
                if (daysLeft > 0) {
                    this.timePackageWebsite = `${daysLeft}`;
                    console.log("timepackage", this.timePackageWebsite);
                } else {
                    storageService.removeAccessToken();
                    window.location.href = "/dang-nhap";
                }
            } else {
                toastUtils.error("Đã có lỗi xảy ra", "");
            }
        }
    }
    constructor() {
        makeAutoObservable(this);
    }
}

export const headerStore = new HeaderStore();
