import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "../../../common/helpers/RequestHelper";

class LabelService {
    apiSubdirectory = `api/v1/features`;

    public fetchAllLabel(params: any): Promise<any> {
        return getRequest(
            `${this.apiSubdirectory}?offset=${params.offset}&order_by=${params.order_by}&page=${params.page}&per_page=${params.per_page}&search=${params.search}`
        );
    }

    public fetchDeleteLable(id: number): Promise<any> {
        return deleteRequest(`${this.apiSubdirectory}/${id}`);
    }

    public fetchCreateLabel(params: any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`, params);
    }

    public fetchUpdateLabel(params: any): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${params.id}`, params);
    }
}
export const labelservice = new LabelService();
