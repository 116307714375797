import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
} from "../../../common/helpers/RequestHelper";

class CouponService {
    apiSubdirectory = "api/v1/coupons";
    async fetchCoupons(paramCoupon: any) {
        return await getRequest(
            `${this.apiSubdirectory}?offset=${paramCoupon.offset}&order_by=${paramCoupon.order_by}&page=${paramCoupon.page}&per_page=${paramCoupon.per_page}&search=${paramCoupon.search}`
        );
    }

    async fetchCouponsAll(): Promise<any> {
        return getRequest(`${this.apiSubdirectory}`);
    }

    async fetchCreateCoupon(paramsCreate: any): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`, paramsCreate);
    }

    async fetchPutCoupon(id: number, paramsPut: any): Promise<any> {
        return putRequest(`${this.apiSubdirectory}/${id}`, paramsPut);
    }

    async fetchDeleteCoupon(id: number): Promise<any> {
        return deleteRequest(`${this.apiSubdirectory}/${id}`);
    }
}

export const couponService = new CouponService();
