import { makeAutoObservable } from "mobx";
import { HttpStatusCode } from "axios";
import { toastUtils } from "../../../common/utils/Toastutils";
import { infoService } from "./InfoService";
import { object } from "yup";
import StorageService from "../../../common/service/StorageService";
const storageService = new StorageService();

class InfoStore {
    isLoadingUpdateInfo: boolean = false;
    isLoadingGetInfo: boolean = false;
    isLoadingChangePassword: boolean = false;
    dataUser: any = {
        name: "",
        email: "",
        avatar: "",
    };
    dataChangePassword: {
        oldpassword: string;
        password: string;
        confirm: string;
    } = {
        oldpassword: "",
        password: "",
        confirm: "",
    };
    listBanner: string[] = [];
    constructor() {
        makeAutoObservable(this);
    }
    async getBanner() {}
    async fetchGetInfoUser() {
        this.isLoadingGetInfo = true;
        const result = await infoService.fetchGetInfoUser();
        if (result.status == HttpStatusCode.Ok) {
            this.isLoadingGetInfo = false;
            this.dataUser = result.body.data;
        } else {
            toastUtils.error(result.body.message, "");
        }
    }
    async fetchChangeInfoUser() {
        const params = {
            name: this.dataUser.name,
            email: this.dataUser.email,
            avatar: this.dataUser.avatar,
        };
        this.isLoadingUpdateInfo = true;
        const result = await infoService.fetchChangeInfoUser(params);
        console.log("check result info", result);
        if (result.status == HttpStatusCode.Ok) {
            this.isLoadingUpdateInfo = false;
            storageService.setNameUser(this.dataUser.name);
            // Lưu thông tin thông báo vào local
            localStorage.setItem("toastMessage", "Cập nhật thành công");
            window.location.href = "/thong-tin-tai-khoan";
        } else {
            toastUtils.error("Đã có lưu xảy ra", "");
        }
    }
    async fetchChangePassword(onSuccessCallback: () => void) {
        let { oldpassword, password, confirm } = this.dataChangePassword;
        const params = {
            old_password: oldpassword,
            new_password: password,
            confirm_new_password: confirm,
        };
        this.isLoadingChangePassword = true;
        const result = await infoService.fetchChangePassword(params);
        if (result.status == HttpStatusCode.Ok) {
            this.isLoadingChangePassword = false;
            toastUtils.success("Thay đổi mật khẩu thành công", "");
            this.dataChangePassword.oldpassword = "";
            this.dataChangePassword.password = "";
            this.dataChangePassword.confirm = "";
            onSuccessCallback();
        } else {
            if (
                result.body.message.password === "the_old_password_is_incorrect"
            ) {
                toastUtils.error("Mật khẩu cũ không đúng", "");
            } else {
                toastUtils.error("Đã có lưu xảy ra", "");
            }
        }
    }
    changeFileAvatar = (pathFile: string) => {
        this.dataUser.avatar = pathFile;
    };
    handleCloseAvatar = () => {
        this.dataUser.avatar = "";
    };
}

export const infoStore = new InfoStore();
