import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";
import { brandStore } from "./BrandStore";
import ReactPaginate from "react-paginate";
import { Input, Button } from "antd";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import "./styles/brand.css";
import { Modal } from "antd";
import * as yup from "yup";
import Loading from "../../../shared/components/loading/Loading";
import { parseISO, format } from "date-fns";
import BrandModal from "./components/BrandModal";
import FilterTab from "./filterBrand/FilterTab";
import { ExclamationCircleFilled } from "@ant-design/icons";
import NoContent from "../../../shared/components/NoContent";
import { showDelete } from "../../../shared/components/showDelete";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Brand = () => {
    const { confirm } = Modal;
    const [selectedTargets, setSelectedTarget] = useState<string[]>([]);
    function handleSelect(value: boolean, name: string) {
        if (value) {
            setSelectedTarget([...selectedTargets, name]);
        } else {
            setSelectedTarget(selectedTargets.filter((item) => item !== name));
        }
    }
    const allSelectedTargetInTable =
        selectedTargets.length === brandStore.dataListBrand.length;
    function selectAllTargetInTable(value: boolean) {
        if (value) {
            setSelectedTarget(
                brandStore.dataListBrand.map((item: any) => item.id)
            );
        } else {
            setSelectedTarget([]);
        }
    }
    const showDeleteConfirm = () => {
        showDelete.showDeleteConfirm(
            "Xác nhận xoá thương hiệu",
            "Bạn chắc chắn muốn xoá thương hiệu này?(Hành động này không thể hoàn tác)",
            () => {
                brandStore.fetchDeleteBrand();
            }
        );
    };

    const query = useQuery();
    const navigate = useNavigate();
    const handlePageClick = (data: { selected: number }) => {
        const selectedPage = data.selected;
        const params: any = {};
        if (brandStore.staticParamBrand.search !== "") {
            params.keyword = `search=${brandStore.staticParamBrand.search}`;
        }
        params.page = selectedPage + 1;
        navigate({
            search: createSearchParams(params).toString(),
        });
        brandStore.staticParamBrand.page = selectedPage;
        brandStore.fetchListBrands();
    };

    const { TextArea } = Input;
    const validBrandSchema = yup.object().shape({
        brand_name: yup.string().required("Vui lòng nhập Tên thương hiệu"),
        brand_description: yup.string(),
    });

    function onSubmitSearch(event: any) {
        const searchKeyword = brandStore.staticParamBrand.search;
        console.log(searchKeyword);
        event.preventDefault();
        const params: any = {};
        if (searchKeyword !== "") {
            params.keyword = `${searchKeyword}`;
            console.log(params);
        }
        navigate({
            search: createSearchParams(params).toString(),
        });
        brandStore.fetchListBrands();
    }
    function onHandleKeyDown(event: any) {
        if (event.key === "Enter") {
            onSubmitSearch(event);
        }
    }

    useEffect(() => {
        brandStore.fetchListBrands();
        brandStore.fetchListBrandsAll();
    }, []);

    return (
        <div className="container">
            <div className="mainContentBrand">
                <div className="header">
                    <div className="header__title">Thương hiệu</div>
                    <form className="header__search" onSubmit={onSubmitSearch}>
                        <input
                            id="search"
                            type="text"
                            onKeyDown={(event: any) => {
                                onHandleKeyDown(event);
                            }}
                            value={brandStore.staticParamBrand.search}
                            onChange={(event) =>
                                (brandStore.staticParamBrand.search =
                                    event.target.value)
                            }
                            placeholder="Nhập tên thương hiệu"
                        />
                        <button type="submit">
                            <img src="/assets/icon/search.svg" alt="" />
                        </button>
                    </form>
                    <button
                        className="btn-add-modal"
                        onClick={() => {
                            brandStore.openModal = true;
                            brandStore.isCreating = true;
                            brandStore.handleClearContent();
                            brandStore.handlerName = "Thêm mới thương hiệu";
                        }}
                    >
                        <span>
                            Thêm mới
                            <img src="/assets/icon/plus.svg" alt="sorting" />
                        </span>
                    </button>
                </div>
                <div className="body-page">
                    <FilterTab />
                    <div className="content-table">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th
                                            style={{
                                                width: "5%",
                                                textAlign: "center",
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                    selectAllTargetInTable(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </th>
                                        <th style={{ width: "30%" }}>Tên</th>
                                        <th style={{ width: "30%" }}>
                                            Ngày tạo
                                        </th>
                                        <th style={{ width: "20%" }}>
                                            Trạng thái
                                        </th>
                                        <th style={{ width: "15%" }}>
                                            Thao tác
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {brandStore.isLoading === true ? (
                                        <tr>
                                            <td colSpan={5}>
                                                <div className="loading__container">
                                                    <Loading />
                                                </div>
                                            </td>
                                        </tr>
                                    ) : brandStore.dataListBrand.length == 0 ? (
                                        <tr>
                                            <td colSpan={5}>
                                                {" "}
                                                <NoContent />{" "}
                                            </td>
                                        </tr>
                                    ) : (
                                        brandStore.dataListBrand.map(
                                            (item, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td
                                                            style={{
                                                                textAlign:
                                                                    "end",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    name="ids[]"
                                                                    value={
                                                                        item.id
                                                                    }
                                                                    checked={selectedTargets.includes(
                                                                        item.id
                                                                    )}
                                                                    onChange={(
                                                                        e: any
                                                                    ) =>
                                                                        handleSelect(
                                                                            e
                                                                                .target
                                                                                .checked,
                                                                            item.id
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="name">
                                                            {item.image ===
                                                            null ? (
                                                                <img
                                                                    src="/assets/images/no-image.svg"
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={
                                                                        item.image
                                                                    }
                                                                    alt=""
                                                                />
                                                            )}
                                                            <span
                                                                title={
                                                                    item.name
                                                                }
                                                            >
                                                                {item.name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {new Date(
                                                                    item.created_at
                                                                ).toLocaleDateString(
                                                                    "en-GB"
                                                                )}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {String(
                                                                item.status
                                                            ) != String(1) ? (
                                                                <span className="inactive">
                                                                    Vô hiệu hoá
                                                                </span>
                                                            ) : (
                                                                <span className="active">
                                                                    Hoạt động
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <Dropdown
                                                                align={"start"}
                                                                className="action-table"
                                                                ref={
                                                                    brandStore.dropdownRef
                                                                }
                                                            >
                                                                <Dropdown.Toggle>
                                                                    Thao tác
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            brandStore.openModal =
                                                                                true;
                                                                            brandStore.staticDataBrand.id =
                                                                                item.id;
                                                                            brandStore.isCreating =
                                                                                false;
                                                                            brandStore.handlerName =
                                                                                "Chỉnh sửa thương hiệu";
                                                                            brandStore.getSpecificBrand();
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                        Sửa
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            showDeleteConfirm();
                                                                            brandStore.staticDataBrand.id =
                                                                                item.id;
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                        Xóa
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        )
                                    )}
                                </tbody>
                                <BrandModal />
                            </table>
                            {brandStore.isLoading
                                ? ""
                                : Math.ceil(brandStore.metadata.total_page) >
                                      1 && (
                                      <div className="pagination__container">
                                          <ReactPaginate
                                              previousLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-left.svg"
                                                      alt="right"
                                                  />
                                              }
                                              nextLabel={
                                                  <img
                                                      src="/assets/icon/fi-rr-angle-small-right.svg"
                                                      alt="right"
                                                  />
                                              }
                                              breakLabel={"..."}
                                              pageCount={
                                                  brandStore
                                                      .filteredDataListBrand
                                                      .length != 0
                                                      ? Math.ceil(
                                                            brandStore
                                                                .filteredDataListBrand
                                                                .length /
                                                                brandStore
                                                                    .staticParamBrand
                                                                    .per_page
                                                        )
                                                      : Math.ceil(
                                                            brandStore.metadata
                                                                .total_page
                                                        )
                                              }
                                              marginPagesDisplayed={2}
                                              pageRangeDisplayed={3}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              activeClassName={"active"}
                                              forcePage={
                                                  brandStore.staticParamBrand
                                                      .page
                                              }
                                          />
                                          <div className="pagination__info">
                                              Hiển thị{" "}
                                              {brandStore.metadata.page !=
                                              brandStore.metadata.total_page
                                                  ? brandStore.metadata.size
                                                  : brandStore.metadata.total -
                                                    (brandStore.metadata.page -
                                                        1) *
                                                        brandStore.metadata
                                                            .size}
                                              /Tổng số{" "}
                                              {brandStore.metadata.total} thương
                                              hiệu
                                          </div>
                                      </div>
                                  )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Brand);
