import React, { useEffect, useState } from "react";
import "./App.css";
import Redirect from "./routing/routers";
import Footer from "./shared/layout/footer/Footer";
import Header from "./shared/layout/header/Header";
import { ToastContainer } from "react-toastify";
import Login from "./pages/login/Login";
import StorageService from "./common/service/StorageService";
import { useLocation, useNavigate } from "react-router-dom";

const App = () => {
    const storageService = new StorageService();
    const navigate = useNavigate();
    const location = useLocation();
    const accessToken = storageService.getCookie("accessToken");

    useEffect(() => {
        if (!accessToken && location.pathname !== "/dang-nhap") {
            navigate("/dang-nhap");
        }
        if (accessToken && location.pathname === "/dang-nhap") {
            navigate("/");
        }
    }, [accessToken, location.pathname]);

    return (
        <>
            {accessToken !== null &&
            accessToken !== "" &&
            accessToken !== undefined ? (
                <div className="root">
                    <Header />
                    <div className="wrapper">
                        <Redirect />
                    </div>
                    {/*<Footer/>*/}
                </div>
            ) : (
                <div className="root">
                    <Login />
                </div>
            )}

            <ToastContainer />
        </>
    );
};
export default App;
