import {postRequest, putRequest} from "../../../common/helpers/RequestHelper";

class InfoService {
    apiSubdirectory = 'api/v1/auth/me' ;
    apiChangeInfoUser = 'api/v1/auth/change-info' ;
    apiChangePassword = 'api/v1/auth/change-password' ;
    public fetchGetInfoUser(): Promise<any> {
        return postRequest(`${this.apiSubdirectory}`,'');
    }
    public fetchChangeInfoUser(params:any): Promise<any> {
        return putRequest(`${this.apiChangeInfoUser}`,params);
    }
    public fetchChangePassword(params:any): Promise<any> {
        return putRequest(`${this.apiChangePassword}`,params);
    }
}

export const infoService = new InfoService()