import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
interface EditProductMenuProps {
    idProduct: string;
    nameEdit: string;
}
const EditProductMenu: React.FC<EditProductMenuProps> = ({ idProduct,nameEdit }) =>{
    return (
        <>
            <ul>
                <li className={nameEdit === 'general' ? 'active' : ''}>
                    <Link to={`/hang-hoa/san-pham/${idProduct}/thong-tin-chung`}>
                        Thông tin chung
                    </Link>
                </li>
                <li className={nameEdit === 'variant' ? 'active' : ''}>
                    <Link to={`/hang-hoa/san-pham/${idProduct}/gia`}>
                        Giá
                    </Link>
                </li>
                <li className={nameEdit === 'image' ? 'active' : ''}>
                    <Link to={`/hang-hoa/san-pham/${idProduct}/hinh-anh`}>
                        Hình ảnh
                    </Link>
                </li>
                <li className={nameEdit === 'qrcode' ? 'active' : ''}>
                    <Link to={`/hang-hoa/san-pham/${idProduct}/tao-ma-qrcode`}>
                        Tạo mã QRCODE
                    </Link>
                </li>
                <li className={nameEdit === 'barcode' ? 'active' : ''}>
                    <Link to={`/hang-hoa/san-pham/${idProduct}/tao-ma-barcode`}>
                        Tạo mã BARCODE
                    </Link>
                </li>
            </ul>
        </>
    )
}
export default observer(EditProductMenu);
