import React, { useEffect } from "react";
import { observer } from "mobx-react";
import "./info.css";
import { Button, Form, Input, Space } from "antd";
import { infoStore } from "./InfoStore";
import { handleFile } from "../../../common/helpers/UploadFileHelper";
import UploadFile from "../../../shared/components/form/UploadFile";
import { info } from "console";
import { toastUtils } from "../../../common/utils/Toastutils";
const Info = () => {
    const [form] = Form.useForm();
    const [formPassword] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            name: infoStore.dataUser?.name,
            email: infoStore.dataUser?.email,
            user: infoStore.dataUser?.avatar,
        });
        formPassword.setFieldsValue({
            oldpassword: infoStore.dataChangePassword?.oldpassword,
            password: infoStore.dataChangePassword?.password,
            confirm: infoStore.dataChangePassword?.confirm,
        });
    }, [infoStore.dataUser, form, infoStore.dataChangePassword, formPassword]);

    window.addEventListener("load", () => {
        // Sẽ lấy và kiểm tra nếu như mà toastMessage có thì chạy thông báo
        const toastMessage = localStorage.getItem("toastMessage");
        if (toastMessage) {
            toastUtils.success(toastMessage, "");
            localStorage.removeItem("toastMessage"); // Xóa thông báo sau khi hiển thị để tránh hiển thị lại khi trang được reload lần nữa.
        }
    });
    console.log("check link img", infoStore.dataUser.avatar);

    return (
        <>
            <div className="container">
                <div className="main-content-info">
                    <div className="main-content-info-item">
                        <p className="title">Chỉnh sửa thông tin chung</p>
                        <Form
                            className="form-info-user"
                            form={form}
                            name="control-hooks"
                            onFinish={(values) => {
                                infoStore.dataUser.name = values.name;
                                infoStore.dataUser.email = values.email;
                                infoStore.fetchChangeInfoUser();
                            }}
                        >
                            <div className="item-form">
                                <Form.Item
                                    name="name"
                                    label="Tên"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Vui lòng nhập" />
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống!",
                                        },
                                        {
                                            type: "email",
                                            message: "Email không hợp lệ!",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Vui lòng nhập" />
                                </Form.Item>
                            </div>
                            <UploadFile
                                text={`Avatar`}
                                description={`Avatar`}
                                file={infoStore.dataUser.avatar}
                                onIconRemove={infoStore.handleCloseAvatar}
                                onFileChange={(e) =>
                                    handleFile(e, infoStore.changeFileAvatar)
                                }
                            />
                            {infoStore.isLoadingGetInfo === false && (
                                <Form.Item>
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {infoStore.isLoadingUpdateInfo ===
                                            true
                                                ? "Đang lưu"
                                                : "Cập nhật"}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            )}
                        </Form>
                    </div>
                    <div className="main-content-info-item">
                        <p className="title">Thay đổi mật khẩu</p>

                        <Form
                            className="form-info-user"
                            form={formPassword}
                            name="control-hooks-change-password"
                            onFinish={(values) => {
                                infoStore.dataChangePassword.oldpassword =
                                    values.oldpassword;
                                infoStore.dataChangePassword.password =
                                    values.password;
                                infoStore.dataChangePassword.confirm =
                                    values.confirm;
                                infoStore.fetchChangePassword(() => {
                                    formPassword.resetFields(); // Pass the resetFields method as a callback
                                });
                            }}
                        >
                            <div className="item-form">
                                <Form.Item
                                    name="oldpassword"
                                    label="Mật khẩu cũ"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống!",
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="Vui lòng nhập" />
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="password"
                                    label="Mật khẩu mới"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống!",
                                        },
                                        {
                                            min: 9,
                                            message:
                                                "Mật khẩu phải có ít nhất 9 ký tự!",
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password placeholder="Vui lòng nhập" />
                                </Form.Item>
                            </div>
                            <div className="item-form">
                                <Form.Item
                                    name="confirm"
                                    label="Nhập lại mật khẩu"
                                    dependencies={["password"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: "Không được để trống!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        "password"
                                                    ) === value
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "Mật khẩu không giống nhau"
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder="Vui lòng nhập" />
                                </Form.Item>
                            </div>

                            <Form.Item>
                                <Space>
                                    <Button type="primary" htmlType="submit">
                                        {infoStore.isLoadingChangePassword ===
                                        true
                                            ? "Đang lưu"
                                            : "Thay đổi"}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default observer(Info);
