import { HttpStatusCode } from "axios";
import { posService } from "./PosService";
import { toastUtils } from "../../common/utils/Toastutils";
import { makeAutoObservable } from "mobx";

class PosStore{
    modalOpen: boolean = false;
    loadingDataAll: boolean= true;
    loadingDataTable: boolean= true;
    loadingListStore: boolean = true;
    waitingText: string = '';
    isCreating: boolean= true;
    modalName: string = '';
    detailedDataPos:{}={}
    listPos: any[] = [];
    listStore: any[] = [];
    statusFilterActiveStatus: boolean = false;
    statusFilterInactiveStatus: boolean = false;
    locationFilterValue: number = -1;
    selectedPosInTable: any[] = [];
    neededActionPosValue: string = '';
    constructor(){
        makeAutoObservable(this);
        this.initParam();
    }
    staticParamPos :{offset: number, order_by: string, page: number, per_page: number, search: string|null, status: string|null, store_id: number | null} = {
        offset: 0,
        order_by: 'id', //Only accept order_by : 'id', 'name'
        page: 1,
        per_page: 10,
        search: '',
        status: '', //Only accept status : '1', '0'
        store_id: 0
    }
    staticDataPos :{id:string, name: string, store_id: string, status: string}={
        id: '',
        name: '',
        store_id: '',
        status: '1',
    }
    metadata: {total: number, page: number, total_page: number, size: number}={
        total: 0,
        page: 0,
        total_page: 0,
        size: 0
    }
    initParam(){
        const queryParam = new URLSearchParams(window.location.search)
        this.staticParamPos.page = queryParam.get('page') ? Number(queryParam.get('page'))-1 : 0;
        this.staticParamPos.search = queryParam.get('keyword') ? String(queryParam.get('keyword')) : '';
    }
    // detailedDataPos: {id: number, name: string, user_id: number, location_id: string, is_working: number, start_working: string, end_working: string, status: number, meta_data: string, created_at: string, updated_at: string, pos_session_id: string, meta_start_amount: null, meta_end_amount: null} = {
    //     id: 0,
    //     name: '',
    //     user_id: 0,
    //     location_id: '',
    //     is_working: 1,
    //     start_working: '',
    //     end_working: '',
    //     status: 1,
    //     meta_data: '',
    //     created_at: '',
    //     updated_at: '',
    //     pos_session_id: '',
    //     meta_start_amount: null,
    //     meta_end_amount: null
    // }
    async createPos(){
        this.waitingText = 'Vui lòng chờ...'
        let {name, store_id, status} = this.staticDataPos;
        const createdDataPos = {
            name: name,
            store_id: store_id,
            status: status
        }
        const result = await posService.fetchPosCreating(createdDataPos);
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Thêm thành công!', '');
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
        this.getPosAll()
        this.handleClearContent();
        this.waitingText = '';
        this.modalOpen = false;
    }
    async modifyPos(){
        this.waitingText = 'Vui lòng chờ...'
        let {id, name, store_id, status} = this.staticDataPos;
        const modifiedDataPos = {
            id: id,
            name: name,
            store_id: store_id,
            status: status
        }
        const result = await posService.fetchPosModifying(modifiedDataPos);
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Sửa thành công!', `${id}`);
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
        this.waitingText = '';
        this.handleClearContent();
        this.modalOpen = false;
    }
    async deletePos(){
        let {id} = this.staticDataPos;
        const result = await posService.fetchPosDeleting(id);
        if(result.status === HttpStatusCode.Ok){
            toastUtils.success('Xóa thành công!', '');
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!', '');
        }
        this.getPosAll()
    }
    async getPosSpecific(){
        let {id} = this.staticDataPos;
        const result = await posService.fetchPosGettingSpecific(id);
        if(result.status === HttpStatusCode.Ok){
            this.detailedDataPos = result.body.data
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
    }
    async getPosAll(){
        this.loadingDataAll = true;
        this.loadingDataTable = false;
        const result = await posService.fetchPosGettingAll();
        if(result.status === HttpStatusCode.Ok){
            this.listPos = result.body.data;
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','');
        }
        this.loadingDataAll = false;
    }
    async getStoreAll(){
        this.loadingListStore = true;
        const result = await posService.fetchStoreGettingAll();
        if(result.status === HttpStatusCode.Ok){
            this.listStore = result.body.data;
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
        this.loadingListStore = false;
    }
    async getPosWithRequirement(){
        this.loadingDataTable = true;
        let {search, page, per_page, status, store_id} = this.staticParamPos;
        const paramPos = {
            search: search ? search : '',
            page: page ? page : 1,
            per_page: per_page ? per_page : 10,
            status : status ? status : '',
            store_id: store_id ? store_id : '',
        }
        const result = await posService.fetchPosWithRequirement(paramPos);
        if(result.status === HttpStatusCode.Ok){
            this.listPos = result.body.data
        }
        else{
            toastUtils.error('Đã có lỗi xảy ra!','')
        }
        this.loadingDataTable = false;
    }
    handleClearContent(){
        this.staticDataPos.id = '';
        this.staticDataPos.name = '';
        this.staticDataPos.store_id = '';
        this.staticDataPos.status = '1';
        this.modalName = '';
        this.locationFilterValue = -1;
        this.statusFilterActiveStatus = false;
        this.statusFilterInactiveStatus = false;
    }
    handleCheckOnStatusActiveOption(){
        if(this.statusFilterActiveStatus === false){
            this.statusFilterActiveStatus = true;
            this.staticParamPos.status = '1';
            if(this.statusFilterInactiveStatus === true){
                this.statusFilterInactiveStatus = false;
            }
            this.getPosWithRequirement();
        }
        else{
            this.statusFilterActiveStatus = false;
            this.staticParamPos.status = null;
            this.getPosWithRequirement();
        }
    }
    handleCheckOnStatusInactiveOption(){
        if(this.statusFilterInactiveStatus === false){
            this.statusFilterInactiveStatus = true;
            this.staticParamPos.status = '0';
            if(this.statusFilterActiveStatus === true){
                this.statusFilterActiveStatus = false;
            }
            this.getPosWithRequirement();
        }
        else{
            this.statusFilterInactiveStatus = false;
            this.staticParamPos.status = null;
            this.getPosWithRequirement();
        }
    }
    async handleCheckOnLocationOption(value:any){
        value = Number(value)
        if(this.locationFilterValue === value){
            toastUtils.warning('Remove Filter', '')
            this.locationFilterValue = -1;
            this.staticParamPos.store_id = null;
        }
        else{
            this.locationFilterValue = value;
            this.staticParamPos.store_id = value;
        }
        this.getPosWithRequirement();
    }
    async handleSwitchStatusPos(item:any, status: boolean){
        this.staticDataPos.id = item.id;
        this.staticDataPos.store_id = item.location_id;
        this.staticDataPos.status = (status === true ? '1' : '0');
        this.staticDataPos.name = item.name;
        return this.modifyPos();
    }
    async handlePosActionFilter() {
        const neededActionPosList = this.listPos.filter((item: any) =>
            this.selectedPosInTable.includes(item.id)
        );
        if(neededActionPosList.length === 0){
            toastUtils.warning('Vui lòng chọn máy POS!', '')
            return;
        }
    
        switch (this.neededActionPosValue) {
            case 'active':
            case 'inactive':
                await Promise.all(
                    neededActionPosList.map(item =>
                        this.handleSwitchStatusPos(item, this.neededActionPosValue === 'active')
                    )
                );
                await this.getPosAll();
                break;
    
            case 'delete':
                await Promise.all(
                    neededActionPosList.map(item => {
                        this.staticDataPos.id = item.id;
                        return this.deletePos(); // Nhớ trả về Promise từ hàm deletePos nếu chưa có
                    })
                );
                break;
    
            default:
                toastUtils.error('Vui lòng chọn hành động!', '');
                return;
        }
        this.neededActionPosValue = '';
    }
    
}
export const posStore = new PosStore();